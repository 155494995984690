<p-confirmDialog header="Confirmation"></p-confirmDialog>

<p-card>
  <!-- {{ feedModel | json }} -->
  <form #theform="ngForm">
    <div class="p-grid">
      <div class="p-col-fixed form-input-label">
        <label for="title">Title</label>
      </div>
      <div class="p-col form-input-field">
        <input #titleInput #title="ngModel" name="title" id="title" type="text" maxlength="100" size="100" pInputText
          [(ngModel)]="feedModel.attributes.title" required minlength="1" maxlength="100" style="width: 600px"
          autocomplete="off" />
        <span [hidden]="title.valid || title.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="thumbnail">Thumbnail</label>
      </div>
      <div class="p-col form-input-field">
        <ng-container *ngIf="feedModel.attributes.thumbnail">
          <span>
            {{ feedModel.attributes.thumbnail.data?.attributes.name }}
            <!-- <button pButton type="button" class="p-button-raised p-button-rounded"
              label="{{ feedModel.attributes.thumbnail.name }}"></button> -->
            &nbsp;&nbsp;
          </span>
        </ng-container>
        <span><button pButton type="button" icon="fas fa-photo-video"
            [label]="feedModel.attributes.thumbnail ? 'Replace thumbnail image' : 'Select thumbnail image from library' "
            (click)="showMediaLibDialogThumbnail()"></button></span>
        <br />
      </div>
    </div>

    <hr>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="quiztype">Quiz Type</label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="quiztype" id="quiztype" [options]="quizTypes" [(ngModel)]="selectedQuizType"
          optionLabel="label" optionValue="value"></p-dropdown>
        <span *ngIf="selectedQuizType === 'imagemap'">
          <br>
          Tip: Use
          <a href="https://www.image-map.net/" target="_blank">this page</a> to
          create coordinates for type rect (x1,y1,x2,y2) or circle (x,y,radius),
          for example 100,100,200,150 or 150,200,50
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="details">Question</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="details" id="details" maxlength="1500" size="1500" autoResize="true" pInputTextarea
          [(ngModel)]="feedModel.attributes.details" style="width: 600px" autocomplete="off"></textarea>
      </div>
    </div>


    <div class="p-grid">
      <div class="p-col-fixed form-input-label">
        <label for="furtherInfoText">Further Info</label>
      </div>
      <div class="p-col form-input-field">
        <input #titleInput #title="ngModel" name="furtherInfoText" id="furtherInfoText" type="text" maxlength="200"
          size="200" pInputText [(ngModel)]="feedModel.attributes.furtherInfoText" minlength="1" maxlength="200"
          style="width: 600px" autocomplete="off" />

      </div>
    </div>


    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <!-- <label for="furtherInf">Further info</label> -->
      </div>

      <div class="p-col form-input-field">
        <ng-container *ngIf="feedModel.attributes.furtherInfo">
          <span>
            {{ feedModel.attributes.furtherInfo?.data?.attributes?.name }}
            &nbsp;&nbsp;
          </span>
        </ng-container>
        <span><button pButton type="button" icon="fas fa-photo-video"
            [label]="(feedModel.attributes.furtherInfo?.data === null) ? 'Select asset from library' : 'Replace asset'"
            (click)="showMediaLibDialogFurtherInfo()"></button></span>
        <br />
      </div>

      <!-- <div class="p-col form-input-field">
        <p-dropdown name="InfoNugget" [options]="nuggets" [(ngModel)]="selectedInfoNugget" optionLabel="furtherInfo"
          appendTo="body">
          <ng-template let-nugget pTemplate="item">
            <span [pTooltip]="nugget.value.description" tooltipPosition="top">{{
              nugget.value.title
              }}</span>
          </ng-template>
        </p-dropdown>
      </div> -->
    </div>

    <!-- selectedQuizType : {{ selectedQuizType | json }} -->


    <div *ngIf="selectedQuizType === 'order'" class="p-grid">
      <div class="p-col-fixed form-input-label">
        <label for="correct">Correct</label>
      </div>
      <div class="p-col form-input-field">
        <textarea #correctInput #correct="ngModel" name="correct" id="correct" autoResize="true" type="text" size="1000"
          pInputTextarea [(ngModel)]="feedModel.attributes.correct" minlength="1" maxlength="1500" style="width: 600px"
          autocomplete="off"></textarea>
        <!-- <span [hidden]="correct.valid || correct.pristine" class="alert alert-danger">
          Field is required
        </span> -->
      </div>
    </div>

    <div *ngIf="selectedQuizType === 'order'" class=" p-grid">
      <div class="p-col-fixed form-input-label">
        <label for="incorrect">Wrong</label>
      </div>
      <div class="p-col form-input-field">
        <textarea #incorrectInput #incorrect="ngModel" name="incorrect" id="incorrect" autoResize="true" type="text"
          size="100" pInputText [(ngModel)]="feedModel.attributes.incorrect" minlength="1" maxlength="1500"
          style="width: 600px" autocomplete="off"></textarea>
        <!-- <span [hidden]="incorrect.valid || incorrect.pristine" class="alert alert-danger">
          Field is required
        </span> -->
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="image">Image</label>
      </div>
      <div class="p-col form-input-field">
        <ng-container *ngIf="feedModel.attributes.image?.data">
          <span>{{ feedModel.attributes.image?.data?.attributes.name }}
            <!-- <button pButton type="button" class="p-button-raised p-button-rounded"
              label="{{ feedModel.attributes.image.name }}"></button> -->
            <!-- <button pButton type="button" class="p-button-raised p-button-rounded" icon="fas fa-times"
                            iconPos="right" label="{{ feedModel.attributes.image.name }}"
                            (click)="onRemoveImage(feedModel.attributes.image)"></button> -->
            &nbsp;&nbsp;
          </span>
        </ng-container>
        <!-- <span><button pButton type="button" icon="fas fa-upload" label="Upload image"
                        (click)="upload();"></button></span>&nbsp; -->
        <span><button pButton type="button" icon="fas fa-photo-video"
            [label]="feedModel.attributes.image ? 'Replace image' : 'Select image from library' "
            (click)="showMediaLibDialog()"></button></span>
        <br />
      </div>
    </div>

    <!-- <h1>nuggets</h1> -->
    <!-- {{ nuggets | json }} -->
    <!-- <h1>selected</h1>{{ selectedNuggets | json }} -->

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="NuggetRefs">Nugget links</label>
      </div>

      <div class="p-col form-input-field">
        <p-multiSelect name="NuggetRefs" [options]="nuggetList" [(ngModel)]="selectedNuggets" defaultLabel="Select Nuggets"
          display="chip" class="multiselect-custom" appendTo="body">
          <!-- <ng-template let-nugget pTemplate="item">
            <span [pTooltip]="nugget.attributes.description" tooltipPosition="top">{{ nugget.attributes.title }}</span>
          </ng-template> -->
        </p-multiSelect>
      </div>
    </div>

    <!-- {{ feedModel.attributes.info | json }} -->
    <p-table [value]="feedModel.attributes.info" dataKey="sortOrder">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <ng-container *ngIf="selectedQuizType === 'question'">Answer</ng-container>
            <ng-container *ngIf="selectedQuizType === 'order'">Statements in correct order</ng-container>
            <ng-container *ngIf="selectedQuizType === 'imagemap'">Image Map Coordinates</ng-container>
          </th>
          <th *ngIf="selectedQuizType !== 'order'">Response</th>
          <th style="width: 100px">Correct</th>
          <th style="width: 50px">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-info let-i="rowIndex">
        <!-- {{ info | json }} X {{ i }} {{ feedModel.attributes.info[i].correct }} -->
        <tr>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <textarea name="answer" maxlength="1500" size="1500" autoResize="true" pInputTextarea required
                  [(ngModel)]="info.statement" style="width: 100%"></textarea>
              </ng-template>
              <ng-template pTemplate="output">
                {{ info.statement }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn *ngIf="selectedQuizType !== 'order'">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <textarea name="answer" maxlength="1500" size="1500" autoResize="true" pInputTextarea required
                  [(ngModel)]="info.response" style="width: 100%"></textarea>
              </ng-template>
              <ng-template pTemplate="output">
                {{ info.response }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-checkbox *ngIf="selectedQuizType !== 'order'" name="answerCorrect1" [(ngModel)]="info.correct"
                  binary="true"></p-checkbox>
                <!-- 2-{{info.sortOrder}}{{info.correct}} -->
              </ng-template>
              <ng-template pTemplate="output">
                {{ info.correct ? "Yes" : "No" }}
                <!-- <p-checkbox
                  *ngIf="selectedQuizType !== 'order'"
                  name="answerCorrect2"
                  [(ngModel)]="info.correct"
                  binary="true"
                  name="correctCheckboxView"
                ></p-checkbox> -->
                <!-- 4-{{info.sortOrder}}{{info.correct}} -->
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <button pButton type="button" icon="fas fa-times" class="p-button-danger" (click)="deleteInfo(info)"
              [disabled]="feedModel.attributes.info.length === 1"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <button pButton type="button" label="Add" icon="fas fa-plus" (click)="newAnswer($event)"
      [disabled]="feedModel?.attributes.info?.length >= 10"></button>

    <!-- <textarea name="nuggetRefs" id="nuggetRefs" size="1000" autoResize="true" pInputTextarea
                    [(ngModel)]="feedModel.attributes.keywords" style="width: 400px;" autocomplete="off"></textarea> -->
    <!-- </div> -->

    <!-- <div class="p-grid">
            <div class=" p-col-fixed form-input-label">
                <label for="learningTime">Learning Time</label>
            </div>
            <div class="p-col form-input-field">
                <!-- <p-inputNumber #learningTime #learningTime="ngModel" [(ngModel)]="levelModel.learningTime" [min]="0"
                    [max]="60" required style="width:400px"></p-inputNumber> -->
    <!-- <input #learningTimeInput #learningTime="ngModel" name="learningTime" id="learningTime" type="number"
                    size="100" pInputText [(ngModel)]="feedModel.attributes.learningTime" required minlength="1" maxlength="3"
                    min="0" max="999" style="width:400px" autocomplete="off">
                <span [hidden]="(learningTime.valid || learningTime.pristine) && feedModel.attributes.learningTime >= 0"
                    class="alert alert-danger">
                    Learning time is required >= 0 (0 for unlimited time)
                </span>
            </div>
        </div> -->

    <!-- <div class="p-grid">
            <div class=" p-col-fixed form-input-label">
                <label for="icon">Cube icon</label>
            </div>
            <div class="p-col form-input-field">
                <p-selectButton name="iconNr" [options]="iconNrs" [(ngModel)]="feedModel.attributes.iconNr">
                    <ng-template let-item>
                        <img src="assets/cube/icons-jpg/ikon_{{item.label}}.jpg"
                            style="width:48px;vertical-align:middle;margin:5px;" />
                    </ng-template>
                </p-selectButton> -->

    <!-- <p-dropdown name="icon" [options]="iconNrs" [(ngModel)]="feedModel.attributes.iconNr" appendTo="body"
                        [style]="{'width':'150px'}">
                        <ng-template let-item pTemplate="selectedItem">
                            <img src="assets/cube/icon-{{item.label}}.png" style="width:48px;vertical-align:middle" />
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div style="position: relative;height:48px;">
                                <img src="assets/cube/icon-{{item.label}}.png"
                                    style="width:48px;position:absolute;top:1px;left:5px" />
                            </div>
                        </ng-template>
                    </p-dropdown> -->
    <!-- </div>
        </div> -->

    <!-- <div class="p-grid">
            <div class="form-group p-col-fixed form-input-label">
                <label for="content">Contents</label>
            </div> -->
    <!-- <div class="p-col form-input-field">
                <ng-container *ngIf="feedModel.attributes.content">
                    <ng-container *ngFor="let content of feedModel.attributes.content; let i = index; let last = last;">
                        <div>
                            <button pButton type="button" class="p-button-raised p-button-rounded" icon="fas fa-times"
                                iconPos="right" label="{{ content.name }}{{ content.ext }}"
                                (click)="onRemoveContent(content)"></button>
                        </div>
                        <br>
                    </ng-container>
                    <br>
                </ng-container>
                <!-- <span><button pButton type="button" icon="fas fa-upload" label="Upload content"
                        (click)="upload();"></button></span>&nbsp; -->
    <!-- <span><button pButton type="button" icon="fas fa-photo-video" label="Select content from library"
                        (click)="showMediaLibDialog();"></button></span>
                <br>
            </div>
        </div> -->
  </form>


  <!-- <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
      content
    </ng-template>
  </p-overlayPanel>

  <br>
  <button type="text" pButton label="Preview feed" (click)="op.toggle($event)"></button> -->

  <!-- <p-sidebar [(visible)]="display" [fullScreen]="true" [style]="{'z-index':'999999999'}">
    contents
    <ng-container *ngIf="display">
      <app-news-feed previewMode="true" [previewFeed]="feedModel"></app-news-feed>
    </ng-container>
  </p-sidebar> -->

  <br>
  <p-button type="text" (click)="togglePreview();" label="Toggle Preview"></p-button>
  <!-- feedModelPreview : {{ feedModelPreview | json }} -->
  <ng-container *ngIf="displayPreview">
    <app-news-feed previewMode="true" [previewFeed]="feedModelPreview.attributes"></app-news-feed>
  </ng-container>


  <!-- {{ feedModel | json }} -->

</p-card>

<br />
<div class="right grouped-buttons">
  <button pButton type="cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!theform.form.valid"
    class="ui-button"></button>
</div>
