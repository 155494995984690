import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ApplicationRequest, ApplicationRequestData, ApplicationService, LevelRequest, LevelService } from '../swagger-api-v4';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LevelResponseDataObject } from 'resources/swagger-api-v4';

@Component({
  selector: 'app-level-form',
  templateUrl: './level-form.component.html',
  styleUrls: ['./level-form.component.scss']
})
export class LevelFormComponent implements OnInit {

  @ViewChild('titleInput') titleInput: ElementRef;

  // @Input() set level(level: LevelResponseDataObject) {
  //   this.originalLevel = level;
  //   this.levelModel.id = level.id;
  //   Object.assign(this.levelModel.attributes, level.attributes);
  // };

  levelModel: LevelResponseDataObject = { id: 0, attributes: { title: '', description: '' } };
  originalLevel: LevelResponseDataObject = null;

  constructor(
    private levelService: LevelService,
    private applicationService: ApplicationService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    // this.levelModel = this.config.data.level;
    this.originalLevel = this.config.data.level;
    this.levelModel.id = this.config.data.level.id;
    Object.assign(this.levelModel.attributes, this.config.data.level.attributes);

    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();
    }, 100);
  }

  onSubmit() {
    const levelRequest: LevelRequest = { data: {} };
    Object.assign(levelRequest.data, this.levelModel.attributes);
    console.log('originalLevel', this.originalLevel);
    if (this.originalLevel?.id > 0) {
      this.levelService.putlevelsid(levelRequest, this.originalLevel.id).toPromise().then(
        level => {
          console.log('updated level', level);
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: level.title });
          this.ref.close(level);
        }
      )
    } else {
      this.levelService.postlevels(levelRequest).toPromise().then(
        newLevel => {
          console.log('newLevel', newLevel);
          console.log('application', this.config.data.application);

          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newlevel.title });
          const applicationRequestData: ApplicationRequestData = {};
          //Object.assign(applicationRequestData, this.config.data.application);
          // this.config.data.application.attributes.levels.data.push(newLevel.data.id);
          let levelIds = this.config.data.application.attributes?.levels.data.map(level => level.id);
          console.log('levelIds', levelIds);
          levelIds.push(newLevel.data.id);
          console.log('levelIds new', levelIds);
          const applicationRequest: ApplicationRequest = { data: { levels: levelIds } };
          console.log('application', this.config.data.application);
          console.log('applicationRequest', applicationRequest);
          this.applicationService.putapplicationsid(applicationRequest, this.config.data.application.id).toPromise().then(updatedApp => {
            console.log('Updated application', updatedApp);
            this.ref.close(newLevel);
          })
        })
    }
  }

  onCancel() {
    this.ref.close();
  }

}
