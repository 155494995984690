import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { UploadFile, UploadFileService, UploadididFileInfo, } from '../swagger-api-v4';
import { DynamicDialogRef, DialogService, DynamicDialogConfig, } from 'primeng/dynamicdialog';
import { Utilities } from '../utilities';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { MSG_DELETE_CONFIRM, MSG_DELETE_CONTENT_CONFIRM, } from '../utils/messages';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ContentFormComponent } from '../content-form/content-form.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { BreadcrumbService } from '../breadcrumb.service';
import { environment } from 'src/environments/environment';
import { CustomerListResponse, Resource, UsersPermissionsUser } from 'resources/swagger-api-v4';
import { FileUpload } from 'primeng/fileupload';
import { CustomerResponse } from 'resources/swagger-api';

@Component({
  selector: 'app-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.scss'],

})

export class ContentLibraryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt') table: Table;
  // @ViewChild('op') op: OverlayPanel;
  // @ViewChild('opUpload') opUpload: OverlayPanel;
  @ViewChild('autoc') autoc: ElementRef;


  public showSelector: boolean = false;
  // @Input() set library(showSelectButtons: boolean) {
  //   this.showSelector = showSelectButtons;
  // }

  Util = Utilities;
  overlayVisible = false;

  contents: UploadFile[] = [];
  selectedContent: UploadFile;

  cols = [
    // { field: 'id', header: 'Id' },
    { field: 'name', header: 'Name', type: 'Text', width: '300px' },
    { field: 'ext', header: 'Extension', type: 'Text', width: '200px' },
    // { field: 'mime', header: 'Mime', type: 'Text' },
    { field: 'size', header: 'Size', type: 'Number', width: '6em' },
    { field: 'tags', header: 'Tags', type: 'Text', width: '200px' },
    //{ field: 'createdAt', header: 'Created', type: 'Date' },
    { field: 'updatedAt', header: 'Uploaded', type: 'Date', width: '9em' },
    // {
    //   field: 'related',
    //   header: 'Used in Nugget(s)',
    //   type: 'Array',
    //   width: '300px',
    // },
    { field: 'actions', header: 'Actions', type: 'Action', width: '100px' },
  ];

  first = 0;

  extensions: SelectItem[] = [];
  mimes: SelectItem[] = [];

  filteredTags: string[] = [];
  newTag = "";
  autocdisabled = false;
  showUpload = false;
  uploadedFiles: any[] = [];
  files = [];
  currentFiles = [];
  tags: string[] = [];
  emptyText = '';
  previewContent: UploadFile;
  sub: Subscription;

  user: UsersPermissionsUser;
  customers: SelectItem[] = [];
  selectedCustomer = 0;


  // allContents: UploadFile[] = [];
  // public disabled = false;

  myObservableObject$: Observable<any>;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private uploadFileService: UploadFileService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private http: HttpClient,
  ) {


  }

  ngOnInit(): void {
    this.user = environment.user;
    this.customers = this.user.customers.map(customer => {
      return { value: customer['id'], label: customer.name };
    });
    this.selectedCustomer = Number(localStorage.getItem('selectedCustomerId'));
    if (this.selectedCustomer > 0 && this.user.customers.length === 1) {
      this.selectedCustomer = this.user.customers[0]['id'];
    }
    if (environment.user.role.type === 'cms_admin') {
      this.customers.push({ value: -1, label: '(no customer)' });
    }
    this.showSelector = this.config?.data?.selector;
    this.emptyText = 'Loading media files...';
    this.changeCustomer(null);
    // this.refreshData();
    this.currentFiles = [];
    this.uploadedFiles = [];
  }

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
  }

  ngAfterViewInit() {

    const items: MenuItem[] = [{ label: 'Content Library' }];
    this.breadcrumbService.setItems(items);

    // this.store.select('contents').subscribe((contents) => {
    //   // this.allContents = contents;
    //   setTimeout(() => {
    //     this.contents = contents;
    //     console.log('content subsription', this.contents.length);
    //     this.updateFilters();
    //   })
    // });


    // map(x => x.payload)).subscribe(data => {
    // const sub = this.actions$
    //   .pipe(filter((x) => x.type === CONTENTS_GET_SUCCESS))
    //   .subscribe((data) => {
    //     console.log('got success in component', data);
    //     this.contents = data['contents'];
    //     sub.unsubscribe();
    //   });


    // this.store.dispatch({ type: CONTENTS_GET });

  }


  changeCustomer(event) {
    localStorage.setItem('selectedCustomerId', '' + this.selectedCustomer);
    if (this.selectedCustomer == -1) {
      this.getOrphanFiles()
    } else {
      this.refreshData();
    }
  }


  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    // this.http.get('cms-server/upload/files', { headers: headers }).toPromise().then((files: File[]) => {
    // Get all resource ids for this customer
    this.http.get('cms-server/resources?[filters][customers][id][$in]=' + this.selectedCustomer, { headers: headers }).toPromise().then((result: any) => {
      // Get all customers and media IVAR
      const resourceIds: number[] = result.data.map(resource => resource.id);
      if (resourceIds.length === 0) {
        this.contents = [];
        this.first = 0;
        return;
      }
      // const filter = resourceIds.map((id, idx) => 'filters[id][$in][' + idx + ']=' + id).join('&');
      // this.http.get('cms-server/resources?' + filter + '&populate[customers]=*&[populate][media]=*', { headers: headers }).toPromise().then((result: any) => {
      this.http.get('cms-server/resources?populate[customers]=*&[populate][media]=*', { headers: headers }).toPromise().then((result: any) => {
        console.log('no of result', result.data.length);
        result.data = result.data.filter(file => resourceIds.includes(file.id));
        console.log('no of result after filtering', result.data.length);
        this.contents = [];
        result.data.forEach(data => {
          if (data.attributes.customers.data.length > 0) {
            const newfile = data.attributes.media.data.attributes;
            newfile['id'] = data.attributes.media.data.id;
            newfile['resourceId'] = data.id;
            newfile['customers'] = data.attributes.customers;
            this.contents.push(newfile);
          }
        });
        this.contents.forEach((content) => {
          if (content.caption !== null && content.caption.length !== 0) {
            content['tags'] = content.caption.split(',');
          } else {
            content['tags'] = [];
          }
        });
        this.updateTags();
        this.contents = [...this.contents];
        this.first = 0;
      },
        (err) => {
          console.log('error', err);
        })
    },
      (err) => {
        console.log('error', err);
      })
  }

  updateTags() {
    this.contents.forEach((content) => {
      if (content.caption !== null && content.caption.length !== 0) {
        content['tags'] = content.caption.split(',');
      } else {
        content['tags'] = [];
      }
    });
    this.updateFilters();
  }

  // Get all files from resources, then all files that are not in resources
  getOrphanFiles() {
    this.contents = [];
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('cms-server/resources?[populate][media][fields]=id', { headers: headers }).toPromise().then((result: any) => {
      console.log('files in resources', result.data.length);
      const mediaIds: number[] = result.data.map(resource => resource.attributes.media.data.id);
      const filter = mediaIds.map((id, idx) => 'filters[id][$notIn][' + idx + ']=' + id).join('&');
      console.log('filter length', filter.length);
      // this.http.get('cms-server/upload/files?' + filter, { headers: headers }).toPromise().then((files: any) => {
      this.http.get('cms-server/upload/files', { headers: headers }).toPromise().then((files: any) => {
        files = files.filter(file => !mediaIds.includes(file.id));
        console.log('files not in resources', files.length);
        files.forEach((file) => {
          file['resourceId'] = 0;
          file['customers'] = [];
          this.contents.push(file);
        });
        this.updateTags();
        this.contents = [...this.contents];
        this.first = 0;
      })
    })

  }

  updateFilters() {
    const exts = [];
    this.contents.forEach((item) => {
      exts.findIndex((x) => x.label == item.ext) == -1
        ? exts.push({ label: item.ext, value: item.ext })
        : 0;
    });
    exts.sort((a, b) => a.value.localeCompare(b.value));
    this.extensions = [...exts];

    const mims = [];
    this.contents.forEach((item) => {
      mims.findIndex((x) => x.label == item.mime) == -1
        ? mims.push({ label: item.mime, value: item.mime })
        : 0;
    });
    mims.sort((a, b) => a.value.localeCompare(b.value));
    this.mimes = [...mims];
  }

  onDateSelect(value) {
    this.table.filter(this.formatDate(value), 'date', 'equals');
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onRowSelect(event) {
    // console.log('selected 'ert event.data);
    // this.op.toggle(event);
    // this.imagePreviewUrl = this.Util.getUrl(event.formats.thumbnail.url);
    // this.selectedApplication = event.data;
    // this.showEditDialog(event.data);
  }

  onCancel() {
    this.ref.close();
    this.changeCustomer(null);
  }

  onSubmit() {
    this.ref.close(this.selectedContent);
  }

  onUpload(event) {
    console.log('onUpload', event);
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    console.log('size', this.uploadedFiles.length, this.uploadedFiles);
  }

  removeFile(deleteFile: File) {
    this.currentFiles = this.currentFiles.filter(
      (file) => deleteFile.name !== file.name
    );
  }

  onDeleteContent(content: UploadFile) {
    this.confirmationService.confirm({
      message: this.Util.format(
        MSG_DELETE_CONTENT_CONFIRM,
        content.name + content.ext
      ),
      accept: () => {

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
        this.http.delete('cms-server/resources/' + content['resourceId'], { headers: headers }).toPromise().then((result: any) => {
          this.uploadFileService.uploadFilesIdDelete('' + content.id).toPromise().then((res) => {
            this.contents = this.contents.filter((con) => con.id !== content.id);
          },
            (err) => {
              console.log(err);
            }
          )
        }, (err) => {
          console.log(err);
        }
        )
      }
    })
  }

  myUploader(event) {
    console.log('myUploader', event);
    event.files.forEach((file: File) => {
      console.log('file =', file);
      if (file['status'] !== 'Done' && file['status'] !== 'Uploading') {
        let fil: any = file;
        fil.status = 'Uploading';
        fil.progress = 0;

        let info = {
          alternativeText: this.tags.toString(),
          caption: this.tags.toString(),
          name: file.name,
        };

        let formData: FormData = new FormData();
        formData.append('files', file, file.name);
        formData.append('fileInfo', JSON.stringify(info));
        console.log('upload', formData);

        // const uploadididFileInfo: UploadididFileInfo = {
        //   name: file.name,
        //   alternativeText: this.tags.toString(),
        //   caption: this.tags.toString()
        // }


        // this.uploadFileService
        //   .uploadPostForm('', '', '', '', [file])
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
        this.http.post('cms-server/upload', formData, { headers: headers })
          .subscribe((files: UploadFile[]) => {
            console.log('subscribed', files);
            // public uploadPostForm(path: string, refId: string, ref: string, field: string, files: Array<Blob>, observe: any = 'body', reportProgress: boolean = false )
            //
            console.log('upload event', event);
            // if (event.type === HttpEventType.UploadProgress || event.type === HttpEventType.DownloadProgress) {
            //   fil.progress = event.loaded === 0 ? 0 : Math.round((event.loaded * 100 / event.total));
            //   // console.log('progress', fil.progress);
            // }
            // if (event.type === HttpEventType.ResponseHeader) {
            //   console.log('ResponseHeader');
            // }
            // if (event.type === HttpEventType.Response) {
            //   console.log('Response');
            //   // fil.progress = 100;
            //   fil.status = 'Done';
            //   // update tags and write again            this.uploadFileService.uploadSearchIdGet
            //   if (
            //     event.body[0].caption !== null &&
            //     event.body[0].caption.length !== 0
            //   ) {
            //     event.body[0]['tags'] = event.body[0].caption.split(',');
            //   } else {
            //     event.body[0]['tags'] = [];
            //   }
            //   this.contents.push(event.body[0]);
            //   this.uploadedFiles.push(event..body[0]);

            files.forEach(file => {
              file['tags'] = file['caption'].split(',');
              this.uploadedFiles.push(file);
            })

            if (this.selectedCustomer > 0) {
              this.writeResource(files);
            }
            this.refreshData();

          }),
          res => {
            // fil.status = 'Done';
            console.log('uploaded res = ', res);
            this.refreshData();
            // this.changeCustomer(null);

            // // update tags and write again            this.uploadFileService.uploadSearchIdGet
            // this.contents.push(res[0]);
            // this.uploadedFiles.push(res[0]);



            // fil.status = 'Done';
            // // update tags and write again            this.uploadFileService.uploadSearchIdGet
            // if (
            //   event.body[0].caption !== null &&
            //   event.body[0].caption.length !== 0
            // ) {
            //   event.body[0]['tags'] = event.body[0].caption.split(',');
            // } else {
            //   event.body[0]['tags'] = [];
            // }
            // this.contents.push(event.body[0]);
            // this.uploadedFiles.push(event.body[0]);
          },
          err => {
            fil.status = 'Error';
            console.log('error', err);
          }
      }
    })

    // this.uploadFileService.uploadPost(formData).toPromise().then(
    //   res => {
    //     fil.status = 'Done';
    //     console.log('uploaded', res);
    //     // update tags and write again            this.uploadFileService.uploadSearchIdGet
    //     this.contents.push(res[0]);
    //     this.uploadedFiles.push(res[0]);
    //   },
    //   err => { fil.status = 'Error'; console.log('error', err); }
    // )
  }

  writeResource(files: UploadFile[]) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    const customerId = this.selectedCustomer;
    files.forEach(file => {
      const resourceRequest = { data: { customers: [customerId], media: file.id } };
      console.log('post', resourceRequest);
      this.http.post('cms-server/resources', resourceRequest, { headers: headers }).toPromise().then(resource => {
        console.log('resource', resource);
      },
        (err) => {
          console.log('error', err);
        })


    })
  }

  onSelect(event) {
    console.log('onSelect', event);
    this.currentFiles = event.currentFiles;
    this.currentFiles.forEach((file) => {
      if (file.status === undefined) {
        file.status = 'Pending';
      }
    });
  }

  onPreview(event: Event, content: UploadFile) {
    console.log('onPreview', event, content);
    this.previewContent = content;
    this.overlayVisible = !this.overlayVisible;
    console.log('overlayVisible', this.overlayVisible);
    // this.op.toggle(event);
    // this.imagePreviewUrl = this.Util.getUrl(content.formats.thumbnail.url);
    // console.log(this.Util.getUrl(content.formats.thumbnail.url));
    // console.log(content);
  }

  hideUpload() {
    this.showUpload = false;
    this.changeCustomer(null);
    // this.refreshData();
  }

  onShowUpload(event) {
    this.currentFiles = [];
    // this.opUpload.show(event);
    this.showUpload = true;

  }

  //   onBlur() {
  //     this.disabled = true;
  //     setTimeout(() => (this.disabled = false));
  // }

  onKeyDown(event) {
    console.log('onKeyDown', event);
  }

  addTag(event) {
    console.log('addTag event : ', event);
    console.log('newTag', this.newTag);
    if (this.newTag.length > 0) {
      this.autocdisabled = true;
      this.tags.push(this.newTag);
      console.log('tags', this.tags);
      setTimeout(() => (this.autocdisabled = false));
    }
    this.newTag = "";
  }

  filterTags(event) {
    console.log('filterTags(event)', event);
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: string[] = [event.query];
    let query = event.query;
    const uniqueTagSet = new Set<string>()
      .add('Articulate')
      .add('3D')
      .add('Cat: BatteryTechnologies')
      .add('Cat: Electrotechnology')
      .add('Cat: Operation')
      .add('Cat: Safety&Hazards')
      .add('Cat: Service&Maintenance')
      .add('Cat: Towing&Recovery')
      .add('Cat: Procedure&SupportDocs');

    this.contents.forEach((content) => {
      if (content.caption != null) {
        content.caption.split(',').forEach((tag) => {
          if (tag.length > 0) uniqueTagSet.add(tag.trim());
        })
      }
    })
    console.log('set', uniqueTagSet);
    const uniqueTags = Array.from(uniqueTagSet).sort();
    // console.log('uniqueTags', uniqueTags);

    // uniqueTags.forEach(tag=> )

    for (let i = 0; i < uniqueTags.length; i++) {
      let tag = uniqueTags[i];
      if (tag.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tag);
      }
    }

    this.filteredTags = filtered;
  }

  onRowDblClick(event) {
    console.log('onRowDblClick', event);
    if (this.showSelector) {
      this.onSubmit()
    } else {
      this.showEditDialog(event);
    }
  }

  showEditDialog(content: UploadFile) {
    this.ref = this.dialogService.open(ContentFormComponent, {
      header: content.id > 0 ? 'Edit Content' : 'New Content',
      width: '600px',
      data: {
        content: content,
        allContents: this.contents
      },
    });

    this.ref.onClose.subscribe((content) => {
      // if (content) {
      this.changeCustomer(null);

      //   let idx;

      //   if (
      //     (idx = this.contents.findIndex((cont) => cont.id === content.id)) >= 0
      //   ) {
      //     console.log('idx', idx);
      //     this.uploadFileService
      //       .uploadFilesIdGet(content.id)
      //       .toPromise()
      //       .then((newContent) => {
      //         const theContent: any = newContent;
      //         if (
      //           theContent.caption !== null &&
      //           theContent.caption.length !== 0
      //         ) {
      //           theContent['tags'] = theContent.caption.split(',');
      //         } else {
      //           theContent['tags'] = [];
      //         }
      //         this.contents[idx] = theContent;
      //         this.contents = [...this.contents];
      //       });
      //   } else {
      //     this.contents.push(content);
      //     // this.parentLevel.nuggets.push(nugget);
      //     // let newLevel: NewLevel = {};
      //     // Object.assign(newLevel, this.parentLevel);
      //     // this.levelService.levelsIdPut(newLevel, this.parentLevel.id).toPromise().then(
      //     //   level => { },
      //     //   err => {
      //     //     this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
      //     //   }
      //     // );
      //   }
      // }
    });
  }
}
