<app-breadcrumbs></app-breadcrumbs>
<hr />

<p-toast></p-toast>

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<!-- <div class="header">Applications</div> -->

<!-- <div [ngStyle]="{ 'height': showSelector ? '100%' : '100vh' } "> -->
<!-- <div [ngStyle]="{ 'height': '100%' }" style="background-color:lightgray;"> -->

<!-- <p-table [columns]="cols" [value]="applications" dataKey="id" selectionMode="single"
  [(selection)]="selectedApplication" (onRowSelect)="onRowSelect($event)" [resizableColumns]="true"
  [reorderableColumns]="true" [scrollable]="true" [scrollHeight]="'calc(100vh - 170px)'">
  <ng-template pTemplate="header" let-columns> -->

<div style="max-height: calc(100vh - 120px); overflow-y: scroll;">
  <p-table [columns]="cols" [value]="applications" dataKey="id" selectionMode="single"
    [(selection)]="selectedApplication" (onRowSelect)="onRowSelect($event)" [resizableColumns]="true"
    [reorderableColumns]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn
          [style]="col.width ? ' width:' + col.width : ''">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th style="width: 260px">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-expanded="expanded">
      <tr [pSelectableRow]="rowData" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData"
        (dblclick)="onRowDblClick(rowData)">
        <td *ngFor="let col of columns" class="ui-resizable-column" style="white-space: normal">
          <!-- <ng-container *ngIf="col.field === 'title'">
                    <span><i [pRowToggler]="rowData"
                            [class]="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i></span>&nbsp;
                </ng-container> -->
          <ng-container *ngIf="col.type === 'Date'">
            {{ Util.formatDate(rowData.attributes[col.field]) }}
          </ng-container>
          <ng-container *ngIf="col.type !== 'Date'">
            <!-- <span [pTooltip]="rowData[col.field]" tooltipPosition="top" showDelay="100" hideDelay="0"
                        style="display: inline-block;"> -->
            {{ rowData.attributes[col.field] }}
            <!-- </span>&nbsp; -->
            <!-- <span pTooltip="IVAR" tooltipPosition="top" tooltipEvent="focus" showDelay="1000"
                        style="display:inline-block;" hideDelay="500">hej</span>
                    {{ rowData[col.field] }} -->
          </ng-container>
        </td>
        <td>
          <span pTooltip="Levels" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-forward" (click)="gotoRoute(rowData)"></button> </span>&nbsp;
          <span pTooltip="Edit" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-edit" (click)="showEditDialog(rowData)"></button> </span>&nbsp;
          <span pTooltip="Feeds" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-rss" (click)="onOpenFeeds(rowData)"
              [disabled]="rowData.attributes.title.substring(0,1)==='@'">
            </button>
          </span>&nbsp;
          <span pTooltip="Export for translation" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-download" (click)="downloadApplicationTexts(rowData)"></button>
          </span>&nbsp;
          <span pTooltip="Import translation" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-upload" (click)="onOpenFileUpload(rowData)">
            </button>
          </span>&nbsp;
          <span pTooltip="Delete" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-times" class="p-button-danger"
              (click)="deleteApplication(rowData)"></button>
          </span>
          <!-- <span [pTooltip]="
            rowData?.levels?.length > 0
              ? 'Cannot delete with levels present'
              : 'Delete'
          " tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-times" class="p-button-danger"
              (click)="deleteApplication(rowData)" [disabled]="rowData?.levels?.length > 0"></button>
          </span> -->
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="rowexpansion" let-rowData>
        <td colspan="6">
            <app-levels [app]="rowData" [appLevels]="rowData.levels"></app-levels>
        </td>
    </ng-template> -->
    <!-- <ng-template pTemplate="summary">
      <div class="right grouped-buttons">
        <span><button pButton type="button" label="Clone" icon="fas fa-clone" [disabled]="!selectedApplication"
            (click)="copyApplication($event)"></button> </span>&nbsp;
        <span>
          <button pButton type="button" label="New Application" icon="fas fa-plus"
            (click)="newApplication($event)"></button>
        </span>
      </div>
    </ng-template> -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div style="text-align: center">Loading applications or no applications found</div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br>
<div class="right grouped-buttons">
  <span>
    <button pButton type="button" label="Move" icon="fas fa-grip-horizontal"
      [disabled]="!selectedApplication" (click)="showMoveApplication()"></button>&nbsp;
  </span>
  <span>
    <button pButton type="button" label="Clone" icon="fas fa-clone" [disabled]="!selectedApplication"
      (click)="copyApplication($event)"></button> </span>&nbsp;
  <span>
    <button pButton type="button" label="New Application" icon="fas fa-plus" (click)="newApplication($event)"></button>
  </span>
</div>


<!-- {{ selectedApplication | json}} -->

<!-- <app-application-form [application]="selectedApplication"></app-application-form> -->

<!-- <app-levels [appLevels]="selectedApplication?.levels"></app-levels> -->
<!-- <div *ngIf="selectedApplication">
    <app-levels [app]="selectedApplication" [appLevels]="selectedApplication.levels"></app-levels>
</div> -->

<!-- <app-content-library></app-content-library> -->

<p-dialog header="Import Translation" [(visible)]="displayUpload" [modal]="true" [style]="{ width: '80vw' }"
  [baseZIndex]="1000" [draggable]="true" [resizable]="true" closable="true">
  <div>
    <div *ngIf="!importFinished">
      Importing : {{ importText }}
      <br>
      <br>

      <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <br>
    </div>
    <p-messages [(value)]="msgsImport"></p-messages>
    <br>
    <div>
      <p-fileUpload #fubauto customUpload="true" mode="basic" accept="application/json"
        (uploadHandler)="myUploader($event, fubauto)" [auto]="true" chooseLabel="Select File">
      </p-fileUpload>
      <div class="grouped-buttons" style="text-align: right;">
        <p-button icon="pi pi-check" (click)="displayUpload = false" label="Close" styleClass="p-button-text">
        </p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Clone" [(visible)]="displayModal" [modal]="true" [style]="{ width: '70vw' }" [baseZIndex]="1000"
  [draggable]="false" [resizable]="false">
  <div>
    <div *ngIf="!cloneFinished">
      Cloning : {{ cloneText }}
      <br>
      <br>
      <!-- <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar> -->
      <p-progressBar  [value]="progressValue"></p-progressBar>
      <br>
    </div>
    <p-messages [(value)]="msgs"></p-messages>
    <br>
    <!-- <ng-template pTemplate="footer"> -->
    <div class="grouped-buttons" style="text-align: right;">
      <p-button (click)="closeCopyApplication($event)" label="Ok" [disabled]="!cloneFinished"
        styleClass="p-button-text"></p-button>
    </div>
    <!-- </ng-template> -->
  </div>
</p-dialog>


<p-dialog header="Move application to another course" [(visible)]="displayMoveModal" [modal]="true" [baseZIndex]="1000" [draggable]="false"
  [resizable]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <div>
    <div  style="display: block;">
      <p-listbox [options]="courses" [(ngModel)]="selectedCourse" [style]="{width: '600px'}"></p-listbox>

      <!-- <p-listbox [options]="groupedCourses" [group]="true" [(ngModel)]="selectedCourse" [metaKeySelection]="false"
        [checkbox]="true" [filter]="true" [multiple]="true" [listStyle]="{'max-height':'250px'}"
        [style]="{'width':'15rem'}"> -->
      <!-- <p-listbox [options]="groupedCourses" [(ngModel)]="selectedCourse" [group]="true" [metaKeySelection]="false"
        [checkbox]="false" [filter]="false" [multiple]="false" [listStyle]="{'max-height':'650px'}"
        [style]="{'width':'25rem'}"> -->
      <!-- <ng-template let-group pTemplate="group">
          <div class="p-d-flex p-ai-center">
            <span>{{group.label}}</span>
          </div>
        </ng-template>
      </p-listbox> -->
    </div>
    <hr>
    <br>
    <div>
      <div class="right grouped-buttons">
        <button pButton type="cancel" label="Cancel" (click)="onMoveCancel()"
          class="ui-button-secondar ui-button"></button>
        <button pButton type="submit" label="Move" (click)="onMove()" [disabled]="!selectedCourse" class="ui-button"></button>
      </div>
    </div>
  </div>
</p-dialog>
