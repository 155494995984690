import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from './breadcrumb.service';
import { ContentLibraryComponent } from './content-library/content-library.component';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { NavigationEnd, Router } from '@angular/router';
import { ContentUtilityService } from './content-utility.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService],
  host: {
    '(window:online)': 'handleConnection($event)',
    '(window:offline)': 'handleConnection($event)'

    // '(window:resize)': 'onResize($event)',
    // '(document:click)': 'onMouseClick($event)',
    // '(touchend)': 'onMouseClick($event)',
    // '(window:popstate)': 'onPopstate($event)'
  },

})
export class AppComponent implements OnInit, AfterViewInit {
  public showHeader = true;
  public showBreadcrumbs = false;
  public showMenu = false;
  darkTheme: boolean = false;
  showFooter = false;
  public menuItems: MenuItem[];

  contentRef: DynamicDialogRef;


  // window.addEventListener( 'resize', onWindowResize, false );

  constructor(
    private breadcrumbService: BreadcrumbService,
    public dialogService: DialogService,
    private messageService: MessageService,
    // private store: Store<{ configs: Config[] }>,
    private contentUtilityService: ContentUtilityService,
    private router: Router,

    public ref: DynamicDialogRef,
  ) {
    this.contentUtilityService.contents;

    // self.addEventListener('fetch', (event: any) => {
    //   console.log('Hello service worker event!!!!!!!! ev event=', event);
    //   if (event.request.method !== 'GET') return;
    //   const request = event.request.clone();
    //   console.log('fetching!', request);
    //   // Do stuff
    //   event.respondWith(fetch(request));
    // });
  }


  getServerUrl() {
    return window.location.origin;
    //  return document.getElementById('serverUrl').value || window.location.origin;
  }



  ngOnInit() {

    this.menuItems = [
      {
        label: 'Tools',
        items: [
          {
            label: 'Content Library',
            icon: 'fas fa-photo-video',
            routerLink: '/content-library',
            command: () => {
              // this.update();
            },
          },
          // {
          //   label: 'Delete',
          //   icon: 'pi pi-times',
          //   command: () => {
          //     // this.delete();
          //   }
          // }
        ],
      },
      {
        label: 'Navigate',
        items: [
          // {
          //     label: 'Angular Website',
          //     icon: 'pi pi-external-link',
          //     url: 'http://angular.io'
          //   },
          {
            label: 'HiVo Tester',
            icon: 'fas fa-link',
            routerLink: '/hivo',
          },
          {
            label: 'HiVo Nugget Demo',
            icon: 'fas fa-link',
            routerLink: '/demo',
          },
          {
            label: 'Log out',
            icon: 'fas fa-sign-in-alt',
            routerLink: '/welcome-cms',
          },
        ],
      },
    ];
  }

  ngAfterViewInit() {
  }

  changeTheme() {
    // const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    const styleLink: HTMLLinkElement = document.getElementById(
      'style-css'
    ) as HTMLLinkElement;
    if (this.darkTheme) {
      // themeLink.href = 'assets/themes/mdc-dark-indigo/theme.css';
      // themeLink.href = 'assets/themes/luna-blue/theme.css';
      styleLink.href = 'assets/themes/style-dark.css';
    } else {
      // themeLink.href = 'assets/themes/mdc-light-indigo/theme.css';
      // themeLink.href = 'assets/themes/nova-light/theme.css';
      styleLink.href = 'assets/themes/style-light.css';
    }
  }

  showMediaLibDialog() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Media Library',
      height: '90%',
      style: {'max-height': '90%'},
      width: '90%',
      // data: {
      //   nugget: nugget
      // }
    });
  }

  reloadPage() {
    window.location.reload();
  }
}
