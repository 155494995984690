<p-card>
  <form #appform="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="title">Title</label>
      </div>
      <div class="p-col form-input-field">
        <input #titleInput #title="ngModel" name="title" id="title" type="text" maxlength="100" size="100" pInputText
          autocomplete="off" [(ngModel)]="levelModel.attributes.title" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="title.valid || title.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="description">Description</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="levelModel.attributes.description" style="width: 400px;"></textarea>
      </div>
    </div>



  </form>
</p-card>

<br>
<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!appform.form.valid"
    class="ui-button"></button>
</div>
