import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '../breadcrumb.service';
import { UsersPermissionsUser } from '../swagger-api-v4';
// import { GetCMS, StateCMS, StateCustomer } from '../store/cms.action';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
})
export class CmsComponent implements OnInit, AfterViewInit {
  // cms: StateCMS = {};
  loading: boolean = false;
  user: UsersPermissionsUser;
  constructor(
    private breadcrumbService: BreadcrumbService,
    // private store: Store<{ cms: StateCMS }>
  ) {
    this.loading = false;
    // this.store.select('cms').subscribe((cms) => {
    //   this.cms = cms;
    //   if (this.cms.customers) {
    //     console.log('CMS content', cms);
    //     this.loading = false;
    //   } else {
    //     // this.loading = true;
    //     // this.store.dispatch(GetCMS());
    //   }
    // });
  }

  ngAfterViewInit(): void {
    this.breadcrumbService.setItems([]);
  }

  ngOnInit(): void {
    this.user = environment.user;
  }
}
