import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnswerService } from './api/answer.service';
import { ApplicationService } from './api/application.service';
import { CourseService } from './api/course.service';
import { CustomerService } from './api/customer.service';
import { ExamService } from './api/exam.service';
import { FeedService } from './api/feed.service';
import { LevelService } from './api/level.service';
import { NuggetService } from './api/nugget.service';
import { ProgressService } from './api/progress.service';
import { ResourceService } from './api/resource.service';
import { SettingService } from './api/setting.service';
import { UploadFileService } from './api/uploadFile.service';
import { UsersPermissionsAuthService } from './api/usersPermissionsAuth.service';
import { UsersPermissionsUsersRolesService } from './api/usersPermissionsUsersRoles.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AnswerService,
    ApplicationService,
    CourseService,
    CustomerService,
    ExamService,
    FeedService,
    LevelService,
    NuggetService,
    ProgressService,
    ResourceService,
    SettingService,
    UploadFileService,
    UsersPermissionsAuthService,
    UsersPermissionsUsersRolesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
