import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreadcrumbService } from '../breadcrumb.service';
import { CourseExportFormComponent } from '../course-export-form/course-export-form.component';
import { CourseFormComponent } from '../course-form/course-form.component';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { Course, CourseRequestData, CourseResponseDataObject, CourseService } from '../swagger-api-v4';
import { Utilities } from '../utilities';
import { CourseListResponse, CourseRequest } from 'resources/swagger-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerListResponse } from 'resources/swagger-api-v4';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class CoursesComponent implements OnInit {
  ref: DynamicDialogRef;
  // courses: StateCourse[] = [];
  courses: CourseResponseDataObject[] = [];
  selectedCourse: CourseResponseDataObject = null;

  customerId: number = 0;
  customerName: string;

  Util = Utilities;

  cols = [
    // { field: 'id', header: 'Id' },
    // { field: 'sortOrder', header: 'SortOrder', type: 'Numeric', width: '4em' },
    { field: 'name', header: 'Name', type: 'Text' },
    { field: 'doceboCourseId', header: 'Docebo course id', type: 'Info' },
    //{ field: 'url', header: 'URL', type: 'URL' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private courseService: CourseService,
    public dialogService: DialogService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private http: HttpClient,
 ) {
    this.customerId = Number(localStorage.getItem('customerId'));
    this.customerName = localStorage.getItem('customerName');

  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {

    const items: MenuItem[] = [
      { label: this.customerName, routerLink: '/customers' },
      { label: 'Courses', routerLink: '/courses' },
    ];
    this.breadcrumbService.setItems(items);
  }

  newCourse(event) {
    const newCourse: Course = {
      name: '',
      //      customer:  { id: this.uxSelections.customerId },
      info: {
        doceboCourseId: 0,
      },
    };
    const newCourseData: CourseResponseDataObject = { id: 0, attributes: newCourse };
    this.selectedCourse = newCourseData;

    this.showEditDialog(newCourseData);
  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/customers?filters[id]=' + this.customerId + "&populate[courses][populate]=*&populate[courses][sort]=name",
      { headers: headers }).toPromise().then((customers: CustomerListResponse) => {
        console.log('Courses', customers.data);
        if (customers.data.length > 0) {
          this.courses = customers.data[0].attributes.courses.data.filter(course =>
            (course.attributes.isDeleted === null || course.attributes.isDeleted === false));
        }
      }, (err => {
        this.showError('Error while getting courses.\n' + err.message);
      }))

  }



  formatDate(date) {
    return Utilities.formatDate(date);
  }

  gotoRoute(rowData) {
    localStorage.setItem('courseId', rowData.id);
    localStorage.setItem('courseName', rowData.attributes.name);
    this.router.navigate(['/applications']);
  }

  showEditDialog(course: CourseResponseDataObject) {
    this.ref = this.dialogService.open(CourseFormComponent, {
      header: course.id > 0 ? 'Edit Course' : 'New Course',
      width: '600px',
      data: {
        course: course,
        customerId: this.customerId,
      },
    });

    this.ref.onClose.subscribe((course) => {
      if (course) {
        this.refreshData();
      }
    });
  }

  showExportDialog(course: CourseResponseDataObject) {
    console.log('clicked on course', course);
    this.ref = this.dialogService.open(CourseExportFormComponent, {
      header: 'Export Course',
      width: '90%',
      // label: course.id + " - " + course.Name,
      data: {
        course: course
      },
    });

    this.ref.onClose.subscribe((Course) => {
      if (Course) {
        this.refreshData();
      }
    });
  }


  export(rowData) {
    console.log('Export course : ', rowData);
  }

  deleteCourse(course: CourseResponseDataObject) {
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, course.attributes.name),
      accept: () => {
        let updateCourse: CourseRequest = { data: { isDeleted: true } };
        this.courseService.putcoursesid(updateCourse, course.id).toPromise().then(res => {
          let index = this.courses.findIndex(
            (c) => c.id === res.data.id
          );
          if (index != -1) {
            this.courses.splice(index, 1);
          }
        }, (err) => {
          this.showError("Could not delete course!\n" + err.message);
        })
      },
    });
  }

  showError(msg) {
    this.messageService.add({ key: 'error', severity: 'error', summary: 'Error', detail: msg });
  }

}
