<p-card>
  <form #form="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="name">Name</label>
      </div>
      <div class="p-col form-input-field">
        <input #nameInput #name="ngModel" name="name" id="name" type="text" size="100" pInputText
          [(ngModel)]="contentModel.name" required minlength="1" maxlength="300" style="width:400px">
        <span [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="extension">Extension</label>
      </div>
      <div class="p-col form-input-field">
        {{ contentModel?.ext }}
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="size">Size</label>
      </div>
      <div class="p-col form-input-field">
        {{ Util.formatBytes(contentModel?.size*1024,0) }}
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="uploaded">Uploaded</label>
      </div>
      <div class="p-col form-input-field">
        {{ Util.formatDate(contentModel['updatedAt']) }}
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="tags">Tags</label>
      </div>
      <div>
        <p-autoComplete #tagsInput #tags="ngModel" name="tags" id="tags" appendTo="body" class="p-col form-input-field"
          [(ngModel)]="contentModel['tags']" [suggestions]="filteredTags" (completeMethod)="filterTags($event)"
          [multiple]="true" [dropdown]="true" [forceSelection]="false">
        </p-autoComplete>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="media">Media</label>
      </div>
      <div class="p-col form-input-field">

        <ng-container *ngIf="replaceFile === null">
          <!-- A {{ contentModel  | json}}  X -->
          <!-- <ng-container *ngIf="contentModel.mime.startsWith('image') && contentModel.formats!==null">
            <img [src]='Util.getUrl(contentModel?.formats?.thumbnail?.url)' alt="Preview"
              [width]="contentModel?.formats?.thumbnail?.width">
          </ng-container> -->
          <ng-container *ngIf="contentModel.mime.startsWith('image')">
            <img class="preview" [src]='Util.getUrl(contentModel.url)' alt="Preview">
          </ng-container>

          <ng-container *ngIf="contentModel.mime.startsWith('video')">
            <video class="preview" controls autoplay>
              <source [src]="Util.getUrl(contentModel?.url)">
              Sorry, your browser do not support video playback.
            </video>
          </ng-container>

          <ng-container *ngIf="!contentModel.mime.startsWith('video') && !contentModel.mime.startsWith('image')">
            <i class="far fa-file fa-7x p-col"></i>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="replaceFile">
          <ng-container *ngIf="replaceFile.type.startsWith('image')">
            <div class="media-preview">
              <img [src]='replaceFile?.objectURL' alt="Preview">
            </div>
          </ng-container>

          <ng-container *ngIf="replaceFile.type.startsWith('video')">
            <div class="media-preview">
              <video width="400" controls autoplay>
                <source [src]="replaceFile?.objectURL">
                Sorry, your browser do not support video playback.
              </video>
            </div>
          </ng-container>

          <ng-container *ngIf="!replaceFile.type.startsWith('video') && !replaceFile.type.startsWith('image')">
            <i class="fas fa-file fa-7x"></i>
          </ng-container>
          <br>
          {{ replaceFile.name }}
        </ng-container>

        <br><br>
        <p-fileUpload #fubauto mode="basic" [name]="selectedNames" customUpload="true" chooseIcon="fas fa-file-upload"
          (uploadHandler)="myUploader($event)" (onUpload)="onBasicUploadAuto($event)" [auto]="true"
          chooseLabel="Replace media file">
        </p-fileUpload>

        <br>
        <button *ngIf="isPushable()" pButton label="Unzip contents to education server" (click)="onPush()"
          class="ui-button-secondar ui-button"></button>
        <br>
        <p-messages [(value)]="msgs"></p-messages>


      </div>
      <!-- {{ contentModel|json}} -->
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="manages">Customers</label>
      </div>
      <div class="p-col form-input-field">
        <p-multiSelect [style]="{'max-width': '400px'}" autocomplete="false" #manages="ngModel" name="manages"
          appendTo="body" [options]="customers"  [(ngModel)]="selectedCustomers" display="chip"
          defaultLabel="Select customer(s)"></p-multiSelect>
      </div>
    </div>
 <!-- [required]="true" -->

    <!-- <div class="p-grid">
            <div class="form-group p-col-fixed form-input-label">
                <label for="locale">Locale</label>
            </div>
            <div class="p-col form-input-field">
                <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.locale" appendTo="body">
                </p-dropdown>
            </div>
        </div> -->
  </form>
</p-card>

<br>

<div class="right grouped-buttons">
  <button pButton type="cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" [disabled]="!saveEnabled" (click)="onSubmit()"
    [disabled]="!form.form.valid" class="ui-button"></button>
</div>
