import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../breadcrumb.service';
import { AuthLocalBody, UsersPermissionsAuthService, UsersPermissionsUser, UsersPermissionsUserRegistration } from '../swagger-api-v4';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-welcome-cms',
  templateUrl: './welcome-cms.component.html',
  styleUrls: ['./welcome-cms.component.scss']
})
export class WelcomeCmsComponent implements OnInit, AfterViewInit {

  @ViewChild("usernameInputBox") usernameInputBox: ElementRef;

  cmsusername = ''; // 'testuser';
  cmspassword = ''; // 'password';
  error = false;
  errorOthers = false;
  errormessage = '';
  signInDisabled = false;

  items: MenuItem[] = [{ label: 'Welcome' }];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private http: HttpClient,
    private usersPermissionsAuthService: UsersPermissionsAuthService,
    public route: Router) {
    this.breadcrumbService.setItems(this.items);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.error = false;
    this.errorOthers = false;
    this.signInDisabled = false;
    this.usernameInputBox.nativeElement.focus();
  }

  signIn() {
    this.signInDisabled = true;

    const body: AuthLocalBody = {
      identifier: this.cmsusername,
      password: this.cmspassword
    }

    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.post('/cms-server/auth/local', body, {}).toPromise().then((result: UsersPermissionsUserRegistration) => {
      if (result) {
        localStorage.setItem('jwt', result.jwt);
        environment.cmsAccessToken = result.jwt;
        this.route.navigate(['/']);
      }
      this.signInDisabled = false;
    }, (err => {
      console.log('err', err);
      this.signInDisabled = false;
      console.error(err.status);
      if (err.status === 400) {
        this.errormessage = err.error.error.message;
        this.error = true;
      } else {
        this.errorOthers = true;
      }
    }))
  }
}
