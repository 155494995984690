<p-card>
  <form #theform="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="question">Question</label>
      </div>
      <div class="p-col form-input-field">
        <textarea #questionInput #question="ngModel" name="question" id="question" size="500" maxlength="500"
          autoResize="true" pInputTextarea required [(ngModel)]="examModel.attributes.question" style="width: 100%;"></textarea>
        <span [hidden]="question.valid || question.pristine" class="alert alert-danger">
          Question is required
        </span>

      </div>
    </div>
  </form>

  <p-table [value]="examModel.attributes?.answers?.data" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th>Answer</th>
        <th style="width: 100px">Correct</th>
        <th style="width: 50px">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-answer>
      <tr>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <textarea size="500" maxlength="500" autoResize="true" pInputTextarea required [(ngModel)]="answer.attributes.answer"
                style="width: 100%;"></textarea>
              <!-- <input pInputText type="text" [(ngModel)]="answer.answer" width="100%"> -->
            </ng-template>
            <ng-template pTemplate="output">
              {{ answer.attributes.answer }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-checkbox [(ngModel)]="answer.attributes.correct" binary="true" inputId="binary"></p-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <p-checkbox [(ngModel)]="answer.attributes.correct" binary="true" inputId="binary"></p-checkbox>
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <button pButton type="button" icon="fas fa-times" class="p-button-danger"
            (click)="deleteAnswer(answer)"></button>

        </td>
      </tr>
    </ng-template>
  </p-table>



</p-card>

<!-- binary : {{ binaryvalue }} -->

<!-- {{ examModel.answers | json}} -->

<br>

<div class="right grouped-buttons">
  <button pButton type="button" label="New Answer" icon="fas fa-plus" (click)="newAnswer($event)"
    [disabled]="examModel?.attributes.answers?.data.length>= 10"></button>
  <button pButton type="cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!theform.form.valid || examModel.attributes.answers?.data.length < 1"
    class="ui-button"></button>
</div>
