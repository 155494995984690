<br><br>

<div class="p-grid">
  <div class="p-col"></div>
  <div class="p-col signin-card">
    <!-- <p-card header="DEKRA Education CMS" [style]="{'width': '25rem', 'margin-bottom': '4em'}"> -->
    <p-card header="DEKRA - Control Center" [style]="{'width': '25rem', 'margin-bottom': '4em'}">
      <br>
      <form>
        <div class=" p-field p-grid">
          <label for="cmsusername" class="p-col-fixed" style="width:100px">Username</label>
          <div class="p-col">
            <input #usernameInputBox id="cmsusername" name="cmsusername" autocomplete="username" type="text"
              [(ngModel)]="cmsusername" pInputText (change)="error=false">
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="cmspassword" class="p-col-fixed" style="width:100px">Password</label>
          <div class="p-col">
            <input id="cmspassword" autocomplete="current-password" name="cmspassword" type="password"
              [(ngModel)]="cmspassword" pInputText (change)="error=false">
          </div>
        </div>
        <p-message *ngIf="error" severity="error" [text]="errormessage"></p-message>
        <!-- <p-message *ngIf="error" severity="error" text="Username or password invalid."></p-message> -->
        <p-message *ngIf="errorOthers" severity="error" text="Error connecting to CMS server."></p-message>
        <!-- <ng-template pTemplate="footer"> -->
        <br><br>
        <p-button label="Sign in" icon="fas fa-sign-in-alt" [disabled]="signInDisabled" type="submit"
          (onClick)="signIn()"></p-button>
      </form>
      <br><br>
      <!-- <a href="forgot-password">Forgot password?</a> -->
      <!-- <span><a routerLink="/forgot-password">Forgot password?</a></span> -->

      <!-- </ng-template> -->
    </p-card>
  </div>
  <div class="p-col"></div>
</div>
