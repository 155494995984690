import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { ApplicationsComponent } from './applications/applications.component';
import { AuthStrapiGuard } from './auth-strapi.guard';
import { ContentLibraryComponent } from './content-library/content-library.component';
import { LevelsComponent } from './levels/levels.component';
import { NuggetsComponent } from './nuggets/nuggets.component';
import { WelcomeCmsComponent } from './welcome-cms/welcome-cms.component';
import { CmsComponent } from './cms/cms.component';
import { FeedsComponent } from './feeds/feeds.component';
import { CustomersComponent } from './customers/customers.component';
import { CoursesComponent } from './courses/courses.component';
import { UsersComponent } from './users/users.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

export const routes: Routes = [
  // { path: 'x', component: AppMainComponent },
  // { path: '', component: DekraHomepageComponent },

  // {
  // path: '', component: AppMainComponent,
  // children: [
  // { path: 'welcome', component: WelcomeComponent },
  { path: 'welcome', component: WelcomeCmsComponent },
  { path: '', component: CmsComponent, canActivate: [AuthStrapiGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthStrapiGuard] },
  { path: 'courses', component: CoursesComponent, canActivate: [AuthStrapiGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthStrapiGuard] },
  { path: 'applications', component: ApplicationsComponent, canActivate: [AuthStrapiGuard] },
  { path: 'levels', component: LevelsComponent, canActivate: [AuthStrapiGuard] },
  { path: 'nuggets', component: NuggetsComponent, canActivate: [AuthStrapiGuard] },
  { path: 'content-library', component: ContentLibraryComponent, canActivate: [AuthStrapiGuard] },
  { path: 'feeds', component: FeedsComponent, canActivate: [AuthStrapiGuard] },
  // ]
  // },

  { path: 'error', component: AppErrorComponent },
  { path: 'accessdenied', component: AppAccessdeniedComponent },
  { path: '404', component: AppNotfoundComponent },
  { path: 'login', component: AppLoginComponent },
  //{ path: '**', component: LoaderScreenComponent }
  { path: '**', redirectTo: '/404' },

];

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'
    // useHash: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', anchorScrolling: 'enabled'
});
