import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { CONTENTS_GET, CONTENTS_GET_SUCCESS } from './store/contents.action';
import { UploadFile } from './swagger-api-v4';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root'
})
export class ContentUtilityService {

  contents: UploadFile[] = [];

  constructor(
    // private store: Store,
    // private actions$: Actions,
    // private uploadFileService: UploadFileService,
    private http: HttpClient
  ) {

    // console.log('ContentUtilityService constructor');
    // this.store.dispatch({ type: CONTENTS_GET });
    // const sub = this.actions$
    //   .pipe(filter((x) => x.type === CONTENTS_GET_SUCCESS))
    //   .subscribe((data: any) => {
    //     console.log('allContent', data.contents);
    //     this.contents = data.contents;
    //     if (this.contents.length > 0) sub.unsubscribe();
    //   });
  }

  // When matching "Intro-016-HiVo-AU-EN-GB.mp3" it only matches on all lowercases.
  async getFileUrl(filename: string, setToLowerCase = false, contentUrl = false): Promise<string> {
    // if (setToLowerCase) {
    //   filename = filename.toLocaleLowerCase();
    // }

    // console.log('getFileUrl', filename, setToLowerCase);
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    console.log('getFileUrl headers', headers);
    const contents = await this.http.get<UploadFile[]>('/cms-server/upload/files?filters[name][$eq]=' + filename + '&fields[0]=url&fields[1]=name', { headers: headers }).toPromise();
    //const contents = await this.uploadFileService.uploadSearchIdGet(filename).toPromise();
    console.log('contents', contents, 'filename', filename);
    if (contents.length >= 0) {
      return contents[0].url;
    } else {
      console.log('Could not find content file url for filename ', filename);
      return null;
    }
    // const foundContent = contents.find(content => {
    //   // console.log('name', content.name, content.name.localeCompare(filename));
    //   let match = false;
    //   if (setToLowerCase) {
    //     match = (content.name.toLocaleLowerCase().localeCompare(filename) === 0);
    //   } else {
    //     match = (content.name.localeCompare(filename) === 0);
    //   }
    //   return match;
    // });
    // // console.log('foundContent', foundContent);
    // if (foundContent === undefined) {
    //   console.log('Could not find content file url for filename ', filename);
    //   return null;
    // } else {
    //   // console.log('Found content file url for filename ', filename);
    //   if (contentUrl) {
    //     return foundContent.url;
    //   } else {
    //     return Utilities.getUrl(foundContent.url);
    //   }
    // }
  }


  async getFileContent(filename: string, setToLowerCase = false): Promise<UploadFile> {
    // if (setToLowerCase) {
    //   filename = filename.toLocaleLowerCase();
    // }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    console.log('getFileContent headers', headers);
    const contents = await this.http.get<UploadFile[]>('/cms-server/upload/files?filters[name][$eq]=' + filename, { headers: headers }).toPromise();
    // const contents = await this.uploadFileService.uploadSearchIdGet(filename).toPromise();
    const foundContent = contents.find(content => {
      let match = false;
      if (setToLowerCase) {
        match = (content.name.toLocaleLowerCase().localeCompare(filename) === 0);
      } else {
        match = (content.name.localeCompare(filename) === 0);
      }
      return match;
    });
    if (foundContent === undefined) {
      console.log('Could not find content file url for filename ', filename);
      return null;
    } else {
      return foundContent;
    }
  }


  getFileUrlX(filename: string) {
    // console.log('getFileUrl', filename);
    // console.log('contents = ', this.contents);
    const carContent = this.contents.filter(content => content.name === filename)
    console.log('carContent', carContent);
    if (carContent.length === 0) {
      console.log('Could not find file in redux content library', filename);
      return 'File not foundd in redux content library : ' + filename;
    } else {
      return Utilities.getUrl(carContent[0].url);
    }
  }

}
