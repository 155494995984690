<span *ngIf="!showSelector">
  <app-breadcrumbs></app-breadcrumbs>
  <hr />
</span>

<p-toast></p-toast>

<!-- [scrollable]="true" -->
<!-- [responsive]="true" -->
<!-- autoLayout="true" -->
<!-- scrollHeight="700px"> -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<!-- height: auto;
max-height: 500px;
overflow-y: scroll; -->



<!-- <div class="p-grid">
  <div class="form-group form-input-label">
    <label for="tags">Tags</label>
  </div>
  <div>
    <p-autoComplete #autoc class="p-col form-input-field" [(ngModel)]="tags" [suggestions]="filteredTags"
      (completeMethod)="filterTags($event)" [multiple]="true" [dropdown]="true" [forceSelection]="false"
      [disabled]="autocdisabled">
    </p-autoComplete>
  </div>
</div> -->


<p-overlayPanel #op [(visible)]="overlayVisible" styleClass="p-sidebar-lg" position="left" [fullScreen]="true"
  styleClass="p-sidebar-lg" position="left" [fullScreen]="true" [blockScroll]="false" [blockScroll]="false"
  [responsive]="{ breakpoint: '640px', direction: 'bottom', contentStyleClass: 'h-20rem' }"
  contentStyleClass="p-4 surface-overlay shadow-2 border-round">
  OVERFLAY CONTENT
  <!-- <ng-template> -->
  <img [src]='Util.getUrl(previewContent?.formats?.large?.url)' alt="Image Preview"
    [width]="previewContent?.formats?.large?.width">
  <!-- </ng-template> -->
</p-overlayPanel>
<!-- [style]="{width:'900px'}" -->
<!-- <div *ngIf="showSelector" [ngStyle]="{ 'height': showSelector ? :'80%' }"> -->
<!-- <div style="height: auto;max-height: 90%;overflow-y: scroll;"> -->
<!-- <div style="height: calc(100vh - 140px);"> -->
<!-- <div style="max-height: calc(100vh - 120px); overflow-y: scroll;"> -->
<!-- <div
  [ngStyle]="{'max-height': showSelector ? 'calc(100% - 60px)' : 'calc(100vh - 120px)', 'overflow-y': 'scroll',  'overflow-x': 'auto' }"> -->

<!-- style="height:90vh; display:inline-flex;" -->

<!-- [ngStyle]="{'height': showSelector ? 'calc(100% - 60px)' : 'calc(100vh - 120px)' }"> -->
<div style="background-color: #ff00ff;">

  <p-table #dt [columns]="cols" [value]="contents" selectionMode="single" [(selection)]="selectedContent" dataKey="id"
    styleClass="p-datatable-lg" (onRowSelect)="onRowSelect($event)" [resizableColumns]="true" [filterDelay]="0"
    sortField="name" [sortOrder]="1" [globalFilterFields]="['name','ext','status','tags']" [scrollable]="true"
    [scrollHeight]="(showSelector ? 'calc(100% - 150px)' : 'calc(100vh - 210px)')" [paginator]="true" [rows]="10"
    [(first)]="first" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10,25,50,100]" [tableStyle]="{ 'min-width': '100%' }">

    <!-- [scrollHeight]="showSelector ? '80vh': '72vh'"> -->
    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
            placeholder="Global Search" />
        </span>
        <span class="abs-right grouped-buttons">
          <span *ngIf="customers.length > 1">Customer :&nbsp;<p-dropdown [options]="customers"
              [(ngModel)]="selectedCustomer" (onChange)="changeCustomer($event)"></p-dropdown></span>

          <!-- <button [disabled]="selectedCustomer <= 0" pButton label="Upload Media" (click)="onShowUpload($event)" -->
          <button pButton label="Upload Media" (click)="onShowUpload($event)"
            class="ui-button-secondar ui-button"></button>
          <ng-container *ngIf="showSelector">
            <button pButton type="cancel" label="Cancel" (click)="onCancel()"
              class="ui-button-secondar ui-button"></button>
            <button pButton type="submit" label="Select" (click)="onSubmit()" [disabled]="!selectedContent"
              class="ui-button"></button>
          </ng-container>
        </span>
      </div>
    </ng-template>

    <!-- <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template> -->

    <!-- <ng-template pTemplate=" colgroup">
    <colgroup>
        <col *ngFor="let col of cols" [style.width]="col.width">
    </colgroup>
    </ng-template> -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <!-- <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn pReorderableColumn -->
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
          [style]="col.width ? ' width:'+col.width : ''">
          {{col.header}}
          <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
        </th>
        <!-- <th>Action</th> -->
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData"
        (dblclick)="onRowDblClick(rowData)">
        <td *ngFor=" let col of columns" class="ui-resizable-column" styleClass="p-column-filter">
          <ng-container *ngIf="col.type === 'Date'">
            {{ Util.formatDate(rowData[col.field]) }}
          </ng-container>
          <ng-container *ngIf="col.field === 'size'">
            {{ Util.formatBytes(rowData[col.field]*1024,0) }}
          </ng-container>
          <ng-container *ngIf="col.field === 'tags'">
            <p-chips *ngIf="rowData?.tags?.length > 0" [ngModel]="rowData.tags" disabled="true">
            </p-chips>
          </ng-container>
          <!-- <ng-container *ngIf="col.field === 'related'">
            <ng-container *ngFor="let content of rowData[col.field]; let i = index; let last = last;">
              <span *ngIf="!last">{{ content?.title }}, </span>
              <span *ngIf="last">{{ content?.title }}</span>
            </ng-container>
          </ng-container> -->
          <ng-container *ngIf="col.field === 'actions'">
            <span [pTooltip]="(rowData?.related?.length > 0) ? 'Cannot delete when used by nuggets' :  'Delete'"
              tooltipPosition="top" style="display:inline-block;">
              <button pButton type="button" icon="fas fa-times" class="p-button-danger"
                (click)="onDeleteContent(rowData)" [disabled]="(rowData?.related?.length > 0)"></button>
            </span>&nbsp;
            <span pTooltip="Download" tooltipPosition="top" style="display:inline-block;">
              <a [href]="Util.getUrl(rowData.url)" [download]="rowData.name"><button pButton type="button"
                  icon="fas fa-download"></button></a>
            </span>
            <!-- zz&nbsp; -->
            <!-- <span *ngIf="rowData.mime.startsWith('image') && rowData.formats?.large?.url" pTooltip="Preview"
              tooltipPosition="top" style="display:inline-block;">
              <button pButton type="button" icon="fas fa-search" (click)="onPreview($event, rowData)"></button>
            </span> -->
          </ng-container>
          <ng-container
            *ngIf="col.type !== 'Date' && col.field !== 'size' && col.field !== 'related'  && col.field !== 'tags'  && col.field !== 'actions'">
            {{ rowData[col.field] }}
          </ng-container>
        </td>
        <!-- <td>
                    <span [pTooltip]="(rowData?.related?.length > 0) ? 'Cannot delete when used by nuggets' :  'Delete'"
                        tooltipPosition="top" style="display:inline-block;">
                        <button pButton type="button" icon="fas fa-times" class="p-button-danger"
                            (click)="onDeleteContent(rowData)" [disabled]="(rowData?.related?.length > 0)"></button>
                    </span>&nbsp;
                    <span *ngIf="rowData.mime.startsWith('image') && rowData.formats?.large?.url" pTooltip="Preview"
                        tooltipPosition="top" style="display:inline-block;">
                        <button pButton type="button" icon="fas fa-search"
                            (click)="onPreview($event, rowData)"></button>
                    </span>
                </td> -->
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="summary">
        </ng-template> -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          <div style="text-align: center;">{{ emptyText }}
            <!-- <ng-container *ngIf="emptyText.startsWith('Load')">
                        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"
                            animationDuration=".5s">
                        </p-progressSpinner>
                    </ng-container> -->
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br>

<!-- <button pButton label="Upload Media" (click)="showUpload = !showUpload" -->

<!-- <div class="abs-right grouped-buttons">
<button pButton label="Upload Media" (click)="onShowUpload($event)" class="ui-button-secondar ui-button"></button>
<ng-container *ngIf="showSelector">
  <button pButton type="cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Select" (click)="onSubmit()" [disabled]="!selectedContent"
    class="ui-button"></button>
</ng-container>
</div> -->
<!-- <p-fileUpload name="myfile[]" url="./upload.php" multiple="multiple" accept="image/*" maxFileSize="1000000">
    <ng-template pTemplate="toolbar">
        <div>Upload Files</div>
    </ng-template>
    <ng-template let-file pTemplate="file">
        <div>Custom UI to display a file</div> {{file|json}}
    </ng-template>
    <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
    </ng-template> -->
<!-- <ng-template pTemplate="content" let-files>
        <div>Additional content.</div> {{ files|json}}
        x {{ files }}
    </ng-template> -->
<!-- </p-fileUpload> -->

<!-- </div> -->
<!-- </div> -->
<p-sidebar [(visible)]="showUpload" styleClass="p-sidebar-lg" position="left" [fullScreen]="true" [blockScroll]="false"
  (onHide)="hideUpload()">
  <!-- <p-overlayPanel #opUpload [showCloseIcon]="true" [style]="{'width': '98%'}" appendTo="body"> -->
  <!-- <ng-template pTemplate> -->
  <div>



    <!-- <br><br><br><br> -->

    <div>
      <h5>Tags</h5>
      <!-- <span class="p-fluid"> -->
      <!-- <span> -->
      <!-- <div>
        <input type="text" pInputText placeholder="Create new tag" [(ngModel)]="newTag"> -->
      <!-- <button type="button" pButton pRipple label="Add" (Click)="addTag($event)"></button> -->
      <!-- <p-button label="Add" pRipple (onClick)="addTag($event)"></p-button> -->
      <!-- </div>
      <br>  -->
      <div class="col-12 md:col-6">
        <p-autoComplete #autoc [(ngModel)]="tags" [suggestions]="filteredTags" (completeMethod)="filterTags($event)"
          [multiple]="true" [dropdown]="true" [forceSelection]="false" [autoHighlight]="true" [disabled]="autocdisabled"
          placeholder="Selected tag(s)">
        </p-autoComplete>
      </div>


      <!-- <div class="col-12 md:col-1"></div> -->

      <!-- <div class="col-12 md:col-6">
          <div class="p-inputgroup">
            <button type="button" pButton pRipple label="Add"></button>
            <input type="text" pInputText placeholder="New tag">
          </div>
        </div> -->

      <!-- <span class="p-float-label">
          <input id="float-input" type="text" pInputText [(ngModel)]="newTag">
          <label for="float-input">Create new tag</label>
        </span> -->
      <!-- </span> -->
      <!-- </div> -->


      <br>
      <!-- {{filteredTags|json}}
      <br>
      Tags: <br>{{ tags | json}} -->
      <!-- <p-autoComplete [(ngModel)]="tags" [suggestions]="filteredTags" (completeMethod)="filterTags($event)"
        [multiple]="true" [dropdown]="true" [forceSelection]="true">
      </p-autoComplete> -->

      <!-- <h5>Float Label</h5> -->
    </div>

    <div style="align-content: right;">

      <p-fileUpload [(files)]="files" customUpload="true" (uploadHandler)="myUploader($event)"
        (onUpload)="onUpload($event)" multiple="multiple" (onSelect)="onSelect($event)" (onClear)="currentFiles=[]"
        chooseLabel="Add Files">

        <!-- <ng-template pTemplate="toolbar">
          Toolbar
        </ng-template>

        <ng-template let-files pTemplate="file">
          File template
        </ng-template> -->

        <ng-template let-files pTemplate="content">
          <div *ngIf="uploadedFiles.length">
            <h5>Uploaded :</h5>
            <ul>
              <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>



    <!-- <div class="card" *ngIf="showUpload"> -->
    <!-- <div [ngStyle]="{'height': 'calc(100vh - 160px)' }">
      <p-table [value]="currentFiles" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="header">
          <tr>
            <th style="word-break: break-all; overflow-wrap: anywhere;">Name</th>
            <th style="width:120px;">Size</th>
            <th style="width:250px;">Status</th>
            <th style="width:170px;">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-file>
          <tr [pSelectableRow]="file" [pSelectableRow]="file">

            <td style=" word-break: break-all; overflow-wrap: anywhere;">{{file.name}}
            </td>
            <td>{{ Util.formatBytes(file.size,0) }}</td>
            <td>
              <span *ngIf="file?.status === 'Uploading' || file?.status === 'Done'">
                <p-progressBar [value]="file.progress"></p-progressBar>
              </span>
              <span *ngIf="file?.status !== 'Uploading' && file?.status !== 'Done'">{{file?.status}}
              </span>
            </td>
            <td>
              <button pButton type="button" icon="fas fa-times" class="p-button-danger"
                (click)="removeFile(file)"></button>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="3">
              <div style="text-align: center;">No files selected</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div> -->
  </div>
  <!-- <br><br><br><br><br><br><br> -->
  <!-- </ng-template> -->
  <!-- </p-overlayPanel> -->
</p-sidebar>
