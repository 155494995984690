<div class="exception-body notfound">
    <div class="exception-panel">
        <div class="exception-content">
            <!-- <img src="assets/layout/images/pages/icon-404.svg"> -->
            <h1>404 - Page Not Found</h1>
            <p>Requested resource is not available.</p>
            <button type="button" [routerLink]="['/']" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button" aria-disabled="false">
                <span class="ui-button-text ui-c">Home</span>
            </button>
        </div>
    </div>
</div>
