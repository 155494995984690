import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NuggetService, NuggetResponseDataObject, UploadFile, LevelService, LevelRequest, NuggetResponse, ApplicationLevelsAttributesNuggetsAttributesContentData } from '../swagger-api-v4';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { Utilities } from '../utilities';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { CourseResponse, NuggetRequest } from 'resources/swagger-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nugget-form',
  templateUrl: './nugget-form.component.html',
  styleUrls: ['./nugget-form.component.scss'],
  providers: [DialogService, ConfirmationService]

})
export class NuggetFormComponent implements OnInit {
  @ViewChild('titleInput') titleInput: ElementRef;


  contentRef: DynamicDialogRef;

  courseId = 0;

  Util = Utilities;

  nuggetModel: NuggetResponseDataObject = { id: 0, attributes: { title: '', description: '', learningTime: 0, exams: { data: [] } } };
  originalNugget: NuggetResponseDataObject = null;

  iconGroupNames = [];
  selectedIconGroupName = '';
  tagFilesMap: Map<string, UploadFile[]>;


  1144

  iconFiles: UploadFile[] = [];

  iconNrs: SelectItem[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  constructor(
    private nuggetService: NuggetService,
    private levelService: LevelService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private http: HttpClient,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.courseId = Number(localStorage.getItem('courseId'));

    console.log('oninit', this.config.data.nugget);
    this.getIconGroups();

    this.originalNugget = this.config.data.nugget;
    Object.assign(this.nuggetModel.attributes, this.config.data.nugget.attributes);
    Object.assign(this.nuggetModel.attributes.content, this.config.data.nugget.attributes.content);

    // this.nugget = Object.assign({}, this.config.data.nugget);
    // if (this.titleInput) {
    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();
    }, 100);

    // }
  }

  getIconGroups() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/courses/' + this.courseId + '?fields=iconGroupName', { headers: headers }).toPromise().then((course: CourseResponse) => {
      const iconGroupName = ((course.data.attributes.iconGroupName === null) ? 'HiVo' : course.data.attributes.iconGroupName);
      console.log('iconGroupName', iconGroupName);
      this.selectedIconGroupName = iconGroupName;

      this.http.get('/cms-server/upload/files?filters[caption][$contains]=IconGroup: ' + iconGroupName + '&fields[0]=caption&fields[1]=url&fields[2]=name&sort=name', { headers: headers }).toPromise().then((files: UploadFile[]) => {
        // console.log('icongroup files :', files);
        const tags = Utilities.getAllTags(files);
        const uniqueTags = new Set<string>();
        uniqueTags.add('HiVo');
        tags.forEach(tag => { if (tag.key === 'IconGroup') uniqueTags.add(tag.values[0]); });
        console.log('uniqueTags :', uniqueTags);
        this.iconGroupNames = Array.from(uniqueTags);

        const tagFilesMap = new Map<string, UploadFile[]>();
        this.iconGroupNames.forEach(iconGroupName => tagFilesMap.set(iconGroupName, []));
        files.forEach(file => {
          const tag = Utilities.getTagValuesOfKey(Utilities.getTags(file), 'IconGroup')[0];
          tagFilesMap.get(tag).push(file);
        })

        console.log('tagFilesMap:', tagFilesMap);
        this.tagFilesMap = tagFilesMap;

        this.iconFiles = this.tagFilesMap.get(iconGroupName).filter(file => {
          return ((file.name.endsWith('-A.png') || file.name.endsWith('-A.jpg')) && !file.name.includes('quiz'));
        });
        this.iconFiles.forEach((file, idx) => file['iconNr'] = idx + 1);

      }, (err => {
        console.error('Error while getting icongroups.\n' + err.message);
      }))
    }, (err => {
      console.error('Error while getting course iconGroupName (courseId=' + this.courseId + ').\n' + err.message);
    }))
  }


  // changedIconGroup(event) {
  //   // console.log('changedIconGroup', event);
  //   // console.log('changedIconGroup', event.value);
  //   // console.log('changedIconGroup', this.tagFilesMap.get(event.value));
  //   this.iconFiles = this.tagFilesMap.get(event.value).filter(file => {
  //     return ((file.name.endsWith('-A.png') || file.name.endsWith('-A.jpg')) && !file.name.includes('quiz'));
  //   });
  //   this.iconFiles.forEach((file, idx) => file['iconNr'] = idx + 1);
  //   // console.log('iconFiles', this.iconFiles);
  // }

  onSubmit() {
    let newNugget: NuggetRequest = { data: {} };
    Object.assign(newNugget.data, this.nuggetModel.attributes);
    newNugget.data.content = this.nuggetModel.attributes?.content?.data === null ? [] : this.nuggetModel.attributes.content.data.map(content => content.id);
    newNugget.data.exams = this.nuggetModel.attributes?.exams?.data.map(exam => exam.id);
    newNugget.data.feeds = this.nuggetModel.attributes?.feeds?.data.map(feed => feed.id);
    console.log('newNugget', newNugget);

    let nuggetIds = [];
    nuggetIds = this.config.data.level.attributes?.nuggets?.data.map(nugget => nugget.id);
    let levelRequest: LevelRequest = { data: { nuggets: nuggetIds } };
    console.log('levelRequest', levelRequest);
    if (this.originalNugget?.id > 0) {
      this.nuggetService.putnuggetsid(newNugget, this.originalNugget.id).toPromise().then(
        nugget => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: nugget.title });
          this.ref.close(nugget);
        }
      )
    } else {
      this.nuggetService.postnuggets(newNugget).toPromise().then(
        newNugget => {
          nuggetIds.push(newNugget.data.id);
          console.log('levelRequest+1', levelRequest);
          this.levelService.putlevelsid(levelRequest, this.config.data.level.id).toPromise().then(updatedLevel => {
            console.log('Updated Level', updatedLevel);
          })
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newnugget.title });
          this.ref.close(newNugget);
        }
      )
    }
  }

  onCancel() {
    this.ref.close();
  }

  onRemoveContent(content) {
    console.log('content', content);
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove "' + content.attributes.name + '"?',
      accept: () => {
        this.nuggetModel.attributes.content.data = this.nuggetModel.attributes.content.data.filter(cntnt => cntnt.id !== content.id);
      }
    });
  }

  upload() { }

  showMediaLibDialog() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      height: '100%',
      // style: 'max-height: 90%',
      width: '100%',
      data: {
        selector: true
      }
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('onclose', content);
      console.log('content', content);
      if (this.nuggetModel.attributes.content.data === null) {
        this.nuggetModel.attributes.content.data = [];
      }
      if (content !== undefined) {
        const nuggetContent: ApplicationLevelsAttributesNuggetsAttributesContentData = {
          id: Number(content.id),
          attributes: {}
        }
        Object.assign(nuggetContent.attributes, content);
        this.nuggetModel.attributes.content.data.push(nuggetContent);
      }



      // if (content) {
      //   console.log('content selected', content);
      //   //        this.nuggetModel.content.push(content);
      //   this.nuggetModel.attributes.content.data.push(content);
      //   // let idx;
      //   // if ((idx = this.nuggets.findIndex(alevel => alevel.id === nugget.id)) >= 0) {
      //   //   this.nuggets[idx] = nugget;
      //   // } else {
      //   //   this.nuggets.push(nugget);
      //   //   console.log('1 parentLevel', this.parentLevel);
      //   //   this.parentLevel.nuggets = this.nuggets;
      //   //   console.log('2 parentLevel', this.parentLevel);
      //   //   this.levelService.levelsIdPut(this.parentLevel, this.parentLevel.id).toPromise().then(
      //   //     app => { },
      //   //     err => {
      //   //       this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
      //   //     }
      //   //   );
      //   // }
      // }
    });
  }


  ngOnDestroy() {
    this.contentRef?.close();
  }

  setIcon(iconNr) {
    this.nuggetModel.attributes.iconNr = iconNr;
  }

}

