import { Component, OnInit, Input } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Utilities } from '../utilities';
import { Exam, Nugget, NuggetService, ExamService, AnswerService, ExamResponseDataObject, NuggetRequest, NuggetResponseDataObject, NuggetExamsData } from '../swagger-api-v4';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { ExamFormComponent } from '../exam-form/exam-form.component';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class ExamsComponent implements OnInit {

  Util = Utilities;

  examModel: ExamResponseDataObject = { id: 0, attributes: { question: '', correctAnswers: '' } };
  // originalExams: ExamResponseDataObject[] = [];

  ref: DynamicDialogRef;
  nugget: NuggetResponseDataObject;
  exams: ExamResponseDataObject[] = [];
  selectedExam: ExamResponseDataObject;
  cols = [
    // { field: 'id', header: 'Id' },
    { field: 'question', header: 'Exam Question' },
    // { field: 'correctAnswer', header: 'Correct Answer' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' }

  ];

  @Input() set nug(nug: NuggetResponseDataObject) {
    console.log('nug', nug);
    this.nugget = nug;
    this.selectedExam = null;
    this.exams = nug.attributes.exams.data;
  }

  constructor(
    private nuggetService: NuggetService,
    private confirmationService: ConfirmationService,
    private examService: ExamService,
    private answerService: AnswerService,
    private messageService: MessageService,
    public dialogService: DialogService) { }

  ngOnInit(): void {
  }

  newExam(event) {
    const newExam: ExamResponseDataObject = {
      id: 0,
      attributes: {
        question: '',
        answers: { data: [] }
      }
    }
    this.selectedExam = newExam;

    this.showEditDialog(newExam);
  }

  // formatDate(date) {
  //   return Utilities.formatDate(date);
  // }

  showEditDialog(exam: NuggetExamsData) {
    this.ref = this.dialogService.open(ExamFormComponent, {
      header: exam.id > 0 ? 'Edit Exam' : 'New Exam',
      width: '800px',
      data: {
        exam: exam,
        nugget: this.nugget
      }
    });


    this.ref.onClose.subscribe((exam) => {
      console.log('exam', exam);
      if (exam) {
        const idx = this.nugget.attributes.exams.data.findIndex(ex => (ex.id === exam.id && exam.id > 0));
        console.log('idx', idx);
        if (idx >= 0) {
          this.nugget.attributes.exams.data[idx] = exam;
        } else {
          // this.nugget.attributes.exams.data.push(exam);
        }

        // this.nugget.attributes.exams = exam;
        // if ((idx = this.exams.data.findIndex(anExam => anExam.id === exam.id)) >= 0) {
        //   console.log('idx',idx);
        //   this.nugget.attributes.exams.data[idx] = exam.data;
        // } else {
        //  // this.nugget.attributes.exams.data.push(exam.data);
        //   const examIds = this.nugget.attributes.exams.data.map(exam => { console.log('examMaap', exam); return exam.id; });
        //   console.log('examIds', examIds);
        //   let nuggetRequest: NuggetRequest = { data: { exams: examIds } };
        //   console.log('this.nugget', this.nugget, ' bugget', nuggetRequest);
        //   this.nuggetService.putnuggetsid(nuggetRequest, this.nugget.id).toPromise().then(
        //     nug => {
        //       console.log('updatedNugget', nug);
        //     },
        //     err => {
        //       this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
        //     }
        //   );
        // }
      }
    });
  }

  deleteExam(exam: NuggetExamsData) {
    console.log('deleteExam', exam);
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, exam.attributes.question),
      accept: () => {
        //        this.examModel
        this.examService.deleteexamsid(exam.id).toPromise().then(
          ret => {
            exam.attributes.answers.data.forEach(answer => {
              this.answerService.deleteanswersid(answer.id).toPromise().then(ret => {
                console.log('deleted answer', ret);
              }, err => {
                console.error('error deleting answer', err);
              })
            })

            let index = this.exams.findIndex(exm => exm.id === exm.id);
            if (index != -1) {
              this.exams.splice(index, 1);
            }
          },
          err => {
            console.log('error deleting exam', err);
          }
        )
      }
    });
  }

}
