<app-breadcrumbs></app-breadcrumbs>
<hr />

<p-toast></p-toast>
<!-- <p-calendar [(ngModel)]="nextrun" [inline]="true"></p-calendar> -->
<p-confirmDialog header="Confirmation"></p-confirmDialog>

<p-dialog header="Copy" [(visible)]="displayModal" [modal]="true" [style]="{ width: '70vw' }" [baseZIndex]="1000"
  [draggable]="false" [resizable]="false">
  <div>
    <div *ngIf="copyFinished">
      Finished!
    </div>
    <div *ngIf="!copyFinished">
      Copying : {{ copyText }}
      <br>
      <br>
      <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <br>
    </div>
    <p-messages [(value)]="msgs"></p-messages>
    <br>
    <!-- <ng-template pTemplate="footer"> -->
    <div class="grouped-buttons" style="text-align: right;">
      <p-button (onClick)="closeCopyApplication($event)" label="Ok" [disabled]="!copyFinished"
        styleClass="p-button-text"></p-button>
    </div>
    <!-- </ng-template> -->
  </div>
</p-dialog>


<p-dialog header="Copy feeds to course" [(visible)]="displayCopyModal" [modal]="true" [baseZIndex]="1000"
  [draggable]="false" [resizable]="true" [breakpoints]="{'960px': '95vw', '640px': '100vw'}" [style]="{width: '90vw'}">
  <div>
    <div style="display: block;">
      <p-listbox [options]="applications" [(ngModel)]="selectedApplication" [style]="{width: '100%'}"></p-listbox>
    </div>
    <hr>
    <br>
    <div>
      <div class="right grouped-buttons">
        <button pButton type="cancel" label="Cancel" (click)="onCopyCancel()"
          class="ui-button-secondar ui-button"></button>
        <button pButton type="submit" label="Copy" (click)="onCopy()" class="ui-button"></button>
        <!-- <button pButton type="submit" label="Move" (click)="onCopy()" [disabled]="!selectedCourse" class="ui-button"></button> -->
      </div>
    </div>
  </div>
</p-dialog>


<!-- <div class="header">Feeds</div> -->
<!-- [scrollable]="true"
[scrollHeight]="'calc(100vh - 170px)'" -->
<div style="max-height: calc(100vh - 120px); overflow-y: scroll;">
  <p-table [columns]="cols" [value]="feeds" selectionMode="multiple" [(selection)]="selectedFeeds" dataKey="id"
    [resizableColumns]="true" [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 10px"></th>
        <th style="width: 34px">#</th>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns" pResizableColumn pReorderableColumn
          [style]="col.width ? ' width:' + col.width : ''">
          {{ col.header }}
        </th>
        <th style="width: 150px">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index" [pSelectableRowDblClick]="rowData" (dblclick)="showEditDialog(rowData)">
        <td><span class="fas fa-grip-vertical" pReorderableRowHandle></span></td>
        <td>{{ index + 1 }}</td>
        <td> <p-tableCheckbox [value]="rowData"></p-tableCheckbox> </td>
        <td *ngFor="let col of columns" style="white-space: normal">
          <ng-container *ngIf="col.field === 'title'">
            <!-- <i [pRowToggler]="rowData"
                        [class]="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>&nbsp; -->
          </ng-container>

          <ng-container *ngIf="col.type === 'Date'">
            {{ formatDate(rowData.attributes[col.field]) }}
          </ng-container>
          <ng-container *ngIf="col.type !== 'Date'">
            {{ rowData.attributes[col.field] }}
          </ng-container>
        </td>
        <td>
          <!-- <span pTooltip="Nuggets" tooltipPosition="top" style="display:inline-block;">
                    <button pButton type="button" icon="fas fa-forward" (click)="gotoRoute(rowData)"></button>
                </span>&nbsp; -->
          <span pTooltip="Edit" tooltipPosition="top" style="display: inline-block">
            <button pButton type="button" icon="fas fa-edit" (click)="showEditDialog(rowData)"></button> </span>&nbsp;
          <button pButton type="button" icon="fas fa-times" class="p-button-danger"
            (click)="deleteFeed(rowData)"></button>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="rowexpansion" let-rowData>
        <td colspan="6">
            <app-levels [app]="rowData" [appLevels]="rowData.levels"></app-levels>
        </td>
    </ng-template> -->
    <!-- <ng-template pTemplate="summary">
      <div class="right">
        <span><button pButton type="button" label="New Feed" icon="fas fa-plus"
            (click)="newFeed($event)"></button></span>
      </div>
    </ng-template> -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div style="text-align: center">Loading feeds or no feeds found</div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br>
<div class="right grouped-buttons">
  <span><button pButton type="button" [disabled]="selectedFeeds.length===0"
      [label]="'Copy Feeds (' +selectedFeeds.length+')'" icon="fas fa-copy"
      (click)="showCopyFeeds()"></button></span>&nbsp;
  <span><button pButton type="button" label="Notifications" icon="fas fa-calendar-alt"
      (click)="onShowNotifications()"></button></span>&nbsp;
  <span><button pButton type="button" label="New Feed" icon="fas fa-plus" (click)="newFeed($event)"></button></span>
</div>


<p-dialog #notificationDialog header="Notifications" [(visible)]="displayScheduling" [modal]="true"
  [style]="{width: '70%', height: '80%', 'overflow':'visible'}" [baseZIndex]="100" [draggable]="true"
  [resizable]="true">
  <div *ngIf="notificationSettings.notifications">
    <!-- <p-card> -->
    <!-- <ng-template pTemplate="header"> Notifications </ng-template> -->
    <!-- <h2>Notifications</h2> -->

    <span class="p-field p-col-12">
      <label for="title">Title</label>&nbsp;
      <input #titleInput #title="ngModel" name="title" id="title" type="text" maxlength="100" size="100" pInputText
        [(ngModel)]="notificationSettings.notifications.title" required minlength="1" maxlength="100"
        style="width: 400px" autocomplete="off" />
    </span>

    <br />
    <br />

    <span class="p-field p-col-12">
      <label for="body">Body</label>&nbsp;
      <input #titleInput #body="ngModel" name="body" id="body" type="text" maxlength="500" size="500" pInputText
        [(ngModel)]="notificationSettings.notifications.body" required minlength="0" maxlength="500"
        style="width: 400px" autocomplete="off" />
    </span>

    <br />
    <br />


    <span class="p-field p-col-12">
      <label for="latest">Latest notification sent </label>&nbsp;
      {{ notificationSettings.notifications.latestNotification }}
    </span>

    <br />
    <br />

    <span class="p-field p-col-12">
      <label for="cur">Current feed no</label>&nbsp;
      <p-inputNumber inputId="cur" [(ngModel)]="notificationSettings.notifications.currentFeed"></p-inputNumber>
    </span>

    <br />
    <br />

    <span class="p-field p-col-12">
      <label for="next">Next feed notification in </label>&nbsp;
      <!-- <p-inputNumber inputId="next" (onInput)="updateNextNotification()" -->
      <p-inputNumber inputId="next" [(ngModel)]="notificationSettings.notifications.interval"></p-inputNumber>
      <span class="p-col form-input-field">
        <!-- <p-dropdown (onInput)="updateNextNotification()" name="interval" id="interval" [options]="intervals" -->
        <p-dropdown name="interval" id="interval" [options]="intervals"
          [(ngModel)]="notificationSettings.notifications.intervalUnit"></p-dropdown>
      </span>
    </span>

    <br />
    <br />

    <!-- <p-calendar [(ngModel)]="nextrun" [inline]="true"></p-calendar> -->

    <!-- <span class="p-field p-col-12"> -->
      <label for="basic">Start next notification at </label>&nbsp;
      <br>
      <p-calendar [style]="{'width':'100%'}" inputId="calendarTime" [(ngModel)]="nextrun" showTime="true" hourFormat="24" showButtonBar="true"
        dateFormat="yy-mm-dd" [showicon]="true" showWeek="true" appendTo="notificationDialog" [inline]="true">
      </p-calendar>

      <!-- <p-calendar inputId="calendarTime" [(ngModel)]="nextrun" showTime="true" hourFormat="24" showButtonBar="true"
        dateFormat="yy-mm-dd" [showicon]="true" showWeek="true" appendTo="notificationDialog">
      </p-calendar> -->
      &nbsp;&nbsp;
    <!-- </span> -->

    <!-- <span class="p-field p-col-12">
      <button pButton type="submit" label="Set" (click)="onSubmitSaveNotification()" class="ui-button"></button>
    </span> -->

    <br /><br /><br />

    <!-- <ng-template pTemplate="footer"> Footer content here </ng-template> -->
    <!-- </p-card> -->
  </div>

  <!-- <ng-template pTemplate="footer"> -->
  <div class="right grouped-buttons">
    <p-button (click)="displayScheduling=false" label="Cancel" styleClass="p-button-text"></p-button>
    <button pButton type="submit" label="Save" (click)="onSubmitSaveNotification()" class="ui-button"></button>
  </div>
  <!-- </ng-template> -->
</p-dialog>
