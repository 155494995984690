<!-- <p-toast></p-toast> -->

<!-- <div class="header">Exams</div> -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<p-table [columns]="cols" [value]="exams" selectionMode="single" [(selection)]="selectedExam" dataKey="id"
  [resizableColumns]="true" [reorderableColumns]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn pReorderableColumn
        [style]="col.width ? ' width:' + col.width : ''">
        {{ col.header }}
      </th>
      <th style="width: 120px">Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" (dblclick)="showEditDialog(rowData)">
      <td *ngFor="let col of columns" style="white-space: normal">
        <ng-container *ngIf="col.type === 'Date'">
          {{ Util.formatDate(rowData.attributes[col.field]) }}
        </ng-container>
        <ng-container *ngIf="col.type !== 'Date'">
          {{ rowData.attributes[col.field] }}
        </ng-container>
      </td>
      <td>
        <span><button pButton type="button" icon="fas fa-edit" (click)="showEditDialog(rowData)"></button></span>&nbsp;
        <span><button pButton type="button" icon="fas fa-times" class="p-button-danger"
            (click)="deleteExam(rowData)"></button></span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="right">
      <span><button pButton type="button" label="New Exam" icon="fas fa-plus"
          (click)="newExam($event)"></button></span>&nbsp;
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div style="text-align: center">No exams found</div>
      </td>
    </tr>
  </ng-template>
</p-table>
