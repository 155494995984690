/**
 * DOCUMENTATION
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { UploadFile } from '../model/uploadFile';
import { UploadididFileInfo } from '../model/uploadididFileInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from 'src/environments/environment';


@Injectable()
export class UploadFileService {

    protected basePath = '/cms-server';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFilesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UploadFile>>;
    public uploadFilesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UploadFile>>>;
    public uploadFilesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UploadFile>>>;
    public uploadFilesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
                console.log('accessToken: ', accessToken);
                headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UploadFile>>('get',`${this.basePath}/upload/files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**444444444444444444444444444444444
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFilesIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<UploadFile>;
    public uploadFilesIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFile>>;
    public uploadFilesIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFile>>;
    public uploadFilesIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadFilesIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UploadFile>('delete',`${this.basePath}/upload/files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadFilesIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<UploadFile>;
    public uploadFilesIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFile>>;
    public uploadFilesIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFile>>;
    public uploadFilesIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadFilesIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UploadFile>('get',`${this.basePath}/upload/files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Upload files
     * @param path
     * @param refId
     * @param ref
     * @param field
     * @param files
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPostForm(path: string, refId: string, ref: string, field: string, files: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<UploadFile>>;
    public uploadPostForm(path: string, refId: string, ref: string, field: string, files: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UploadFile>>>;
    public uploadPostForm(path: string, refId: string, ref: string, field: string, files: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UploadFile>>>;
    public uploadPostForm(path: string, refId: string, ref: string, field: string, files: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (path === null || path === undefined) {
            throw new Error('Required parameter path was null or undefined when calling uploadPost.');
        }

        if (refId === null || refId === undefined) {
            throw new Error('Required parameter refId was null or undefined when calling uploadPost.');
        }

        if (ref === null || ref === undefined) {
            throw new Error('Required parameter ref was null or undefined when calling uploadPost.');
        }

        if (field === null || field === undefined) {
            throw new Error('Required parameter field was null or undefined when calling uploadPost.');
        }

        if (files === null || files === undefined) {
            throw new Error('Required parameter files was null or undefined when calling uploadPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (path !== undefined) {
            formParams = formParams.append('path', <any>path) as any || formParams;
        }
        if (refId !== undefined) {
            formParams = formParams.append('refId', <any>refId) as any || formParams;
        }
        if (ref !== undefined) {
            formParams = formParams.append('ref', <any>ref) as any || formParams;
        }
        if (field !== undefined) {
            formParams = formParams.append('field', <any>field) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<UploadFile>>('post',`${this.basePath}/upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Upload file information
     * @param fileInfo
     * @param files
     * @param id File id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadididPostForm(fileInfo: UploadididFileInfo, files: Blob, id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UploadFile>>;
    public uploadididPostForm(fileInfo: UploadididFileInfo, files: Blob, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UploadFile>>>;
    public uploadididPostForm(fileInfo: UploadididFileInfo, files: Blob, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UploadFile>>>;
    public uploadididPostForm(fileInfo: UploadididFileInfo, files: Blob, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileInfo === null || fileInfo === undefined) {
            throw new Error('Required parameter fileInfo was null or undefined when calling uploadididPost.');
        }

        // if (files === null || files === undefined) {
        //     throw new Error('Required parameter files was null or undefined when calling uploadididPost.');
        // }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadididPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileInfo !== undefined) {
            formParams = formParams.append('fileInfo', <any>fileInfo) as any || formParams;
        }
        if (files !== undefined) {
            formParams = formParams.append('files', <any>files) as any || formParams;
        }

        return this.httpClient.request<Array<UploadFile>>('post',`${this.basePath}/upload?id=${encodeURIComponent(String(id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
