export const environment = {
  production: true,
  serviceWorkers: true,
  hmr: false,

  cmsAccessToken: null,
  user: null,
  cubeApplicationId: '0',
  knowledgeStoreApplicationId: '0',

  // OLDcmsSuperAdminUsername: 'cms@cms.com',
  // OLDcmsSuperAdminPassword: 'cmspassword',
  // cmsSuperAdminUsername: 'cms-api@cms.com',
  // cmsSuperAdminPassword: 'TY99S7WQUDFZUH6S',
  cmsSuperAdminUsername: 'info@stageit.com',
  cmsSuperAdminPassword: 'this-is-no-secret',


  doceboAuthUrl: '/auth-docebo/oauth2/token',
  //doceboAuthUrl: '/docebo/oauth2/token',
  // doceboClientId: 'hivo-web-app',
  // doceboSecret: 'ef5fbfe1ce4c7b6bad1eb029c91169248844e516',
  doceboClientId: 'hivo-portal',
  doceboSecret: '7d1ae0c68ad32d8275519d43425369098895d1a1',
  doceboGrantType: 'password',
  doceboScope: 'api',

  doceboUserAuthUrl: '/auth-user-docebo/oauth2/token',

  doceboEnrollmentUrl: '/docebo/learn/v1/enrollments',
  doceboUserUrl: '/docebo/manage/v1/user',
  // doceboCourseId: 235,
  // doceboCourseCode: 'E-Y1Q371',
  doceboCourseId: 978,
  doceboCourseCode: 'E-VO3KQ0',

  doceboSuperAdminUsername: 'XXXXX-hivo-api-superadmin',
  doceboSuperAdminPassword: 'XXXXX-v9LxZVR6NyUkk9gqBfuVG6Yq',
  // doceboSuperAdminUsername: 'hivo-superadmin',
  //  OLDdoceboSuperAdminPassword: 'hivo-superadmin',
  // doceboSuperAdminPassword: 'XV3TGH9FEX77WBMC',



  //web-push generate-vapid-keys --json
  vapidPublicKeyTest:
    'BOPIFGcyR3gL7fQeNvYS07YveomOIORpfy3SgqjAFejfF-Xn1aM9iBvR1MeH5BERNoYb1s7gdmx2d9SGD4UxqPM',
  vapidPrivateKeyTest: 'tdyObnilO0rPHy_IbXepHTtAtVAYhOuLSPjLVwMoA_k',

  vapidPublicKeyDev:
    'BIJ4E5Mc13BcTCy9YmxtttxU2Skzztbe9Es2Y5H0fpTVlzq4erIdmdEm4oKC_MaxJLdYEP8YmGUzt5vr9NgChIA',
  vapidPrivateKeyDev: 'zbThQqL96gh1oEfoYMxyr4dMtA4AJ7TFI2OLNSPu9Tg',

  vapidPublicKeyProdOLD:
    'BBVeiZzjk7KlRxvVuaFwz1xjOHDWd7-VKrfGjMFzCm8r0GnuywdRzZFKYBwRvdOGpT-zHYeHEtZNPbVWvWMqKXE',
  vapidPrivateKeyProdOLD: '5NFlYneUgEOP46omVndzy4uWuKdjQ_K5d93CThjv2r0',


  vapidPublicKeyProd:
    'BJXZN7twZYsEwDuBv3Gj04kO-MdhLed_K3k92n1nCu9-rQtz6C5mHeWzQ2fyfKmQ_TcfTM1-HGvGyENyunnd4Kg',
  vapidPrivateKeyProd: 'g7AN8wrFacPqnJcwLYZfVOihvDdxJTQUASpKNYcFWAw',
  subject: "mailto: <info-hivo@dekra.com>",

  jwtSecret: 'BAD20CAFE42',

  juniorUser: false,
  seniorPlusUser: false,
  lockTopLevels: false,

  gaCookie : true
};
