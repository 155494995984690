import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { ExamResponseDataObject, ExamRequest, ExamService, AnswerService, AnswerRequest, ExamAnswers, ExamAnswersData, NuggetRequest, NuggetService } from '../swagger-api-v4';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-exam-form',
  templateUrl: './exam-form.component.html',
  styleUrls: ['./exam-form.component.scss']
})
export class ExamFormComponent implements OnInit {
  @ViewChild('questionInput') questionInput: ElementRef;

  @Input() set exam(exam: ExamResponseDataObject) {
    this.originalExam = exam;
    // this.exam.answers.forEach(answer => {
    //   answer.alternative === 'true' ? answer.alternative = true : answer.alternative = false;

    // });
    this.examModel.id = exam.id;
    Object.assign(this.examModel.attributes, exam.attributes);
    let examAnswers: ExamAnswers = { data: [] };
    this.examModel.attributes.answers.data.forEach(answer => {
      const newAnswer: ExamAnswersData = { id: 0, attributes: {} };
      newAnswer.id = answer.id;
      Object.assign(newAnswer.attributes, answer.attributes);
      if (newAnswer.id > 0)
        examAnswers.data.push(newAnswer);
    })
    this.examModel.attributes.answers = examAnswers;
    this.deletedAnswers = { data: [] };
  };

  examModel: ExamResponseDataObject = { id: 0, attributes: { answers: { data: [] } } };
  originalExam: ExamResponseDataObject = null;
  newId = -10;
  deletedAnswers: ExamAnswers;

  constructor(
    private answerService: AnswerService,
    private nuggetService: NuggetService,
    private examService: ExamService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.exam = this.config.data.exam;
    // if (this.titleInput) {
    window.setTimeout(() => {
      this.questionInput.nativeElement.focus();
    }, 100);
    // }
  }

  onSubmit() {
    this.deletedAnswers?.data.forEach(answer => {
      this.answerService.deleteanswersid(answer.id).toPromise().then(
        res => { },
        err => {
          console.log(err);
        }
      )
    })

    let saves = 0;
    let allSaves = this.examModel.attributes.answers.data.length;
    this.examModel.attributes.answers.data.forEach(answer => {
      const answerRequest: AnswerRequest = { data: {} };
      Object.assign(answerRequest.data, answer.attributes);
      if (answer.id > 0) {
        this.answerService.putanswersid(answerRequest, answer.id).toPromise().then(
          answ => {
            console.log('Updated', answer.id);
            if (++saves === allSaves) {
              this.saveExam();
            }
          },
          err => {
            console.log(err);
          }
        );
      } else {
        this.answerService.postanswers(answerRequest).toPromise().then(
          answ => {
            answer.id = answ.data.id;
            console.log('Created', answer.id);
            if (++saves === allSaves) {
              this.saveExam();
            }
          },
          err => {
            console.log(err);
          }
        )
      }
    });
  }

  saveExam() {
    const examRequest: ExamRequest = { data: {} };
    Object.assign(examRequest.data, this.examModel.attributes);
    examRequest.data.answers = this.examModel.attributes.answers.data.map(answers => answers.id);
    console.log('this.originalExam', this.originalExam, 'examRequest', examRequest);
    if (this.originalExam?.id > 0) {
      this.examService.putexamsid(examRequest, this.originalExam.id).toPromise().then(
        exam => {
          // Object.assign(this.originalExam, this.examModel);
          this.updateNugget();
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: exam.question });
          this.ref.close(this.examModel);
        }
      )
    } else {
      this.examService.postexams(examRequest).toPromise().then(
        newexam => {
          console.log('newexam', newexam);
          // Object.assign(this.originalExam, this.examModel);
          const newExam = { id: newexam.data.id, attributes: newexam.data.attributes };
          this.config.data.nugget.attributes.exams.data.push(newExam);
          this.updateNugget();
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newexam.question });
          this.ref.close(this.examModel);
        }
      )
    }
  }

  updateNugget() {
    let idx;
    // if ((idx = this.examModel.findIndex(anExam => anExam.id === exam.id)) >= 0) {
    //   console.log('idx', idx);
    //   // this.config.data.nugget.attributes.exams.data[idx] = exam.data;
    // } else {
    const examIds = this.config.data.nugget.attributes.exams.data.map(exam => { console.log('examMaap', exam); return exam.id; });
    console.log('examIds', examIds);
    let nuggetRequest: NuggetRequest = { data: { exams: examIds } };
    console.log('this.nugget', this.config.data.nugget, ' bugget', nuggetRequest);
    this.nuggetService.putnuggetsid(nuggetRequest, this.config.data.nugget.id).toPromise().then(
      nug => {
        console.log('updatedNugget', nug);
      },
      err => {
        console.error(err);
        // this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
      }
    );
  }


  onCancel() {
    this.ref.close();
  }

  newAnswer(event) {
    let newAnswer: ExamAnswersData = { id: this.newId--, attributes: { correct: false, answer: '' } };
    console.log('examModel', this.examModel);
    if (this.examModel.attributes?.answers?.data === null) {
      this.examModel.attributes.answers.data = [newAnswer];
    } else {
      this.examModel.attributes.answers.data.push(newAnswer);
    }
  }

  deleteAnswer(event: ExamAnswersData) {
    const idx = this.examModel.attributes.answers.data.findIndex(answer => { return answer.id === event.id });
    if (idx >= 0) {
      if (event.id > 0) {
        this.deletedAnswers.data.push(event);
      }
      this.examModel.attributes.answers.data.splice(idx, 1);
    }
  }

}
 