import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { ContentUtilityService } from '../content-utility.service';
import { CourseExportUrl } from '../course-export-url.model';
// import { Application, ApplicationService, Content, CourseService, ExamService, LevelService, NewCourse, NuggetService } from '../swagger-api';
import { ApplicationLevelsAttributesNuggetsAttributesContentData, ApplicationResponse, ApplicationService, CourseRequest, CourseResponse, CourseResponseDataObject, CourseService, CustomerLogo, ExamService, LevelService, NuggetService, UploadFile } from '../swagger-api-v4';
import { CourseExport, CourseExportOG, Info } from '../../../../node/src/course-export.model';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-course-export-form',
  templateUrl: './course-export-form.component.html',
  styleUrls: ['./course-export-form.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class CourseExportFormComponent implements OnInit {

  @Input() set course(course: CourseResponseDataObject) {
    this.originalCourse = course;
    Object.assign(this.courseModel, course);
  };


  // @Input() set course(course: Course) {
  //   this.originalCourse = course;
  //   Object.assign(this.courseModel, course);
  // };


  //selectedProtocol: string[] = ["scorm2004"];
  selectedProtocol: string = "scorm2004";
  selectedLevels: string = "1";
  // checkboxes - selectedLevels: string[] = ["alllevels"];
  selectedCutsceneType: string = "3d";

  courseModel: CourseResponseDataObject = {};
  originalCourse: CourseResponseDataObject = null;
  cubeLevels = 4;
  displayModal = false;
  downloadFinished = false;
  downloadText = "-";
  showPreview = false;
  includeStartpage = true;

  contentRef: DynamicDialogRef;

  regexContentMD = /!\[.*\]\((.*)\)/gm
  regexReplaceDev = /\/content\/hivo-dev-container\/assets/gm
  regexReplaceProd = /\/content\/hivo-dev-container\/assets/gm

  msgs: Message[] = [];

  constructor(
    private courseService: CourseService,
    private applicationService: ApplicationService,
    private levelService: LevelService,
    private nuggetService: NuggetService,
    private examService: ExamService,
    private contentUtilityService: ContentUtilityService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    console.log('-', this.config);
    this.course = this.config.data.course;
    let course = this.courseModel;
    console.log('1', course);
    // console.log('2', this.courseModel);
    // console.log('3', this.originalCourse);

    if (course.attributes.level1Video || course.attributes.level2Video || course.attributes.level3Video || course.attributes.level4Video || course.attributes.introVideo) {
      this.selectedCutsceneType = "video";
    }
    console.log('course?.info?.cubeLevels', course?.attributes.info?.cubeLevels);
    if (course.attributes.info !== undefined) {
      // this.doceboCourseId = this.config.data.course?.info?.doceboCourseId;
      // this.selectedDemoModes = this.config.data.course?.info?.demoModes;
      // this.selectedAppExt = this.config.data.course?.info?.appExt;
      this.cubeLevels = course?.attributes.info?.cubeLevels === undefined ? '4' : course?.attributes.info?.cubeLevels;
    }
    window.setTimeout(() => {
      // this.nameInput.nativeElement.focus();
    }, 100);
    // }

  }


  onSave() {
    console.log('onSave', this.courseModel);
    const courseRequest: CourseRequest = {
      data: {}
    }
    Object.assign(courseRequest.data, this.courseModel.attributes);
    courseRequest.data.introVideo = this.courseModel.attributes.introVideo.data?.id;
    courseRequest.data.level1Video = this.courseModel.attributes.level1Video.data?.id;
    courseRequest.data.level2Video = this.courseModel.attributes.level2Video.data?.id;
    courseRequest.data.level3Video = this.courseModel.attributes.level3Video.data?.id;
    courseRequest.data.level4Video = this.courseModel.attributes.level4Video.data?.id;
    console.log('courseRequest', courseRequest);
    this.courseService.putcoursesid(courseRequest, this.originalCourse.id).toPromise().then(
      course => {
        console.log('saved course :', course);
        //this.messageService.add({ severity: 'success', summary: 'Saved', detail: app.title });
        this.ref.close(course);
      }
    )

  }

  onCancel() {
    this.ref.close();

  }

  removeContent(contentField: String) {
    console.log('removeContent ', contentField);
    this.courseModel[`${contentField}`] = null;

  }

  showMediaLibDialog(contentField: String) {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      showHeader: false,
      height: '100%',
      //  style: "'min-height': '100%'; 'min-width': '100%'",
      width: '100%',
      data: {
        selector: true,
      },
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('courseModel', `${contentField}`);
      console.log('got this content ', content);
      if (content !== undefined) {
        const contentData: CustomerLogo = {
          data: {
            id: Number(content.id),
            attributes: {}
          }
        }
        Object.assign(contentData.data.attributes, content);
        console.log('contentData', contentData);
        console.log('courseModel before', this.courseModel.attributes[`${contentField}`]);
        this.courseModel.attributes[`${contentField}`] = contentData;
        console.log('courseModel after', this.courseModel.attributes[`${contentField}`]);

        // videoContent = content;
      }
    });
  }

  async onSubmit() {
    console.log('onSubmit-Export', this.originalCourse);

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

    const startpageMDcopy = this.courseModel.attributes.startpageMarkdown;

    let noOfAssets = 0;

    this.displayModal = true;
    this.downloadFinished = false;
    this.downloadText = "Collecting data about the course...";

    let courseOG = { id: '' + this.originalCourse.id, ...this.originalCourse.attributes };
    let courseExportOG: CourseExportOG = {
      info: {}, //Info;
      course: { id: '0' }, //Course;
      application: null, //Application;
      levels: [], //Level[];
      exams: [], //Exam[];
      contentUrls: [] //CourseExportUrl[];
    };
    Object.assign(courseExportOG.course, courseOG);
    console.log('courseExportOG', courseExportOG);
    // let applicationOG : {id: this.originalCourse.attributes.applications.data[0].id, ...this.originalCourse.attributes.applications.data[0].attributes};
    // courseExport.application = applicationOG;
    let courseExport: CourseExport = { course: this.originalCourse };

    this.msgs = [];

    let allContentUrls: CourseExportUrl[] = [];
    const nugIds: string[] = [];

    let appId = 0;
    let courseResponse;
    await this.http.get('/cms-server/courses/' + this.originalCourse.id + '?populate=customer&populate[1]=customer.logo&populate=applications', { headers: headers })
      .toPromise().then((courseResponse1: CourseResponse) => {
        console.log('courseResponse1', courseResponse1);
        courseResponse = courseResponse1;
        if (courseResponse.data.attributes.customer.data.attributes.logo)
          allContentUrls.push({ url: courseResponse.data.attributes.customer.data.attributes.logo?.data.attributes.url, name: courseResponse.data.attributes.customer.data.attributes.logo.data.attributes.name });
        appId = courseResponse.data.attributes.applications.data.filter(app => app.attributes.title.indexOf("@") !== 0 && app.attributes.isDeleted != true).map(app => app.id)[0];
      });

    // if (this.courseModel.attributes.customer.data.attributes.logo) allContentUrls.push({ url: this.courseModel.attributes.customer.data.attributes.logo?.url, name: this.courseModel.attributes.customer.data.attributes.logo.name });

    if (this.selectedCutsceneType === 'video') {
      if (this.courseModel.attributes.introVideo) allContentUrls.push({ url: this.courseModel.attributes.introVideo?.data.attributes.url, name: this.courseModel.attributes.introVideo.data.attributes.name });
      if (this.courseModel.attributes.level1Video) allContentUrls.push({ url: this.courseModel.attributes.level1Video?.data.attributes.url, name: this.courseModel.attributes.level1Video.data.attributes.name });
      if (this.courseModel.attributes.level2Video) allContentUrls.push({ url: this.courseModel.attributes.level2Video?.data.attributes.url, name: this.courseModel.attributes.level2Video.data.attributes.name });
      if (this.courseModel.attributes.level3Video) allContentUrls.push({ url: this.courseModel.attributes.level3Video?.data.attributes.url, name: this.courseModel.attributes.level3Video.data.attributes.name });
      if (this.courseModel.attributes.level4Video) allContentUrls.push({ url: this.courseModel.attributes.level4Video?.data.attributes.url, name: this.courseModel.attributes.level4Video.data.attributes.name });
    }
    // let appId = this.originalCourse.attributes.applications.data.filter(app => app.attributes.title.indexOf("@") !== 0 && app.attributes.isDeleted != true).map(app => app.id)[0];

    try {
      // Read all information about customer, course, application and levels
      await this.http.get('/cms-server/applications/' + appId + '?populate[0]=levels&populate[1]=levels.nuggets&populate[2]=levels.nuggets.content&populate[3]=levels.nuggets.exams&populate[4]=levels.nuggets.exams.answers', { headers: headers })
        .toPromise().then(async (application: ApplicationResponse) => {
          console.log('app all', application);

          courseExport.application = application;
          courseExportOG.application = {
            id: '' + application.data.id,
            title: application.data.attributes.title,
            locale: application.data.attributes.locale,
            description: application.data.attributes.description,
            levels: [],
            course: null,
            isDeleted: false,
          }

          await this.getCubeIcons(courseResponse.data.attributes.iconGroupName).then((iconFiles) => {
            courseExportOG.iconFileNames = iconFiles.map(iconFile => iconFile.name);
            const iconUrls = iconFiles.map(iconFile => { return { url: iconFile.url, name: iconFile.name } });
            allContentUrls.push(...iconUrls);
          });


          courseExportOG.course.customer = { id: '' + courseExport.course.attributes.customer.data.id, ...courseExport.course.attributes.customer.data.attributes };
          courseExportOG.course.customer.logo = { id: '' + courseResponse.data.attributes.customer.data.attributes.logo.data.id, ...courseResponse.data.attributes.customer.data.attributes.logo.data.attributes };
          if (this.courseModel.attributes.introVideo?.data?.id) courseExportOG.course.introVideo = { id: '' + this.courseModel.attributes.introVideo.data.id, ...this.courseModel.attributes.introVideo.data.attributes };
          if (this.courseModel.attributes.level1Video?.data?.id) courseExportOG.course.level1Video = { id: '' + this.courseModel.attributes.level1Video.data.id, ...this.courseModel.attributes.level1Video.data.attributes };
          if (this.courseModel.attributes.level2Video?.data?.id) courseExportOG.course.level2Video = { id: '' + this.courseModel.attributes.level2Video.data.id, ...this.courseModel.attributes.level2Video.data.attributes };
          if (this.courseModel.attributes.level3Video?.data?.id) courseExportOG.course.level3Video = { id: '' + this.courseModel.attributes.level3Video.data.id, ...this.courseModel.attributes.level3Video.data.attributes };
          if (this.courseModel.attributes.level4Video?.data?.id) courseExportOG.course.level4Video = { id: '' + this.courseModel.attributes.level4Video.data.id, ...this.courseModel.attributes.level4Video.data.attributes };

          console.log('courseExportOG app', courseExportOG);

          const levels = application.data.attributes.levels.data.filter(level =>
            this.selectedLevels === 'alllevels' || level.attributes.sortOrder === Number(this.selectedLevels) - 1
          )
          if (levels.length === 0) {
            throw ("Error when getting levels - no matching level sortorder id");
            return;
          }
          console.log('levels: ', levels);
          courseExport.levels = levels;

          // courseExport.levels.forEach(level => {
          //   level.attributes.nuggets.data.forEach(nugget => {
          //     nugget.attributes.content.data.forEach(content => {

          for (let levnr = 0; levnr < courseExport.levels.length; levnr++) {
            const level = courseExport.levels[levnr];
            for (let nugnr = 0; nugnr < level.attributes.nuggets.data.length; nugnr++) {
              const nugget = level.attributes.nuggets.data[nugnr];
              for (let cntnr = 0; cntnr < nugget.attributes.content.data.length; cntnr++) {
                const content = nugget.attributes.content.data[cntnr];

                allContentUrls.push({ name: content.attributes.name, url: content.attributes.url });
                await this.get3DmodelUrls(content).then(all3Durls => {
                  if (all3Durls.length > 0) allContentUrls.push(...all3Durls);
                  // console.log('allContentUrls', allContentUrls);
                  // console.log('allContentUrls length', allContentUrls.length);
                })
              }
              const exams: any = nugget.attributes.exams.data.map(exam => {
                const newAnswers: any = exam.attributes.answers.data.map(answer => { return { id: '' + answer.id, ...answer.attributes } });
                exam.attributes.answers = newAnswers;
                const newNugget: any = { id: nugget.id };
                exam.attributes['nugget'] = newNugget;
                return { id: '' + exam.id, ...exam.attributes };
              })
              exams.forEach(exam => courseExportOG.exams.push(exam));
              // courseExportOG.exams.push(...exams);
              delete nugget.attributes.exams;
            }
          }

          let levelsOG: any = levels.map(level => {
            let nugs: any = level.attributes.nuggets.data.map(nugget => {
              nugget.attributes['content2'] = nugget.attributes.content.data.map(content => { return { id: '' + content.id, ...content.attributes } });
              nugget.attributes['content'] = nugget.attributes['content2'];
              delete nugget.attributes['content2'];
              return { id: '' + nugget.id, ...nugget.attributes };
            });
            console.log('nugs for level ', level.id, nugs);
            let levOG = { id: '' + level.id, ...level.attributes };
            levOG.nuggets = nugs;
            return levOG;
          });
          courseExportOG.levels = levelsOG;

          console.log('courseExportOG levels', courseExportOG);


          const locale = application.data.attributes.locale;
          const levelNr = courseExport.levels.map((level) => level.attributes.sortOrder + 1);
          console.log('locale', locale, 'levelNr', levelNr);
          await this.getAudioUrls(locale, levelNr).then(async data => {
            allContentUrls.push(...data);
            // console.log("allContentUrls", allContentUrls);
            courseExport.contentUrls = allContentUrls;
            courseExportOG.contentUrls = allContentUrls;
          })

          console.log('allContentUrls', allContentUrls);


        })



      //         let levelNr = levels.map(level => { return level.sortOrder + 1; });
      //         console.log('levelNr', levelNr);
      //         await this.getAudioUrls(this.originalCourse.applications[0].locale, levelNr).then(async data => {
      //           allContentUrls.push(...data);
      //           // console.log("allContentUrls", allContentUrls);
      //           courseExport.contentUrls = allContentUrls;

      //           //          const nugIds: string[] = nuggets.map(nug => { return nug.id });
      //           if (nugIds.length > 0) {
      //             console.log('nugIds', nugIds);
      //             await this.nuggetService.nuggetsGet(null, null, null, null, null, nugIds).toPromise().then(
      //               async nugs => {
      //                 console.log('read nugs', nugs);
      //                 //this.testNuggets = nugs;

      //                 const examIds = [].concat(...nugs.map(nug => { return nug.exams.map(exam => { return exam.id }) }));
      //                 console.log('examIds', examIds);

      //                 // this.testExamsNoCorrect = [];
      //                 // this.testExamCorrects = [];
      //                 if (examIds.length > 0) {
      //                   await this.examService.examsGet(null, null, null, 'id', null, examIds).toPromise().then(
      //                     exams => {
      //                       console.log('exams', exams);
      //                       courseExport.exams = exams;

      //                       console.log('courseExport', courseExport);
      //                       console.log('courseExportJSON stringify', JSON.stringify(courseExport, (key, value) => {
      //                         if ((key === 'id' || key === 'customer' || key === 'course') && (typeof value === 'number')) {
      //                           return value.toString();
      //                         }
      //                         return value;
      //                       }));
      //                       console.log('ALL DONE!')

      //                     })
      //                 } else {
      //                   // No exams...set output
      //                   console.log('No exams!')
      //                 }
      //                 console.log('Exams done');
      //               })
      //           } else {
      //             // No nuggets. What to do?
      //             console.log('No nuggets!')
      //           }
      //           console.log('Nuggets done');
      //         })
      //         console.log('Audios done');

      //       })
      //     console.log('Levels done');
      //   })

      if (this.includeStartpage && this.courseModel.attributes.startpageMarkdown?.length > 5) {
        const baseUrl = courseExport.contentUrls[0].url.substring(0, courseExport.contentUrls[0].url.lastIndexOf('/') + 1)
        const regex = new RegExp(this.regexContentMD);
        let results = null;
        while ((results = regex.exec(this.courseModel.attributes.startpageMarkdown)) !== null) {
          console.log(`Found ${results[0]}, indexed at ${regex.lastIndex}.`);

          let filename: string = results[0];
          filename = filename.substring(filename.lastIndexOf('/') + 1, filename.length - 1);
          console.log('filename: ' + filename);
          console.log('url: ' + baseUrl + filename);
          courseExport.contentUrls.push({ name: filename, url: baseUrl + filename });
          courseExportOG.contentUrls.push({ name: filename, url: baseUrl + filename });

        }

        courseExport.course.attributes.startpageMarkdown = this.courseModel.attributes.startpageMarkdown.replace(this.regexReplaceDev, 'assets/cloud');
        courseExport.course.attributes.startpageMarkdown = courseExport.course.attributes.startpageMarkdown.replace(this.regexReplaceProd, 'assets/cloud');
        courseExportOG.course.startpageMarkdown = this.courseModel.attributes.startpageMarkdown.replace(this.regexReplaceDev, 'assets/cloud');
        courseExportOG.course.startpageMarkdown = courseExport.course.attributes.startpageMarkdown.replace(this.regexReplaceProd, 'assets/cloud');

      } else {
        courseExport.course.attributes.startpageMarkdown = "";
        courseExportOG.course.startpageMarkdown = "";
      }

      console.log('Applications done');

      this.downloadText = "Server will download " + allContentUrls.length + " assets and create the SCORM archive. This can take several minutes to complete.";

      console.log('courseExportOG: ', courseExportOG);
      this.onExportPostAndDownload(courseExportOG);

      this.courseModel.attributes.startpageMarkdown = startpageMDcopy;

    } catch (err) {
      this.courseModel.attributes.startpageMarkdown = startpageMDcopy;
      console.log("error:", err);
      this.downloadFinished = true;
      this.downloadText = "Error: " + err.message;
    }

  }

  async getCubeIcons(iconGroupName: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

    console.log('getCubeIcons', iconGroupName);
    const filter = 'IconGroup: ' + iconGroupName;

    return this.http
      .request<Array<FileUpload>>('get', '/cms-server/upload/files?sort=name&filters[caption][$containsi]=' + filter, { headers: headers, }).toPromise()
      .then((result) => {
        console.log('getIcons result', result);
        return result;
      })
  }

  async getAudioUrls(locale: string, levels: number[]) {
    let audioContentUrls: CourseExportUrl[] = [];
    let errors = false;

    if (this.selectedCutsceneType === '3d') {

      if (levels.includes(1)) {
        for (let x = 1; x <= 21; x++) {
          let fileNo = x < 10 ? '00' + x : '0' + x;
          const filename = 'Intro-' + fileNo + '-HiVo-AU-' + locale + '.mp3';
          await this.contentUtilityService.getFileUrl(filename, true, true).then(url => {
            // console.log('Audio for ' + filename + ' found ' + url);
            console.log('Audio for ' + filename + ' url : ', url);
            if (url == null) {
              errors = true;
              this.msgs.push({ severity: 'error', summary: 'Error', detail: "Audio file not found : " + filename });
            }
            audioContentUrls.push({ name: filename, url: url });
          })
        }

        for (let x = 1; x <= 4; x++) {
          let fileNo = x < 10 ? '00' + x : '0' + x;
          const filename = 'Level1-' + fileNo + '-HiVo-AU-' + locale + '.mp3';
          await this.contentUtilityService.getFileUrl(filename, true, true).then(url => {
            console.log('Audio for ' + filename + ' url : ', url);
            if (url == null) {
              errors = true;
              this.msgs.push({ severity: 'error', summary: 'Error', detail: "Audio file not found : " + filename });
            }
            audioContentUrls.push({ name: filename, url: url });
          })
        }
      }
      if (levels.includes(2)) {
        for (let x = 1; x <= 3; x++) {
          let fileNo = x < 10 ? '00' + x : '0' + x;
          const filename = 'Level2-' + fileNo + '-HiVo-AU-' + locale + '.mp3';
          await this.contentUtilityService.getFileUrl(filename, true, true).then(url => {
            console.log('Audio for ' + filename + ' url : ', url);
            if (url == null) {
              errors = true;
              this.msgs.push({ severity: 'error', summary: 'Error', detail: "Audio file not found : " + filename });
            }
            audioContentUrls.push({ name: filename, url: url });
          })
        }
      }

      if (levels.includes(3)) {
        for (let x = 1; x <= 3; x++) {
          let fileNo = x < 10 ? '00' + x : '0' + x;
          const filename = 'Level3-' + fileNo + '-HiVo-AU-' + locale + '.mp3';
          await this.contentUtilityService.getFileUrl(filename, true, true).then(url => {
            console.log('Audio for ' + filename + ' url : ', url);
            if (url == null) {
              errors = true;
              this.msgs.push({ severity: 'error', summary: 'Error', detail: "Audio file not found : " + filename });
            }
            audioContentUrls.push({ name: filename, url: url });
          })
        }
      }

      if (levels.includes(4)) {
        for (let x = 1; x <= 6; x++) {
          let fileNo = x < 10 ? '00' + x : '0' + x;
          const filename = 'Level4-' + fileNo + '-HiVo-AU-' + locale + '.mp3';
          await this.contentUtilityService.getFileUrl(filename, true, true).then(url => {
            console.log('Audio for ' + filename + ' url : ', url);
            if (url == null) {
              errors = true;
              this.msgs.push({ severity: 'error', summary: 'Error', detail: "Audio file not found : " + filename });
            }
            audioContentUrls.push({ name: filename, url: url });
          })
        }
      }
    }

    console.log('audioContentUrls', audioContentUrls);
    console.log('errors', errors);
    if (errors) throw Error("Some audio files are missing!");

    return audioContentUrls;
  }

  // TODO : get en-us models if language code is EN-US
  async get3DmodelUrls(content: ApplicationLevelsAttributesNuggetsAttributesContentData) {
    let courseExportUrls: CourseExportUrl[] = [];
    let names: string[] = [];

    if (content.attributes.caption.includes('CA1a_01_U') || content.attributes.caption.includes('CA1a-002-3D')) {
      names.push('electric_standard_animated.glb');
    }
    if (content.attributes.caption.includes('CA1a-003-3D')) {
      names.push('hybrid_animated.glb');
    }
    if (content.attributes.caption.includes('CA1a-004-3D')) {
      names.push('fuelcell_animated.glb');
    }
    if (content.attributes.caption.includes('CA1b_01_L') || content.attributes.caption.includes('CA1b-004-3D')) {
      names.push('electric_standard_detachable.glb');
      names.push('hybrid_detachable.glb');
      names.push('fuelcell_detachable.glb');
    }
    if (content.attributes.caption.includes('CA1b_01_U21') || content.attributes.caption.includes('CA1b-007-3D')) {
      names.push('electric_standard_detachable.glb');
      names.push('ev1_detachable.glb');
      names.push('ev2_detachable.glb');
    }
    if (content.attributes.caption.includes('CA1b_01_U22') || content.attributes.caption.includes('CA1b-008-3D')) {
      names.push('hybrid_detachable.glb');
      names.push('hbv1_detachable.glb');
    }
    if (content.attributes.caption.includes('CA1b_01_U23') || content.attributes.caption.includes('CA1b-009-3D')) {
      names.push('fuelcell_detachable.glb');
      names.push('fcv1_detachable.glb');
    }
    if (content.attributes.caption.includes('CA2a05') || content.attributes.caption.includes('CA2a-002-3D')) {
      names.push('electric_standard_danger_points.glb');
      names.push('fuelcell_danger_points.glb');
    }

    // names.forEach(name => {
    for (let x = 0; x < names.length; x++) {
      await this.contentUtilityService.getFileUrl(names[x], true, true).then(url => {
        courseExportUrls.push({ name: names[x], url: url });
      })
      //courseExportUrls.push({ name: name, url: this.contentUtilityService.getFileUrl(name, true, true) });
    }
    // });

    if (names.length > 0) console.log('3D name - url', courseExportUrls);

    return courseExportUrls;
  }

  onExportPostAndDownload(courseExport: CourseExportOG) {
    const info: Info = {
      protocol: this.selectedProtocol,
      d3: this.selectedCutsceneType === '3d' ? true : false,
      video: this.selectedCutsceneType === 'video' ? true : false,
      level: this.selectedLevels,
      startpage: this.includeStartpage
    };
    courseExport.info = info;

    let body = courseExport;
    console.log('body', body);
    let url = '/scorm';
    console.log('url', url);
    // Timeout 10 minutes
    this.http.post<any>(url, body, { headers: new HttpHeaders({ timeout: "1200000" }), observe: 'response', responseType: 'blob' as 'json', reportProgress: true })
      .subscribe(
        (response) => {
          console.log('response.type', response.type);
          console.log('response', response);
          // if (response.type === HttpEventType.UploadProgress) {
          //   const percentDone = Math.round(100 * response.loaded / response.total);
          //   console.log('Progress ' + percentDone + '%');
          // }

          if (response.type === HttpEventType.Response) {
            this.downloadFinished = true;
            console.log('Upload complete', response);
            this.msgs = [];
            this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Course export created and downloading.' });

            let filename: string = "scorm-course-export.zip";
            if (courseExport.levels.length === 1) {
              filename = 'SCORM-' + courseExport.course['name'] + '-' + courseExport.application.title + '-' + courseExport.levels[0].title + '.zip';
            } else {
              filename = 'SCORM-' + courseExport.course['name'] + '-' + courseExport.application.title + '.zip';
            }
            console.log('filename: ' + filename);
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
        },
        err => {
          this.downloadFinished = true;
          console.log('err', err);
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: err.message });
        }
      )
  }

  closeDownloadDialog(event) {
    this.displayModal = false;
  }


}
