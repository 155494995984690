<div class="p-grid">
  <div class="p-col"></div>
  <div class="p-col signin-card">
    <p-card header="DEKRA Education CMS" [style]="{'width': '25rem', 'margin-bottom': '2em'}">
      <div class=" p-field p-grid">
        <label for="cmsusername" class="p-col-fixed" style="width:100px">Username</label>
        <div class="p-col">
          <input #usernameInputBox id="cmsusername" autocomplete="username" type="text" [(ngModel)]="username"
            pInputText (change)="error=false">
        </div>
      </div>

      <!-- <p-message *ngIf="errorSuper" severity="error" text="Error connecting to CMS server."></p-message> -->
      <!-- <ng-template pTemplate="footer"> -->
      <br><br>
      <p-button label="Send password reset e-mail" icon="fas fa-envelope" [disabled]="signInDisabled" (click)="signIn()"></p-button>
      <br><br>
      <!-- <a href="forgot-password">Forgot password?</a> -->
      <span><a routerLink="/welcome-cms">Sign in page</a></span>

      <!-- </ng-template> -->
    </p-card>
  </div>
  <div class="p-col"></div>
</div>

