import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef, } from 'primeng/dynamicdialog';
import { FeedInfo } from 'resources/swagger-api-v4';
import { environment } from 'src/environments/environment';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { Answer, ApplicationLevelsAttributesNuggetsData, ApplicationListResponse, ApplicationResponse, FeedRequest, FeedResponseDataObject, FeedService, Nugget, NuggetResponseDataObject, UploadFile } from '../swagger-api-v4';
import { Utilities } from '../utilities';

@Component({
  selector: 'app-feeds-form',
  templateUrl: './feeds-form.component.html',
  styleUrls: ['./feeds-form.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class FeedsFormComponent implements OnInit, AfterViewInit {
  @ViewChild('titleInput') titleInput: ElementRef;

  // set feed(feed: FeedResponseDataObject) {
  //   console.log('set feed', feed);
  //   this.originalFeed = feed;
  //   this.feedModel = Object.assign({}, feed);
  //   console.log('set feedmodel', this.feedModel);
  // }

  contentRef: DynamicDialogRef;

  Util = Utilities;

  display = false;

  checkbox: boolean[] = [true, false, true, false, true, false, true];

  // feedInfoNew: FeedInfo[] ;

  newFeed: FeedResponseDataObject = {
    id: 0,
    attributes: {
      title: 'New Feed',
      details: 'the question',
      examtype: 'question',
      sortOrder: 999,
      // locale: 'EN',
      info: [
        { statement: '', response: '', sortOrder: 1, correct: true },
        { statement: '', response: '', sortOrder: 1, correct: false },
        { statement: '', response: '', sortOrder: 1, correct: false },
      ],
    }
  };

  feedModel: FeedResponseDataObject = { id: 0, attributes: {} };

  feedModelPreview: FeedResponseDataObject = { id: 0, attributes: {} };

  displayPreview = false;

  originalFeed: FeedResponseDataObject = null;
  nuggets: ApplicationLevelsAttributesNuggetsData[] = [];
  // nuggets: NuggetResponseDataObject[] = [];
  selectedNuggets: number[] = [];
  selectedInfoNugget: NuggetResponseDataObject;
  quizTypes: SelectItem[] = [
    { label: 'Question', value: 'question' },
    { label: 'Order', value: 'order' },
    { label: 'Image Map', value: 'imagemap' },
  ];
  selectedQuizType = "order"; //SelectItem = { "label": "Order", "value": "order" }; // this.quizTypes[0];

  nuggetList: SelectItem[] = [];

  // examModel: NewExam = {
  //   answers: [fee
  //     { id: '-7', answer: 'A' },
  //     { id: '-8', aqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqnswer: 'B' },
  //     { id: '-9', answer: 'C' },
  //   ],
  // };
  // originalExam: Exam = null;
  // newId = -10;
  deletedAnswers: Answer[] = [];

  applicationId = 0;

  constructor(
    private feedService: FeedService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private http: HttpClient,
  ) {
    this.applicationId = Number(localStorage.getItem('applicationId'));
    this.getNuggets();


    // this.ksStore.dispatch(GetKnowledge());

    // this.ksStore.select('knowledge').subscribe(knowledges => {
    //   if (knowledges !== undefined) {
    //     this.nuggets = [];
    //     knowledges.forEach(know => {
    //      // TODO: ADD THIS this.nuggets.push(know.nugget);
    //     });

    //     this.selectedNuggets = this.nuggets.filter((nugget) =>
    //       this.originalFeed?.attributes.nuggets?.data.find((nug) => nug.id === Number(nugget.id))
    //     );

    //   }
    // });

    // this.store.dispatch(GetCMS());
    // this.cmsStore.select('cms').subscribe((cms) => {
    //   // console.log('cms subscription in feeds', cms === undefined, cms.customers.length, cms);
    //   this.cms = cms;
    //   // TODO: get current customer and course to work with feeds
    //   if (this.cms === undefined || this.cms?.customers?.length === 0) {
    //     console.log('reloading cms data to store');
    //     this.cmsStore.dispatch(GetCMS());
    //   } else {
    //     this.nuggets = [];
    //     this.cms.customers[0].courses[0].applications.forEach((app) => {
    //       app.levels.forEach((lev) => {
    //         lev.nuggets.forEach((nug) => {
    //           this.nuggets.push(nug.nugget);
    //         });
    //       });
    //     });
    //     console.log('sorting nuggets');
    //     this.nuggets.sort((a, b) => {
    //       return a.title.localeCompare(b.title);
    //     });
    //     console.log('allnugs', this.nuggets);
    //   }
    // });
  }

  getNuggets() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/applications/' + this.applicationId + '?[populate][levels][populate][nuggets][fields][0]=title&[populate][levels][populate][nuggets][filter][isDelete][$neq]=true', { headers: headers })
      .toPromise().then((application: ApplicationResponse) => {
        console.log('application', application);
        this.nuggets = []
        let res = application.data.attributes.levels.data
          .filter(level => level.attributes.isDeleted !== true)
          .map(level => level.attributes.nuggets.data).concat()
          .filter(nugget => nugget.filter(nugget => nugget.attributes.isDeleted !== true))
          .forEach(nuggets => nuggets.forEach(nugget => {
            this.nuggets.push(nugget);
            this.nuggetList.push({ value: nugget.id, label: nugget.attributes.title })
          }))
        this.selectedNuggets = this.originalFeed.attributes.nuggets.data.map((nugget) => nugget.id);
        console.log('this.nuggets', this.nuggets);

      })
  }

  ngOnInit(): void {
    console.log('oninit', this.config.data.feed);
  }

  ngAfterViewInit() {
    // if (this.config.data.feed.id > '0') {
    this.originalFeed = this.config.data.feed;
    Object.assign(this.feedModel, this.config.data.feed);
    Object.assign(this.feedModel.attributes.info, this.config.data.feed.info);
    // this.feed = this.config.data.feed;
    //      this.feed = Object.assign({}, this.config.data.feed);
    console.log('You opened an existing feed');
    // Object.assign(this.examModel, this.originalFeed.exam);
    // Object.assign(this.examModel.answers, this.originalFeed.exam.answers);
    // this.selectedNuggets = [...this.originalFeed.nuggets];
    //  this.originalFeed.attributes.nuggets.data.forEach((nugget) => {
    //    this.selectedNuggets.push(nugget);
    //  });
    this.selectedQuizType = this.quizTypes.find(
      (type) => type.value === this.originalFeed.attributes.examtype
    ).value;
    console.log('this.selectedQuizType',this.selectedQuizType);



    console.log('this.selectedNuggets=', this.selectedNuggets);
    console.log('this.originalFeed.nuggets=', this.originalFeed.attributes.nuggets);



    // Moved to inside subscription
    // this.selectedNuggets = this.nuggets.filter((nugget) =>
    //   this.originalFeed?.nuggets?.find((nug) => nug.id === nugget.id)
    // );


    // }
    //  else {
    //   console.log('this.newFeed',this.newFeed);
    //   this.feedModel = this.newFeed;
    //   this.feedModel.application = this.newFeed.application;
    //   console.log('this.feedModel',this.feedModel)
    //   // Object.assign(this.feedModel, this.newFeed);
    //   // Object.assign(this.feedModel.info, this.newFeed.info);
    // }

    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();
    }, 100);
  }

  onCancel() {
    console.log('onCancel', this.ref);
    this.ref.close();
  }

  upload() { }

  showMediaLibDialog() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      showHeader: false,
      height: '100%',
      //  style: "'min-height': '100%'; 'min-width': '100%'",
      width: '100%',
      data: {
        selector: true,
      },
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('onclose media', content);
      if (content !== undefined) {
        console.log('content selected', content);
        this.feedModel.attributes.image.data = { attributes: content, id: content.id };
      }
    });
  }

  showMediaLibDialogFurtherInfo() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      showHeader: false,
      height: '100%',
      //  style: "'min-height': '100%'; 'min-width': '100%'",
      width: '100%',
      data: {
        selector: true,
      },
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('onclose', content);
      console.log('this.feedModel.attributes.furtherInfo.data', this.feedModel.attributes.furtherInfo.data);
      if (content !== undefined) {
        console.log('content selected', content);
        this.feedModel.attributes.furtherInfo.data = { id: content.id, attributes: content };
      }
    });
  }

  showMediaLibDialogThumbnail() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      showHeader: false,
      height: '100%',
      //  style: "'min-height': '100%'; 'min-width': '100%'",
      width: '100%',
      data: {
        selector: true,
      },
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('onclose', content);
      if (content !== undefined) {
        console.log('content selected', content);
        this.feedModel.attributes.thumbnail.data = { id: content.id, attributes: content };
      }
    });
  }

  ngOnDestroy() {
    this.contentRef?.close();
  }

  sendFeedUpdate() {
    const payload = JSON.stringify({
      notification: {
        title: 'Allianz Partners | Dekra',
        body: 'You are now subscribing to new quizes!',
        icon:
          'http://dekracms-dev.northeurope.cloudapp.azure.com:1337/assets/notification-icon.png',
        // icon: 'http://localhost/assets/notification-icon.png',
        vibrate: [100, 50, 100],
        data: {
          url: 'http://dekracms-dev.northeurope.cloudapp.azure.com/',
        },
      },
    });
    console.log('webPush.sendNotification', payload);
    // console.log('webPush.sendNotification', subscription.notification);
    // webPush.sendNotification(subscription.notification, payload)
    //   .catch(error => console.error(error));
  }

  newAnswer(event) {
    const newInfo: FeedInfo = {
      statement: '',
      response: '',
      sortOrder: this.feedModel.attributes.info.length + 1,
      correct: true,
    };
    this.feedModel.attributes.info.push(newInfo);
    this.feedModel.attributes.info[1].correct = !this.feedModel.attributes.info[1].correct;
    // let newAnswer: Answer = {
    //   id: (this.newId--).toString(),
    //   correct: false,
    //   answer: '',
    // };
    // this.examModel.answers.push(newAnswer);
  }

  // deleteAnswer(event: Answer) {
  //   const idx = this.examModel.answers.findIndex((answer) => {
  //     return answer.id === event.id;
  //   });
  //   if (idx >= 0) {
  //     if (event.id > '0') {
  //       this.deletedAnswers.push(event);
  //     }
  //     this.examModel.answers.splice(idx, 1);
  //   }
  // }

  deleteInfo(info: FeedInfo) {
    const feedInfo: FeedInfo[] = this.feedModel.attributes.info;
    feedInfo.splice(feedInfo.indexOf(info), 1);
  }

  onSubmit() {
    // this.deletedAnswers.forEach((answer) => {
    //   this.answerService
    //     .answersIdDelete(answer.id)
    //     .toPromise()
    //     .then(
    //       (res) => {},
    //       (err) => {
    //         console.log(err);
    //       }
    //     );
    // });

    // let saves = 0;
    // let allSaves = this.examModel.answers.length;
    // this.examModel.answers.forEach((answer) => {
    //   if (answer.id > '0') {
    //     this.answerService
    //       .answersIdPut(answer, answer.id)
    //       .toPromise()
    //       .then(
    //         (answ) => {
    //           if (++saves === allSaves) {
    //             this.saveExam();
    //           }
    //         },
    //         (err) => {
    //           console.log(err);
    //         }
    //       );
    //   } else {
    //     this.answerService
    //       .answersPost(answer)
    //       .toPromise()
    //       .then(
    //         (answ) => {
    //           answer.id = answ.id;
    //           if (++saves === allSaves) {
    //             this.saveExam();
    //           }
    //         },
    //         (err) => {
    //           console.log(err);
    //         }
    //       );
    //   }
    // });
    // // }

    // // saveExam() {
    //   // if (this.originalExam?.id) {
    //   if (this.originalFeed?.exam?.id) {
    //     this.examService
    //       .examsIdPut(this.examModel, this.originalFeed.exam.id)
    //       .toPromise()
    //       .then((exam) => {
    //         // this.messageService.add({
    //         //   severity: 'success',
    //         //   summary: 'Saved',
    //         //   detail: exam.question,
    //         // });
    //         this.saveFeed(exam);
    //         // this.ref.close(exam);
    //       });
    //   } else {
    //     this.examService
    //       .examsPost(this.examModel)
    //       .toPromise()
    //       .then((newexam) => {
    //         // this.messageService.add({
    //         //   severity: 'success',
    //         //   summary: 'Saved',
    //         //   detail: newexam.question,
    //         // });
    //         this.saveFeed(newexam);
    //         // this.ref.close(newexam);
    //       });
    //   }
    // }

    // saveFeed(exam: Exam) {
    console.log('feedModel', this.feedModel);
    // let newFeed: NewFeed = { exam: { id: '0' } };
    // Object.assign(newFeed, this.feedModel);
    // // Object.assign(newFeed.exam, exam);
    // newFeed.application = this.feedModel.application;
    // newFeed.examtype = this.selectedQuizType?.value;
    // newFeed.nuggets = this.selectedNuggets;
    // console.log('saving feed', newFeed);
    // let sortOrder = 1;
    // newFeed.info.forEach(info => {
    //   info.sortOrder = sortOrder++;
    // });

    let feedRequest: FeedRequest = {
      data: {}
      // title?: string;
      // details?: string;
      // correct?: string;
      // incorrect?: string;
      // sortOrder?: number;
      // image?: number | string;
      // thumbnail?: number | string;
      // examtype?: string;
      // info?: any;
      // nuggets?: Array<number | string>;
      // application?: number | string;
      // furtherInfo?: number | string;
      // furtherInfoText?: string;

    }

    Object.assign(feedRequest.data, this.feedModel.attributes);
    feedRequest.data.application = this.feedModel.attributes?.application?.data.id;
    feedRequest.data.examtype = this.selectedQuizType;
    feedRequest.data.nuggets = this.selectedNuggets;
    feedRequest.data.furtherInfo = this.feedModel.attributes?.furtherInfo?.data?.id;
    feedRequest.data.application = this.feedModel.attributes?.application?.data?.id;
    feedRequest.data.image = this.feedModel.attributes?.image?.data?.id;
    feedRequest.data.thumbnail = this.feedModel.attributes?.thumbnail?.data?.id;
    console.log('saving feed', feedRequest);
    let sortOrder = 1;
    feedRequest.data?.info.forEach(info => {
      info.sortOrder = sortOrder++;
    });

    if (this.originalFeed?.id > 0) {
      this.feedService.putfeedsid(feedRequest, this.originalFeed.id)
        .toPromise()
        .then((feed) => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: feed.title });
          this.ref.close(feed);
        });
    } else {
      console.log('SAVED NEW this feed', feedRequest);
      this.feedService.postfeeds(feedRequest)
        .toPromise().then(anewfeed => {
          console.log('SAVED RETURN this feed', anewfeed);
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newfeed.title });
          this.ref.close(anewfeed);
        });
    }
  }

  togglePreview() {
    console.log('this.feedModel',this.feedModel);
    // this.feedModelPreview = Object.assign({}, this.feedModel);
    //this.feedModelPreview.attributes = Object.assign({}, this.feedModel.attributes);

    this.feedModelPreview = { id: this.feedModel.id, attributes: {}};
    this.feedModelPreview.attributes =  Object.assign({}, this.feedModel.attributes); // {... this.feedModel.attributes};

        //this.feedModelPreview = Object.assign({}, this.feedModel);
    this.feedModelPreview.attributes.info = [];
      this.feedModel.attributes.info.forEach((info) => {
        const infoCopy = Object.assign({}, info);
        this.feedModelPreview.attributes.info.push(infoCopy);
    });
    console.log('this.feedModelPreview',this.feedModelPreview);
    // console.log('this.feedModel',this.feedModel);

    // this.displayPreview = !this.displayPreview;

    window.setTimeout(() => {
       this.displayPreview = !this.displayPreview;
    }, 200);

  }
}
