import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Utilities } from '../utilities';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NuggetFormComponent } from '../nugget-form/nugget-form.component';
// import { Level, NewNugget, Nugget, LevelService, NewLevel } from '../swagger-api';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbService } from '../breadcrumb.service';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { LevelListResponse, LevelListResponseDataItem, LevelNuggets, LevelNuggetsData, LevelRequest, NuggetResponseDataObject, NuggetService } from '../swagger-api-v4';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nuggets',
  templateUrl: './nuggets.component.html',
  styleUrls: ['./nuggets.component.scss'],
  providers: [DialogService, ConfirmationService]

})
export class NuggetsComponent implements OnInit, AfterViewInit {

  Util = Utilities;

  ref: DynamicDialogRef;
  nuggetModel: NuggetResponseDataObject = {};
  nuggets: LevelNuggetsData[] = [];
  selectedNugget: LevelNuggetsData;
  level: LevelListResponseDataItem;
  resetSortOrder = false;
  cols = [
    // { field: 'id', header: 'Id' },
    { field: 'title', header: 'Title' },
    { field: 'description', header: 'Description' },
    { field: 'learningTime', header: 'Time Learning (min)', width: '55px' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' }
  ];

  levelId: number = 0;

  getWidth(col: any) {
    console.log('col', col);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private confirmationService: ConfirmationService,
    // private levelService: LevelService,
    private messageService: MessageService,
    private nuggetService: NuggetService,
    public dialogService: DialogService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.levelId = Number(localStorage.getItem('levelId'));
    this.refreshData();

  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/levels?filters[id]=' + this.levelId + '&populate[nuggets][populate][exams][populate]=*&populate[nuggets][populate]=content&sort=sortOrder', {
      headers: headers
    }).toPromise().then((level: LevelListResponse) => {
      console.log('level', level);
      this.level = level.data[0];
      if (level.data[0].attributes?.nuggets?.data.length > 0)
        this.nuggets = level.data[0].attributes.nuggets.data;
        this.nuggets.sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder);
      console.log('Nuggets', this.nuggets);
    },
      err => { console.log(err); }
    );
  }

  ngAfterViewInit(): void {
    const items: MenuItem[] = [
      { label: localStorage.getItem('customerName'), routerLink: '/customers' },
      { label: localStorage.getItem('courseName'), routerLink: '/courses' },
      { label: localStorage.getItem('applicationName'), routerLink: '/applications' },
      { label: localStorage.getItem('levelName'), routerLink: '/levels' },
      { label: 'Nuggets & Exams' },
    ];

    this.breadcrumbService.setItems(items);

  }


  newNugget(event) {
    let sortOrder = 0;
    if (this.nuggets.length > 0) {
      sortOrder = this.nuggets?.reduce((prev, current) => {
        return (prev.attributes.sortOrder > current.attributes.sortOrder) ? prev : current
      }).attributes.sortOrder + 1;
    }
    const newNugget: NuggetResponseDataObject = {
      attributes: {
        title: '',
        description: '',
        iconNr: 1,
        learningTime: 1,
        sortOrder: sortOrder,
        exams: { data: [] },
        content: { data: [] },
        feeds: { data: [] }
      }
    }

    this.showEditDialog(newNugget);
  }

  onRowSelect(event) {
    this.selectedNugget = event.data;
    //overlaypanel.toggle(event);
  }

  onDelete(nugget) {
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, nugget.title),
      accept: () => {
        this.nuggetService.deletenuggetsid(nugget.id).toPromise().then(
          ret => {
            let index = this.nuggets.findIndex(nug => nug.id === nugget.id);
            if (index != -1) {
              this.nuggets.splice(index, 1);
            }
          },
          err => {
            console.log('error', err);
          }
        )
      }
    });


  }

  showEditDialog(nugget: NuggetResponseDataObject) {

    this.ref = this.dialogService.open(NuggetFormComponent, {
      header: nugget.id > 0 ? 'Edit Nugget' : 'New Nugget',
      width: '900px',
      data: {
        nugget: nugget,
        level: this.level
      }
    });

    this.ref.onClose.subscribe((nugget) => {
      if (nugget) {
        this.refreshData();
      }
    });
  }


  ngOnDestroy() {
    this.ref?.close();
  }

  onRowReorder(event) {
    this.nuggets.forEach((nug, idx) => {
      nug.attributes.sortOrder = idx;
      // let newNugget: NewNugget = {};
      // Object.assign(newNugget, nug);
      let nuggetRequest: LevelRequest = { data: { sortOrder: idx} };
      //Object.assign(nuggetRequest, nug.attributes);

      this.nuggetService.putnuggetsid(nuggetRequest, nug.id).toPromise().then(
        nug => {
          console.log('reorder',nug);
        },
        err => {
          this.messageService.add({ severity: 'error', summary: 'Error while saving new sorting', detail: err });
        }
      )
    });

  }
}
