import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CourseService, CourseResponseDataObject, CourseRequest, UploadFile } from '../swagger-api-v4';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utilities } from '../utilities';
import { environment } from 'src/environments/environment';
import { SelectItem } from 'primeng/api';

export interface courseInfo {
  doceboCourseId: number;
  demoModes: string[];
}
@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.scss']
})
export class CourseFormComponent implements OnInit, AfterViewInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  @Input() set course(course: CourseResponseDataObject) {
    this.originalCourse = course;
    this.courseModel.id = course.id;
    Object.assign(this.courseModel.attributes, course.attributes);
  };

  courseModel: CourseResponseDataObject = { id: 0, attributes: {} };
  originalCourse: CourseResponseDataObject = null;

  doceboCourseId: number = 0;
  url = '';

  selectedDemoModes: string[] = [];
  selectedAppExt: string[] = [];
  cubeLevels: string = '4';

  iconGroupNames = [];
  selectedIconGroupName;
  tagFilesMap: Map<string, UploadFile[]>;
  iconFiles: UploadFile[] = [];
  iconNrs: SelectItem[] = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];


  Util = Utilities;

  constructor(
    private courseService: CourseService,
    private http: HttpClient,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.course = this.config.data.course;
    if (this.config.data.course.attributes.info !== undefined) {
      this.doceboCourseId = this.config.data.course.attributes.info?.doceboCourseId;
      this.selectedDemoModes = this.config.data.course.attributes.info?.demoModes;
      this.selectedAppExt = this.config.data.course.attributes.info?.appExt;
      this.cubeLevels = this.config.data.course.attributes.info?.cubeLevels === undefined ? '4' : this.config.data.course.attributes.info?.cubeLevels;
    }
     this.getIconGroups();
    window.setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 100);
    // }
  }

  ngAfterViewInit(): void {
    // this.getIconGroups();
  }

  onSubmit() {

    if (this.courseModel.attributes.info) {
      this.courseModel.attributes.info.doceboCourseId = this.doceboCourseId;
      this.courseModel.attributes.info.demoModes = this.selectedDemoModes;
      this.courseModel.attributes.info.appExt = this.selectedAppExt;
      this.courseModel.attributes.info.cubeLevels = this.cubeLevels;
    } else {
      this.courseModel.attributes.info = { doceboCourseId: this.doceboCourseId };
    }
    let courseRequest: CourseRequest = { data: {} };
    Object.assign(courseRequest.data, this.courseModel.attributes);
    courseRequest.data.customer = this.config.data.customerId;
    courseRequest.data.iconGroupName = this.selectedIconGroupName;

    if (this.originalCourse?.id) {
      this.courseService.putcoursesid(courseRequest, this.originalCourse.id).toPromise().then(
        course => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: app.title });
          this.ref.close(course);
        }
      )
    } else {
      this.courseModel.attributes.info = { doceboCourseId: this.doceboCourseId };
      this.courseService.postcourses(courseRequest).toPromise().then(
        newcourse => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newapp.title });
          this.ref.close(newcourse);
        }
      )
    }
  }

  onCancel() {
    this.ref.close();
  }

  getIconGroups() {
    this.selectedIconGroupName = this.courseModel.attributes.iconGroupName;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/upload/files?filters[caption][$contains]=IconGroup:&fields[0]=caption&fields[1]=url&fields[2]=name&sort=name', { headers: headers }).toPromise().then((files: UploadFile[]) => {
      // console.log('icongroup files :', files);
      const tags = Utilities.getAllTags(files);
      const uniqueTags = new Set<string>();
      uniqueTags.add('HiVo');
      tags.forEach(tag => { if (tag.key === 'IconGroup') uniqueTags.add(tag.values[0]); });
      console.log('uniqueTags :', uniqueTags);
      this.iconGroupNames = Array.from(uniqueTags);

      const tagFilesMap = new Map<string, UploadFile[]>();
      this.iconGroupNames.forEach(iconGroupName => tagFilesMap.set(iconGroupName, []));
      files.forEach(file => {
        const tag = Utilities.getTagValuesOfKey(Utilities.getTags(file), 'IconGroup')[0];
        tagFilesMap.get(tag).push(file);
      })

      console.log('tagFilesMap:', tagFilesMap);
      this.tagFilesMap = tagFilesMap;

      console.log('courseModel', this.courseModel);
      console.log('this.courseModel.attributes.iconGroupName', this.courseModel.attributes.iconGroupName);
      // this.selectedIconGroupName = this.courseModel.attributes.iconGroupName;
      // this.selectedIconGroupName = this.courseModel.attributes.iconGroupName === null ? 'HiVo' : this.courseModel.attributes.iconGroupName;
      console.log('this.selectedIconGroupName', this.selectedIconGroupName);

      this.changedIconGroup({originalEvent: null, value: this.selectedIconGroupName })
    }, (err => {
      console.error('Error while getting icongroups.\n' + err.message);
    }))

  }

  changedIconGroup(event) {
    console.log('changedIconGroup', event);
    console.log('changedIconGroup', event.value);
    console.log('changedIconGroup', this.tagFilesMap.get(event.value));
    this.iconFiles = this.tagFilesMap.get(event.value).filter(file => {
      return ((file.name.endsWith('-A.png') || file.name.endsWith('-A.jpg')));
      return ((file.name.endsWith('-A.png') || file.name.endsWith('-A.jpg')) && !file.name.includes('quiz'));
    });
    this.iconFiles.forEach((file, idx) => file['iconNr'] = idx + 1);
    // console.log('iconFiles', this.iconFiles);
  }



}
