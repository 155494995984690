import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '../breadcrumb.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  providers: [ConfirmationService],
})
export class BreadcrumbsComponent implements OnDestroy {
  sub: Subscription;

  items: MenuItem[];

  user;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    public breadcrumbService: BreadcrumbService) {

    const jwt = localStorage.getItem('jwt');
    console.log('jwt', jwt);
    console.log('decoded', jwt_decode(jwt));
    console.log('env.user',environment.user);
    this.user = environment.user;

    this.sub = this.breadcrumbService.itemsHandler.subscribe(
      (response) => {
        setTimeout(() => {
          this.items = [...response];
        });
      }
    );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onLogout() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to log out?',
      accept: () => {
        localStorage.removeItem('jwt');
        environment.cmsAccessToken = null;
        this.router.navigate(['welcome']);
        return false;
      },
      reject: () => {
        return false;
      }
    });

  }

}
