<app-breadcrumbs></app-breadcrumbs>
<hr />

<div style="text-align: center">
  <div *ngIf="loading" class="footer">
    Loading
    <br />
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  <br />
  <br />
  <!-- <h1> DEKRA HiVo Control Center</h1> -->

  <h1> DEKRA - Control Center</h1>
  <h2>Welcome {{ user.username }}!</h2>
  <h3><a routerLink="/customers">Enter</a> </h3>

  <div style="text-align: center; display: none">

    <br />
    <br />
    <br />
    <br />
    <div>
      <!-- <p-button
      label="Test send notification"
      (onClick)="sendNotifications($event)"
    ></p-button> -->
    </div>
  </div>
</div>
