<hr>
<div class="p-grid ">
  <div class="box p-col-12" style="text-align: center;">
    <span class="p-jc-center p-offset-1 p-col-1"><a i18n="Footer imprint@@#FOOTER_IMPRINT"
        routerLink="/imprint">Imprint</a></span>
    <span class="p-col-1 p-offset-1"><a i18n="Footer policy@@#FOOTER_POLICY" routerLink="/policy">User/Privacy
        Policy</a></span>
    <span class=" p-col-3 p-offset-1  "><a i18n="Footer privacy@@#FOOTER_PRIVACY" routerLink="/privacy">Data Privacy
        Settings
      </a></span>
    <!-- </div>
  <div class="p-col-12 p-jc-center "> -->
    <div class="p-offset-1 p-col-10">
      <span style="font-weight: bold;" i18n="Footer support required@@#FOOTER_SUPPORT_REQUIRED">Support
        Required?&nbsp;</span>
      <span i18n="Footer support info@@#FOOTER_SUPPORT_INFO">Should you have any technical issues or questions regarding
        the training, please contact the global helpdesk:</span>&nbsp;
      <span><a #supportPhone id="supportPhone" i18n="Footer support phone no@@#FOOTER_SUPPORT_PHONE"
          href="tel:+XX-123 456 789">-</a>
      </span>&nbsp;
      <span i18n="Footer support email@@#FOOTER_SUPPORT_EMAIL">Email:</span>&nbsp;
      <span><a #supportEmail id="supportEmail" i18n="Footer support email adress@@#FOOTER_SUPPORT_EMAIL_ADRESS"
          href="mailto:dekra.support.training@dekra.com">support.training@dekra.com</a></span>
    </div>
  </div>
</div>
