import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from '../breadcrumb.service';
import { CourseExportFormComponent } from '../course-export-form/course-export-form.component';
import { CourseFormComponent } from '../course-form/course-form.component';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { CustomerListResponse, CustomerService, UsersPermissionsUser, UsersPermissionsUsersRolesService } from '../swagger-api-v4';
import { Utilities } from '../utilities';
import { UserFormComponent } from '../user-form/user-form.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  ref: DynamicDialogRef;
  // courses: StateCourse[] = [];
  // courses: Course[] = [];
  customers: CustomerListResponse;
  users: UsersPermissionsUser[] = [];
  selectedUser: UsersPermissionsUser = null;

  Util = Utilities;

  cols = [
    { field: 'username', header: 'Username', type: 'Text' },
    { field: 'email', header: 'E-Mail', type: 'Text' },
    { field: 'customerNames', header: 'Manages Customer(s)', type: 'Text' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    public dialogService: DialogService,
    public userService: UsersPermissionsUsersRolesService,
    public customerService: CustomerService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private http: HttpClient

  ) {

  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    const items: MenuItem[] = [
      { label: 'Users', routerLink: '/users' },
    ];
    this.breadcrumbService.setItems(items);
  }

  newUser(event) {
    const newUser: UsersPermissionsUser = {
      id: null,
      username: '',
      email: '',
      customers: []
    };
    this.selectedUser = newUser;

    this.showEditDialog(newUser);
  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/users?sort=username&populate[customers][fields][0]=name&populate[role][fields][0]=type', { headers: headers }).toPromise().then((users: UsersPermissionsUser[]) => {

      users.forEach(user => { user['customerNames'] = user.customers.map(customer => customer.name) });
      console.log('Users', users);
      this.users = users.filter(user => (user.username != 'cms' && user.username != 'cms-api'));
    }, (err => {
      this.showError('Error while getting users.\n' + err.message);
    }))


    this.customerService.getcustomers().toPromise().then((customers) => {
      console.log('Customers', customers);
      customers.data = customers.data.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
      this.customers = customers;
    });
  }

  formatDate(date) {
    return Utilities.formatDate(date);
  }

  gotoRoute(rowData) {
    this.router.navigate(['/applications']);
  }

  showEditDialog(user: UsersPermissionsUser) {
    console.log('showEditDialog', user);
    this.ref = this.dialogService.open(UserFormComponent, {
      header: user.id > 0 ? 'Edit User' : 'New User',
      width: '600px',
      data: {
        user: user,
        customers: this.customers.data.map(customer => { return { value: customer.id, label: customer.attributes.name } })
      },
    });

    this.ref.onClose.subscribe((user) => {
      if (user) {
        this.refreshData();
      }
    });
  }

  deleteUser(user: UsersPermissionsUser) {
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, user.username),
      accept: () => {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
        this.http.delete('/cms-server/users/' + user.id, { headers: headers }).toPromise().then((user: UsersPermissionsUser) => {
          console.log('deleted user', user);
          this.refreshData();
        }, (err) => {
          this.showError("Could not delete user!\n" + err.message);
        })
      },
    });
  }


  showError(msg) {
    this.messageService.add({ key: 'error', severity: 'error', summary: 'Error', detail: msg });
  }



}
