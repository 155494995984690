<app-breadcrumbs></app-breadcrumbs>
<hr />
<!-- <p-toast></p-toast> -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<!-- <div class="header">Nuggets</div> -->

<div style="max-height: calc(100vh - 120px); overflow-y: scroll;">
  <p-table [columns]="cols" [value]="nuggets" selectionMode="single" [(selection)]="selectedNugget" dataKey="id"
    [resizableColumns]="true" [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 10px"></th>
        <th style="width: 34px">#</th>
        <th *ngFor="let col of columns" pResizableColumn pReorderableColumn
          [style]="col.width ? ' width:'+col.width : ''">
          {{col.header}}
        </th>
        <th>Contents</th>
        <th style="width: 140px">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex" let-expanded="expanded">
      <tr [pReorderableRow]="index" [pSelectableRow]="rowData">

        <td> <span class="fas fa-grip-vertical" pReorderableRowHandle></span> </td>
        <td>{{index+1}}</td>
        <td *ngFor="let col of columns" class="" style="white-space: normal; overflow: hidden; text-overflow: ellipsis;">
          <ng-container *ngIf="col.field === 'title'">
            <span>({{rowData.attributes?.exams?.data.length}})<i [pRowToggler]="rowData"
                [class]="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i></span>&nbsp;
          </ng-container>

          <ng-container *ngIf="col.type === 'Date'">
            {{ Util.formatDate(rowData.attributes[col.field]) }}
          </ng-container>
          <ng-container *ngIf="col.type !== 'Date'">
            {{ rowData.attributes[col.field] }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor="let content of rowData.attributes.content.data; let i = index; let last = last; let first = first">
            <span *ngIf="first && rowData.attributes.content.length > 1">({{ rowData.attributes.content.length }}) </span>
            <span *ngIf="!last">{{ content.attributes.name }}, </span>
            <span *ngIf="last">{{ content.attributes.name }}</span>
          </ng-container>

          <!-- <ng-container *ngIf="rowData.content?.formats?.thumbnail?.mime">
                    {{ rowData.content.name }}{{ rowData.content.ext }}
                </ng-container> -->
          <!-- <ng-container *ngIf="Util.isImage(rowData.content?.formats?.thumbnail?.mime)">
                    <img [src]="Util.getUrl(rowData.content.formats.thumbnail.url)" width="100%">
                        <app-exams [nug]="selectedNugget" [nugExams]="selectedNugget?.exams"></app-exams>

                </ng-container> -->
        </td>
        <td>
          <span><button pButton type="button" icon="fas fa-edit"
              (click)="showEditDialog(rowData)"></button></span>&nbsp;
          <span><button pButton type="button" icon="fas fa-times" class="p-button-danger" (click)="onDelete(rowData)"
              [disabled]="rowData?.attributes.exams.data.length>0"></button>
          </span>

        </td>
      </tr>
    </ng-template>
    <ng-template let-rowData pTemplate="rowexpansion">
      <td></td>
      <td></td>
      <td colspan="7" *ngIf="rowData">
        <app-exams [nug]="rowData" [nugExams]="rowData?.attributes.exams.data"></app-exams>
        <!-- <app-levels [app]="rowData" [appLevels]="rowData.levels"></app-levels> -->
      </td>
    </ng-template>
    <!-- <ng-template pTemplate="summary">
      <div class="right">
        <span><button pButton type="button" label="New Nugget" icon="fas fa-plus"
            (click)="newNugget($event)"></button></span>&nbsp;
      </div>
    </ng-template> -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length+2">
          <div style="text-align: center;">Loading nuggets or no nuggets found</div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br>
<div class="right">
  <span><button pButton type="button" label="New Nugget" icon="fas fa-plus"
      (click)="newNugget($event)"></button></span>&nbsp;
</div>



<!--
<!-- <div *ngIf="selectedNugget">
    <app-exams [nug]="selectedNugget" [nugExams]="selectedNugget?.exams"></app-exams>
</div> -->
