import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer, UsersBody, UsersIdBody, UsersPermissionsUser, UsersPermissionsUsersRolesService } from '../swagger-api-v4';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { Utilities } from '../utilities';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  // @Input() set user(user: UsersPermissionsUser) {
  //   this.originaluser = user;
  //   Object.assign(this.userModel, user);
  //   console.log('this.userModel', this.userModel);
  // };

  contentRef: DynamicDialogRef;

  userModel: UsersIdBody = { username: '', email: '', password: '', blocked: false, customers: [] };
  // userModel: UsersIdBody = { username: '', email: '', confirmed: true, blocked: false };
  // userModel: UsersPermissionsUser = { username: '', email: '', confirmed: true, blocked: false, manageCustomers:[] };
  originaluser: UsersPermissionsUser = null;

  unlockedLevels = false;
  unlockedAppExt = false;

  showPreview = false;

  user: UsersPermissionsUser;
  customers: SelectItem[];
  selectedCustomers;

  Util = Utilities;

  constructor(
    private userService: UsersPermissionsUsersRolesService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.user = this.config.data.user;
    this.originaluser = this.user;
    Object.assign(this.userModel, this.user);
    this.customers = this.config.data.customers;
    console.log('user', this.user);
    console.log('customers', this.customers);
    this.selectedCustomers = this.user.customers.map(customer => customer['id']);
    console.log('selected', this.selectedCustomers);
    window.setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 100);
    // }
  }

  onSubmit() {
    console.log('save', this.userModel);
    const user : UsersBody = { username: this.userModel.username, email: this.userModel.email , blocked: this.userModel.blocked, customers: this.selectedCustomers};
    console.log('save user', user);
    // this.userModel.customers = this.selectedCustomers;
    if (this.originaluser?.id > 0) {
        this.userService.usersIdPut(user, '' + this.originaluser.id).toPromise().then(
        user => {
          console.log('user save put', user);
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: app.title });
          this.ref.close(user);
        }, (err) => {
          console.log('user save put error', err);
        }
      )
    } else {
      if (this.userModel.password.length > 0) {
        user['password'] = this.userModel.password;
        user['role'] = 3;
      }
      this.userService.usersPost(user).toPromise().then(
        newuser => {
          console.log('user save post', newuser);
          //  this.messageService.add({ severity: 'success', summary: 'Saved', detail: newuser.username });
          this.ref.close(newuser);
        }, (err) => {
          console.log('user save post error', err);
        }
      )
    }
  }

  onCancel() {
    this.ref.close();
  }

}
