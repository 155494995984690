<p-card>
  <form #form="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="name">Name</label>
      </div>
      <div class="p-col form-input-field">
        <input #nameInput #name="ngModel" name="name" id="name" type="text" size="100" pInputText
          [(ngModel)]="customerModel.attributes.name" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>


    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="logo">Logo</label>
      </div>
      <div class="p-col form-input-field">
        <ng-container *ngIf="customerModel.attributes.logo.data != null">
          <span>
            {{ customerModel.attributes.logo.data.attributes.name }}
            <!-- <button pButton type="button" class="p-button-raised p-button-rounded"
              label="{{ feedModel.thumbnail.name }}"></button> -->
            &nbsp;&nbsp;
          </span>
        </ng-container>
        <span><button pButton type="button" icon="fas fa-photo-video"
            [label]="customerModel.attributes.logo ? 'Replace logo image' : 'Select logo image from library' "
            (click)="showMediaLibDialogLogo()"></button></span>
        <br />
      </div>
    </div>


    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="topbarcolor">Topbar color</label>
      </div>
      <div class="p-col form-input-field">
        <input #topbarcolor="ngModel" name="topbarcolor" id="topbarcolor" type="color"
          [(ngModel)]="customerModel.attributes.topbarColor" />
        <!-- <p-colorPicker name="topbarcolor" [(ngModel)]="topbarcolor"></p-colorPicker> -->
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="topbarpreview">Preview</label>
      </div>
      <div class="p-col form-input-field">
        <div [ngStyle]="{'background-color' : customerModel.attributes.topbarColor}">
          <img *ngIf="customerModel.attributes.logo.data != null && customerModel.attributes.logo.data.attributes.url.length > 0" id="Logo"
            [src]="Util.getUrl(customerModel.attributes.logo.data.attributes.url)" alt="Logo" style="padding: 5px">
          <img
            *ngIf="customerModel.attributes.logo.data === null || customerModel.attributes.logo === undefined || customerModel.attributes.logo.data.attributes.url.length === 0"
            id="topbar-logo" src="assets/Dekra-topbar.png" alt="Logo" style="padding: 5px">
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="buttoncolor">Button color</label>
      </div>
      <div class="p-col form-input-field">
        <input #buttoncolor="ngModel" name="buttoncolor" id="buttoncolor" type="color"
          [(ngModel)]="customerModel.attributes.buttonColor" />

        <button pButton label="Example button with custom color" class="ui-button-secondar ui-button"
          [ngStyle]="{'background-color' : customerModel.attributes.buttonColor}"></button>

      </div>

    </div>


    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="startpageMD">Startpage Markdown</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="startpageMD" id="startpageMD" autoResize="true" pInputTextarea
          [(ngModel)]="customerModel.attributes.startpageMarkdown" style="width: 100%;"></textarea>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="startpageMDpreview">Preview</label><br/>
        <a style="cursor: pointer;" (click)="showPreview=!showPreview">{{showPreview ? 'Hide' : 'Show'}}</a>
      </div>
      <div class="p-col">
        <span *ngIf="showPreview" [innerHTML]="customerModel.attributes.startpageMarkdown | markdown"></span>
      </div>
    </div> -->


    <!-- <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="name">Name</label>
      </div>
      <div class="p-col form-input-field">
        <input #nameInput #name="ngModel" name="name" id="name" type="text" size="100" pInputText
          [(ngModel)]="courseModel.Name" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div> -->

    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="docebocustomerId">Docebo courese id</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="docebocustomerId" style="width: 400px;"></textarea>
      </div>
    </div> -->



    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="locale">Language </label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.locale" appendTo="body">
        </p-dropdown>
        <!-- <input name="locale" id="locale" type="text" size="50" pInputText [(ngModel)]="appModel.locale"
                    style="width: 400px;"> -->
    <!-- </div> -->
    <!-- </div>  -->

  </form>



</p-card>



<br>

<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!form.form.valid"
    class="ui-button"></button>
</div>
