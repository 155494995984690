<p-card>
  <form #appform="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="title">Title</label>
      </div>
      <div class="p-col form-input-field">
        <input #titleInput #title="ngModel" name="title" id="title" type="text" size="100" pInputText
          [(ngModel)]="appModel.attributes.title" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="title.valid || title.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="description">Description</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="appModel.attributes.description" style="width: 400px;"></textarea>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="locale">Language </label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.attributes.locale" appendTo="body">
        </p-dropdown>
        <!-- <input name="locale" id="locale" type="text" size="50" pInputText [(ngModel)]="appModel.locale"
                    style="width: 400px;"> -->
      </div>
    </div>
  </form>
</p-card>

<br>

<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!appform.form.valid"
    class="ui-button"></button>
</div>
