<p-card>
  <form #form="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="name">Name</label>
      </div>
      <div class="p-col form-input-field">
        <input #nameInput #name="ngModel" name="name" id="name" type="text" size="100" pInputText
          [(ngModel)]="courseModel.attributes.name" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="courseId">Docebo Course Id</label>
      </div>
      <div class="p-col form-input-field">
        <input #courseId="ngModel" name="doceboCourseId" id="courseId" type="number" size="20" pInputText
          [(ngModel)]="doceboCourseId" required minlength="1" maxlength="8" min="0" max="99999999" style="width:400px"
          autocomplete="off">
        <span [hidden]="(courseId.valid || courseId.pristine) && doceboCourseId >= 0" class="alert alert-danger">
          Docebo course id is required
        </span>
      </div>
    </div>
    <hr>
    <br>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="iconGroup">Icon Group</label>
      </div>
      <div class="p-col form-input-field">
        <!-- <p-dropdown name="iconGroup" id="iconGroup" [options]="iconGroupNames" [(ngModel)]="appModel.locale" appendTo="body"> -->
        <p-dropdown name="iconGroup" id="iconGroup" [options]="iconGroupNames" [(ngModel)]="selectedIconGroupName"
          (onChange)="changedIconGroup($event)" appendTo="body">
        </p-dropdown>
      </div>
    </div>
    <br>
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="icon">Cube icon</label>
      </div>
      <div class="p-col form-input-field" style="width:48px;vertical-align:middle;margin:5px;">
        <ng-container *ngIf="selectedIconGroupName === 'HiVo'">
          <ng-container *ngFor="let iconNr of iconNrs">
            <img src='{{ "/assets/cube/icons-png/icon_"+iconNr.label+".png" }}' class="icon" />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectedIconGroupName !== 'HiVo'">
          <ng-container *ngFor="let file of iconFiles">
            <img [src]='Util.getUrl(file.url)' class="icon" />
          </ng-container>
        </ng-container>
      </div>
    </div>
    <hr>
    <br>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="unlockLevels">Demo mode</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-12">
          <p-checkbox name="group1" value="unlockAppExtension" label="Unlock app extension"
            [(ngModel)]="selectedDemoModes" inputId="appExt"></p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group1" value="unlockAllLevels" label="Unlock all cube levels"
            [(ngModel)]="selectedDemoModes" inputId="allLevels"></p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group1" value="lockTopLevels" label="Lock level 2-4" [(ngModel)]="selectedDemoModes"
            inputId="oneLevel"></p-checkbox>
        </div>
      </div>
    </div>
    <br>

    <hr>
    <br>
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="appext">App Extension</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-12">
          <p-checkbox name="group2" value="ks" label="Knowledge Store & Favourites" [(ngModel)]="selectedAppExt"
            inputId="ks"></p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="rc" label="Rescue Cards" [(ngModel)]="selectedAppExt" inputId="rc">
          </p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="feed" label="My Feed" [(ngModel)]="selectedAppExt" inputId="feed">
          </p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="faq" label="FAQ" [(ngModel)]="selectedAppExt" inputId="faq"></p-checkbox>
        </div>
      </div>
    </div>

    <hr>
    <br>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="appext">Cube</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">

        <div class="field-radiobutton p-col-12">
          <p-radioButton name="cube" value="4" [(ngModel)]="cubeLevels" inputId="cube4"></p-radioButton>
          <label for="cube4">&nbsp;4x4x4</label>
        </div>
        <div class="field-radiobutton p-col-12">
          <p-radioButton name="cube" value="3" [(ngModel)]="cubeLevels" inputId="cube3"></p-radioButton>
          <label for="cube3">&nbsp;3x3x3</label>
        </div>

      </div>
    </div>



    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="doceboCourseId">Docebo courese id</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="doceboCourseId" style="width: 400px;"></textarea>
      </div>
    </div> -->





    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="locale">Language </label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.locale" appendTo="body">
        </p-dropdown>
        <!-- <input name="locale" id="locale" type="text" size="50" pInputText [(ngModel)]="appModel.locale"
                    style="width: 400px;"> -->
    <!-- </div> -->
    <!-- </div>  -->

  </form>
</p-card>

<br>

<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!form.form.valid"
    class="ui-button"></button>
</div>
