// import { Route } from '@angular/compiler/src/core';
import { AfterViewInit, Component, ContentChild, HostListener, ViewChild } from '@angular/core';
// import { Router } from '@angular/router'; 
import { environment } from 'src/environments/environment';
import { AppMainComponent } from './app.main.component';
import { Utilities } from './utilities';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements AfterViewInit {


  // window.addEventListener('offline', function(e) { console.log('offline'); });
  // window.addEventListener('online', function(e) { console.log('online'); });

  showMenu = false;
  showBarMenu = false;
  logoUrl: string;
  appExt = [];


  Util = Utilities;

  constructor(
    public app: AppMainComponent,
    // private router: Router,

) {

    // console.log('doceboService.route', this.doceboService.route);
    // this.configStore.select('configs').subscribe(configs => {
    //   let wannaShowMenu = false;
    //   // console.log('got config in topbar ', configs);
    //   if (configs.findIndex(set => (set.key === 'completed-cube')) >= 0) {
    //     // console.log('showmenu true');
    //     wannaShowMenu = true;
    //     this.showMenu = true;
    //   }
    //   //  else {
    //   //   // console.log('showmenu false');
    //   //   this.showMenu = false;
    //   // }

    //   const appExt = configs.find((conf) => conf.key === 'appExt');
    //   if (appExt !== undefined && appExt.value !== undefined) {
    //     //"ks","feed","rc","faq"
    //     this.appExt = appExt.value;
    //   } else {
    //     this.appExt = [];
    //   }


      // const confDemoMode = configs.find((conf) => conf.key === 'demoMode');
      // // console.log('confDemoMode', confDemoMode)
      // if (confDemoMode !== undefined) {
      //   const unlockAppExt = (confDemoMode?.value?.includes('unlockAppExtension' || environment.seniorPlusUser) ? true : false);
      //   if (unlockAppExt) {
      //     wannaShowMenu = true;
      //     this.showMenu = true;
      //   }
      //   //this.showMenu = unlockAppExt;
      // }

      // console.log('CHECK SHOWMENU', authDoceboService.loggedIn);
      //this.showBarMenu = authDoceboService.loggedIn;

    //   const confLogo = configs.find((conf) => conf.key === 'customerLogo');
    //   // console.log('confLogo', confLogo)
    //   if (confLogo !== undefined) {
    //     this.logoUrl = confLogo.value;
    //   }
    // })

    // this.app.topbarColor = this.getRandomColor();

  }


  // getRandomColor() {
  //   const letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //     console.log('color:', i, color);
  //   }
  //   console.log('rndcolor', color);
  //   return color;
  // }


  getFullUserName(): string {
    // if (this.authDoceboService.loggedIn) {
      return 'FULL USERNAME'; //this.authDoceboService.username;
    // } else
    //   return '';
  }


  getUserContext(): string {
    // if (this.authDoceboService.loggedIn) {
      return 'Learner';
    // } else
    //   return '';
  }

  isSelected(menuRoute: string[]) {
    // if (menuRoute.includes(this.router.url)) {
    //   return { 'font-weight': 'bold' };
    // } else {
    //   return "";
    // }
  }

  ngAfterViewInit(): void {

  }
}
