<p-confirmDialog header="Confirmation"></p-confirmDialog>
<p-card>
  <form #theform="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="title">Title</label>
      </div>
      <div class="p-col form-input-field">
        <input #titleInput #title="ngModel" name="title" id="title" type="text" size="100" pInputText
          [(ngModel)]="nuggetModel.attributes.title" required minlength="1" maxlength="100" style="width:400px"
          autocomplete="off">
        <span [hidden]="title.valid || title.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="description">Description</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="1500" size="1500" autoResize="true" pInputTextarea
          [(ngModel)]="nuggetModel.attributes.description" style="width: 400px;" autocomplete="off"></textarea>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="keywords">Keywords</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="keywords" id="keywords" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="nuggetModel.attributes.keywords" style="width: 400px;" autocomplete="off"></textarea>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="learningTime">Learning Time (minutes)</label>
      </div>
      <div class="p-col form-input-field">
        <!-- <p-inputNumber #learningTime #learningTime="ngModel" [(ngModel)]="levelModel.learningTime" [min]="0"
                    [max]="60" required style="width:400px"></p-inputNumber> -->
        <input #learningTimeInput #learningTime="ngModel" name="learningTime" id="learningTime" type="number" size="100"
          pInputText [(ngModel)]="nuggetModel.attributes.learningTime" required minlength="1" maxlength="3" min="0"
          max="999" style="width:400px" autocomplete="off">
        <span [hidden]="(learningTime.valid || learningTime.pristine) && nuggetModel.attributes.learningTime >= 0"
          class="alert alert-danger">
          Learning time is required >= 0 (0 for unlimited time)
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="icon">Cube icon</label>
      </div>
      <div class="p-col form-input-field" style="width:48px;vertical-align:middle;margin:5px;">
        <ng-container *ngIf="selectedIconGroupName === 'HiVo'">
          <ng-container *ngFor="let iconNr of iconNrs">
            <p-button class="icon-button" (onClick)="setIcon(iconNr.value)">
              <ng-template pTemplate="content">
                <img src='{{ "/assets/cube/icons-png/icon_"+iconNr.label+".png" }}'
                  style="width:48px;vertical-align:middle;"
                  [ngClass]="(iconNr.value === nuggetModel.attributes.iconNr ? 'styleSelected' : 'styleNotSelected') " />
              </ng-template>
            </p-button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectedIconGroupName !== 'HiVo'">
          <ng-container *ngFor="let file of iconFiles">
            <p-button class="icon-button" (onClick)="setIcon(file.iconNr)">
              <ng-template pTemplate="content">
                <img [src]='Util.getUrl(file.url)' style="width:48px;vertical-align:middle;"
                  [ngClass]="(file.iconNr === nuggetModel.attributes.iconNr ? 'styleSelected' : 'styleNotSelected') " />
              </ng-template>
            </p-button>
          </ng-container>
        </ng-container>


        <!-- <p-selectButton name="iconNr" [options]="iconNrs" [(ngModel)]="nuggetModel.attributes.iconNr">
          <ng-template let-item>
            <img src='{{ "/assets/cube/icons-png/icon_"+nuggetModel.attributes.iconNr+".png" }}'
              style="width:48px;vertical-align:middle;margin:5px;" />
          </ng-template>
        </p-selectButton>
        <img src='{{ "/assets/cube/icons-png/icon_"+nuggetModel.attributes.iconNr+".png" }}'
          style="width:48px;vertical-align:middle;margin:5px;" />

          <img src='{{ "/assets/cube/icons-png/icon_"+nuggetModel.attributes.iconNr+".png" }}'
          style="width:48px;vertical-align:middle;margin:5px;" class="styleSelected"/> -->


        <!-- <p-dropdown name="icon" [options]="iconNrs" [(ngModel)]="nuggetModel.iconNr" appendTo="body"
                        [style]="{'width':'150px'}">
                        <ng-template let-item pTemplate="selectedItem">
                            <img src="assets/cube/icon-{{item.label}}.png" style="width:48px;vertical-align:middle" />
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div style="position: relative;height:48px;">
                                <img src="assets/cube/icon-{{item.label}}.png"
                                    style="width:48px;position:absolute;top:1px;left:5px" />
                            </div>
                        </ng-template>
                    </p-dropdown> -->
      </div>
    </div>


    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="content">Contents</label>
      </div>
      <div class="p-col form-input-field">
        <ng-container *ngIf="nuggetModel.attributes.content.data">
          <ng-container *ngFor="let content of nuggetModel.attributes.content.data; let i = index; let last = last;">
            <span>
              <button pButton type="button" class="p-button-raised p-button-rounded" icon="fas fa-times" iconPos="right"
                label="{{ content.attributes.name }}" (click)="onRemoveContent(content)"></button>
            </span>
          </ng-container>
          <br>
        </ng-container>
        <!-- <span><button pButton type="button" icon="fas fa-upload" label="Upload content"
                        (click)="upload();"></button></span>&nbsp; -->
        <span><button pButton type="button" icon="fas fa-photo-video" label="Select content from library"
            (click)="showMediaLibDialog();"></button></span>
        <br>
      </div>
    </div>
  </form>
</p-card>
<br>
<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!theform.form.valid"
    class="ui-button"></button>
</div>
