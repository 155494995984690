<p-card>
  <form #form="ngForm">
    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="newusername">Username</label>
      </div>
      <div class="p-col form-input-field">
        <input autocomplete="false" #nameInput #newusername="ngModel" name="newusername" id="newusername" type="text"
          size="100" pInputText [(ngModel)]="userModel.username" required minlength="5" maxlength="100"
          style="width:400px">
        <span [hidden]="newusername.valid || newusername.pristine" class="alert alert-danger">
          Username is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="newemail">E-Mail</label>
      </div>
      <div class="p-col form-input-field">
        <input autocomplete="false" #newemail="ngModel" name="newemail" id="newemail" type="text" size="100" pInputText
          [(ngModel)]="userModel.email" required minlength="1" maxlength="100" style="width:400px"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
        <span [hidden]="newemail.valid || newemail.pristine" class="alert alert-danger">
          Valid email is required
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="newpassword">Password</label>
      </div>
      <div class="p-col form-input-field">
        <input autocomplete="false" #newpassword="ngModel" name="newpassword" id="newpassword" type="password"
          size="100" pInputText [(ngModel)]="userModel.password" minlength="5" maxlength="100" style="width:400px">
        <span [hidden]="newpassword.valid || newpassword.pristine" class="alert alert-danger">
          Password is required. Minimum 5 characters.
        </span>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="blocked">Blocked</label>
      </div>
      <div class="p-col form-input-field" >
        <p-inputSwitch  name="blocked"  #blocked="ngModel" [(ngModel)]="userModel.blocked"></p-inputSwitch>
        <!-- <p-multiSelect style="max-width: 400px"  autocomplete="false" #manages="ngModel" name="manages" appendTo="body" [options]="customers"
          [(ngModel)]="selectedCustomers" display="chip" defaultLabel="Select customer(s)"></p-multiSelect> -->
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="manages">Manage customers</label>
      </div>
      <div class="p-col form-input-field" >
        <p-multiSelect [style]="{'max-width': '400px'}" autocomplete="false" #manages="ngModel" name="manages" appendTo="body" [options]="customers"
          [(ngModel)]="selectedCustomers" display="chip" defaultLabel="Select customer(s)"></p-multiSelect>
      </div>
    </div>
    <!---
      <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="startpageMDpreview">Preview</label><br/>
        <a style="cursor: pointer;" (click)="showPreview=!showPreview">{{showPreview ? 'Hide' : 'Show'}}</a>
      </div>
      <div class="p-col">
        <span *ngIf="showPreview" [innerHTML]="userModel.startpageMarkdown | markdown"></span>
      </div>
    </div> -->


    <!-- <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="name">Name</label>
      </div>
      <div class="p-col form-input-field">
        <input #nameInput #name="ngModel" name="name" id="name" type="text" size="100" pInputText
          [(ngModel)]="courseModel.Name" required minlength="1" maxlength="100" style="width:400px">
        <span [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Name is required
        </span>
      </div>
    </div> -->

    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="docebocustomerId">Docebo courese id</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="docebocustomerId" style="width: 400px;"></textarea>
      </div>
    </div> -->



    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="locale">Language </label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.locale" appendTo="body">
        </p-dropdown>
        <!-- <input name="locale" id="locale" type="text" size="50" pInputText [(ngModel)]="appModel.locale"
                    style="width: 400px;"> -->
    <!-- </div> -->
    <!-- </div>  -->

  </form>



</p-card>



<br>

<div class="right grouped-buttons">
  <button pButton type=" cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Save" (click)="onSubmit()" [disabled]="!form.form.valid"
    class="ui-button"></button>
</div>
