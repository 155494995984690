/**
 * DOCUMENTATION
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: contact-email@something.io
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthChangepasswordBody } from '../model/authChangepasswordBody';
import { AuthForgotpasswordBody } from '../model/authForgotpasswordBody';
import { AuthLocalBody } from '../model/authLocalBody';
import { AuthResetpasswordBody } from '../model/authResetpasswordBody';
import { AuthSendemailconfirmationBody } from '../model/authSendemailconfirmationBody';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { LocalRegisterBody } from '../model/localRegisterBody';
import { UsersPermissionsUserRegistration } from '../model/usersPermissionsUserRegistration';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersPermissionsAuthService {

    protected basePath = '/cms-server';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Update user&#x27;s own password
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authChangePasswordPost(body: AuthChangepasswordBody, observe?: 'body', reportProgress?: boolean): Observable<UsersPermissionsUserRegistration>;
    public authChangePasswordPost(body: AuthChangepasswordBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersPermissionsUserRegistration>>;
    public authChangePasswordPost(body: AuthChangepasswordBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersPermissionsUserRegistration>>;
    public authChangePasswordPost(body: AuthChangepasswordBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authChangePasswordPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UsersPermissionsUserRegistration>('post',`${this.basePath}/auth/change-password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm user email
     *
     * @param confirmation confirmation token received by email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authEmailConfirmationGet(confirmation?: string, observe?: 'body', reportProgress?: boolean): Observable<Error>;
    public authEmailConfirmationGet(confirmation?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Error>>;
    public authEmailConfirmationGet(confirmation?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Error>>;
    public authEmailConfirmationGet(confirmation?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (confirmation !== undefined && confirmation !== null) {
            queryParameters = queryParameters.set('confirmation', <any>confirmation);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Error>('get',`${this.basePath}/auth/email-confirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send rest password email
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authForgotPasswordPost(body: AuthForgotpasswordBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public authForgotPasswordPost(body: AuthForgotpasswordBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public authForgotPasswordPost(body: AuthForgotpasswordBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public authForgotPasswordPost(body: AuthForgotpasswordBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authForgotPasswordPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse200>('post',`${this.basePath}/auth/forgot-password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Local login
     * Returns a jwt token and user info
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authLocalPost(body: AuthLocalBody, observe?: 'body', reportProgress?: boolean): Observable<UsersPermissionsUserRegistration>;
    public authLocalPost(body: AuthLocalBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersPermissionsUserRegistration>>;
    public authLocalPost(body: AuthLocalBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersPermissionsUserRegistration>>;
    public authLocalPost(body: AuthLocalBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authLocalPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UsersPermissionsUserRegistration>('post',`${this.basePath}/auth/local`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a user
     * Returns a jwt token and user info
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authLocalRegisterPost(body: LocalRegisterBody, observe?: 'body', reportProgress?: boolean): Observable<UsersPermissionsUserRegistration>;
    public authLocalRegisterPost(body: LocalRegisterBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersPermissionsUserRegistration>>;
    public authLocalRegisterPost(body: LocalRegisterBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersPermissionsUserRegistration>>;
    public authLocalRegisterPost(body: LocalRegisterBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authLocalRegisterPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UsersPermissionsUserRegistration>('post',`${this.basePath}/auth/local/register`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Default Callback from provider auth
     *
     * @param provider Provider name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authProviderCallbackGet(provider: string, observe?: 'body', reportProgress?: boolean): Observable<UsersPermissionsUserRegistration>;
    public authProviderCallbackGet(provider: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersPermissionsUserRegistration>>;
    public authProviderCallbackGet(provider: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersPermissionsUserRegistration>>;
    public authProviderCallbackGet(provider: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling authProviderCallbackGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UsersPermissionsUserRegistration>('get',`${this.basePath}/auth/${encodeURIComponent(String(provider))}/callback`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rest user password
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authResetPasswordPost(body: AuthResetpasswordBody, observe?: 'body', reportProgress?: boolean): Observable<UsersPermissionsUserRegistration>;
    public authResetPasswordPost(body: AuthResetpasswordBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersPermissionsUserRegistration>>;
    public authResetPasswordPost(body: AuthResetpasswordBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersPermissionsUserRegistration>>;
    public authResetPasswordPost(body: AuthResetpasswordBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authResetPasswordPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UsersPermissionsUserRegistration>('post',`${this.basePath}/auth/reset-password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send confirmation email
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authSendEmailConfirmationPost(body: AuthSendemailconfirmationBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public authSendEmailConfirmationPost(body: AuthSendemailconfirmationBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public authSendEmailConfirmationPost(body: AuthSendemailconfirmationBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public authSendEmailConfirmationPost(body: AuthSendemailconfirmationBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authSendEmailConfirmationPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<InlineResponse2001>('post',`${this.basePath}/auth/send-email-confirmation`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Login with a provider
     * Redirects to provider login before being redirect to /auth/{provider}/callback
     * @param provider Provider name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectProviderGet(provider: string, observe?: 'body', reportProgress?: boolean): Observable<Error>;
    public connectProviderGet(provider: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Error>>;
    public connectProviderGet(provider: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Error>>;
    public connectProviderGet(provider: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling connectProviderGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Error>('get',`${this.basePath}/connect/${encodeURIComponent(String(provider))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
