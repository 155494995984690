
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerLogo, CustomerRequest, CustomerRequestData, CustomerResponseDataObject, CustomerService } from '../swagger-api-v4';
import { ConfirmationService } from 'primeng/api';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { Utilities } from '../utilities';
import { UploadFile } from 'resources/swagger-api';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class CustomerFormComponent implements OnInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  @Input() set customer(customer: CustomerResponseDataObject) {
    this.originalcustomer = customer;
    this.customerModel.id = customer.id;
    Object.assign(this.customerModel.attributes, customer.attributes);
    if (customer.attributes.logo) {
      Object.assign(this.customerModel.attributes.logo, customer.attributes.logo);
    }
    if (customer.attributes.topbarColor === null) {
      this.customerModel.attributes.topbarColor = "#007D40";
    }
    if (customer.attributes.buttonColor === null) {
      this.customerModel.attributes.buttonColor = "#007D40";
    }
  };

  contentRef: DynamicDialogRef;

  customerModel: CustomerResponseDataObject = { id: 0, attributes: {} };
  originalcustomer: CustomerResponseDataObject = null;


  unlockedLevels = false;
  unlockedAppExt = false;

  showPreview = false;

  Util = Utilities;

  constructor(
    private customerService: CustomerService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.customer = this.config.data.customer;
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 100);
    // }
  }

  onSubmit() {
    const customerRequestData: CustomerRequestData = {
      name: this.customerModel.attributes.name,
      logo: this.customerModel.attributes.logo.data?.id,
      isDeleted: this.customerModel.attributes.isDeleted,
      topbarColor: this.customerModel.attributes.topbarColor,
      buttonColor: this.customerModel.attributes.buttonColor,
      startpageMarkdown: this.customerModel.attributes.startpageMarkdown
    }
    const customerRequest: CustomerRequest = { data: customerRequestData };
    console.log('customerRequest', customerRequest);
    if (this.customerModel.id > 0) {
      this.customerService.putcustomersid(customerRequest, this.customerModel.id).toPromise().then(
        customer => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: app.title });
          this.ref.close(customer);
        }, (err) => {
          console.log('customer save put error', err);
        }
      )
    } else {
    customerRequest.data['manages'] = [environment.user.id];
      this.customerService.postcustomers(customerRequest).toPromise().then(
        newcustomer => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newapp.title });
          this.ref.close(newcustomer);
        }, (err) => {
          console.log('customer save post error', err);
        }
      )
    }
  }

  onCancel() {
    this.ref.close();
  }

  showMediaLibDialogLogo() {
    this.contentRef = this.dialogService.open(ContentLibraryComponent, {
      header: 'Select Content',
      showHeader: false,
      height: '100%',
      //  style: "'min-height': '100%'; 'min-width': '100%'",
      width: '100%',
      data: {
        selector: true,
      },
    });

    this.contentRef.onClose.subscribe((content: UploadFile) => {
      console.log('content', content);
      if (content !== undefined) {
        const customerLogo: CustomerLogo = {
          data: {
            id: Number(content.id),
            attributes: {}
          }
        }
        Object.assign(customerLogo.data.attributes, content);
        this.customerModel.attributes.logo = customerLogo;
      }
    });
  }

}
