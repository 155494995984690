<p-confirmDialog #bandwidth key="bandwidth" [closeable]="false"   i18n-message="Confirmation dialog bandwidth informationgModel@@#APP_BANDWIDTH_INFORMATION"
  message="This learning element is available in two versions. Use 'Low bandwidth' if you experience problems with downloading or slow performance."
 style="{width: 'auto';}" styleClass="p-confirm-dialog-message-override p-dialog-title-override">
  <p-footer>
    <!-- <div *ngIf="learningTime>0" style=" width: 100%; text-align: center;">
      <span i18n="Confirmation dialog nugget start learning time@@#CUBE_DIALOG_LEARNING_TIME">Learning time
        approx.</span>
      {{ learningTime }}
      <span i18n="Confirmation dialog nugget start mintes@@#CUBE_DIALOG_LEARNING_TIME_MINUTES_SHORT">min.</span>
    </div>
    <br> -->
    <div class="p-confirm-dialog-footer">
      <!-- <button type="button" class="rejectButton" pButton label="Back" (click)="cd.reject()"></button> -->
      <button type="button" style="float:left;" pButton
        i18n-label="Confirmation dialog nugget low bandwidth@@#APP_LOW_BANDWIDTH" label="Low bandwidth"
        (click)="bandwidth.reject()"></button>
      <!-- <span class="learning-time">Learning time : approx. {{ learningTime }} minutes</span> -->
      <!-- <button type="button" class="acceptButton" pButton label="Open" (click)="cd.accept()"></button> -->
      <button type="button" pButton i18n-label="Confirmation dialog nugget high bandwidth@@#APP_HIGH_BANDWIDTH"
        label="High bandwidth" (click)="bandwidth.accept()"></button>
    </div>
  </p-footer>
</p-confirmDialog>


<!-- <span style="float: right;">
        <p-inputSwitch [(ngModel)]="app.darkTheme" (onChange)="app.changeTheme()"></p-inputSwitch>&nbsp;&nbsp;
        <button pButton type="button" icon="fas fa-photo-video" label="Media Library"
            (click)="showMediaLibDialog();"></button>
    </span> -->
<!-- <span class="dekra-header" *ngIf="showHeader">
    <img class="logo" [src]="darkTheme ? '../assets/dekra_logo_dark.jpg' : '../assets/dekra_logo_green.jpg'">
    <span class="menu">
        <p-menu #menu [popup]="true" appendTo="body" [model]="menuItems"></p-menu>
        <button type="button" style="background-color: #015B27;" pButton icon="fas fa-bars fa-2x" label="" (click)="menu.toggle($event)"></button>
    </span>
</span>
<ng-container *ngIf="showHeader && showBreadcrumbs">
    <app-breadcrumbs></app-breadcrumbs>
</ng-container> -->


<!-- <p-toast></p-toast> -->
<p-toast key="error"></p-toast>

<p-toast key="error222" position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 2">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{message.summary}}</h4>
        <div>{{message.detail}}</div>
        <div *ngIf="message.sticky">
          <br>
          <button (click)="reloadPage()">Reload page</button>
        </div>
      </div>

    </div>
  </ng-template>
</p-toast>

<router-outlet></router-outlet>

<div *ngIf="showFooter" class="subpage-footer">
  <app-footer></app-footer>
</div>
