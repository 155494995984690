<p-dialog header="Export" [(visible)]="displayModal" [modal]="true" [style]="{ width: '70vw' }" [baseZIndex]="1000"
  [draggable]="false" [resizable]="false">
  <div>
    <div *ngIf="downloadFinished">
      {{ downloadText }}
    </div>
    <div *ngIf="!downloadFinished">
      {{ downloadText }}
      <br>
      <br>
      <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <br>
    </div>
    <p-messages [(value)]="msgs"></p-messages>
    <br>
    <!-- <ng-template pTemplate="footer"> -->
    <div class="grouped-buttons" style="text-align: right;">
      <p-button (onClick)="closeDownloadDialog($event)" label="Ok" [disabled]="!downloadFinished"
        styleClass="p-button-text"></p-button>
    </div>
    <!-- </ng-template> -->
  </div>
</p-dialog>


<p-card>

  <form #form="ngForm">

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="levels">Levels</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="group1" value="1" label="Level 1" [(ngModel)]="selectedLevels" inputId="level1">
          </p-radioButton>
        </div>
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="group1" value="2" label="Level 2" [(ngModel)]="selectedLevels" inputId="level2">
          </p-radioButton>
        </div>
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="group1" value="3" label="Level 3" [(ngModel)]="selectedLevels" inputId="level3">
          </p-radioButton>
        </div>
        <div *ngIf="cubeLevels>=4" class="p-col-6 field-radiobutton">
          <p-radioButton name="group1" value="4" label="Level 4" [(ngModel)]="selectedLevels" inputId="level4">
          </p-radioButton>
        </div>
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="group1" value="alllevels" label="All Levels" [(ngModel)]="selectedLevels"
            inputId="alllevels">
          </p-radioButton>
        </div>

        <!-- <div class="p-col-12 field-checkbox">
            <p-checkbox name="group1" value="level1" label="Level 1" [(ngModel)]="selectedLevels" inputId="level1">
            </p-checkbox>
          </div>
          <div class="p-col-12 field-checkbox">
            <p-checkbox name="group1" value="level2" label="Level 2" [(ngModel)]="selectedLevels" inputId="level2">
            </p-checkbox>
          </div>
          <div class="p-col-12 field-checkbox">
            <p-checkbox name="group1" value="level3" label="Level 3" [(ngModel)]="selectedLevels" inputId="level3">
            </p-checkbox>
          </div>
          <div *ngIf="cubeLevels>=4" class="p-col-12 field-checkbox">
            <p-checkbox name="group1" value="level4" label="Level 4" [(ngModel)]="selectedLevels" inputId="level4">
            </p-checkbox>
        </div>-->
      </div>
    </div>




    <!-- selectedLevels : {{ selectedLevels }} -->
    <hr>
    <br>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="levels">SCORM</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-6">
          <p-radioButton name="group2" [disabled]="false" value="scorm1.2" label="1.2" [(ngModel)]="selectedProtocol"
            inputId="scorm12">
          </p-radioButton>
        </div>
        <div class="p-col-6">
          <p-radioButton name="group2" [disabled]="false" value="scorm2004" label="2004" [(ngModel)]="selectedProtocol"
            inputId="scorm2004">
          </p-radioButton>
        </div>
        <!-- <div class="p-col-12">
          <p-checkbox name="group2" value="scormxApi" label="Scorm xApi" [(ngModel)]="selectedProtocol"
            inputId="scormxApi">
          </p-checkbox> -->
      </div>
    </div>

    <!-- <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="appext">App Extension</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-12">
          <p-checkbox name="group2" value="ks" label="Knowledge Store & Favourites" [(ngModel)]="selectedAppExt"
            inputId="ks"></p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="rc" label="Rescue Cards" [(ngModel)]="selectedAppExt" inputId="rc">
          </p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="feed" label="My Feed" [(ngModel)]="selectedAppExt" inputId="feed">
          </p-checkbox>
        </div>
        <div class="p-col-12">
          <p-checkbox name="group2" value="faq" label="FAQ" [(ngModel)]="selectedAppExt" inputId="faq"></p-checkbox>
        </div>
      </div>
    </div>-->


    <!-- <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="appext">Cube</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">

        <div class="field-radiobutton p-col-12">
          <p-radioButton name="cube" value="4" [(ngModel)]="cubeLevels" inputId="cube4"></p-radioButton>
          <label for="cube4">&nbsp;4x4x4</label>
        </div>
        <div class="field-radiobutton p-col-12">
          <p-radioButton name="cube" value="3" [(ngModel)]="cubeLevels" inputId="cube3"></p-radioButton>
          <label for="cube3">&nbsp;3x3x3</label>
        </div>

      </div>
    </div> -->

    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="doceboCourseId">Docebo courese id</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="description" id="description" maxlength="500" size="500" autoResize="true" pInputTextarea
          [(ngModel)]="doceboCourseId" style="width: 400px;"></textarea>
      </div>
    </div> -->





    <!-- <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="locale">Language </label>
      </div>
      <div class="p-col form-input-field">
        <p-dropdown name="locale" id="locale" [options]="locales" [(ngModel)]="appModel.locale" appendTo="body">
        </p-dropdown>
        <!-- <input name="locale" id="locale" type="text" size="50" pInputText [(ngModel)]="appModel.locale"
                    style="width: 400px;"> -->
    <!-- </div> -->
    <!-- </div>  -->

    <hr>
    <br>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="cutscenes">Cutscenes</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="csgroup1" value="3d" label="3D" [(ngModel)]="selectedCutsceneType" inputId="3d">
          </p-radioButton>
        </div>
        <div class="p-col-6 field-radiobutton">
          <p-radioButton name="csgroup1" value="video" label="Video" [(ngModel)]="selectedCutsceneType" inputId="video">
          </p-radioButton>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedCutsceneType==='video'">
      <div class="p-grid">
        <div class="form-group p-col-fixed form-input-label">
          <label for="introvideo">Intro video</label>
        </div>
        <div class="p-col form-input-field">
          <ng-container *ngIf="courseModel.attributes.introVideo.data !== null">
            <span>
              {{ courseModel.attributes.introVideo.data?.attributes.name }}
              &nbsp;&nbsp;
            </span>
          </ng-container>
          <span><button pButton type="button" icon="fas fa-photo-video"
              [label]="courseModel.attributes.introVideo.data !== null ? 'Replace video' : 'Select video from library' "
              (click)="showMediaLibDialog('introVideo')"></button></span>&nbsp;
          <span *ngIf="courseModel.attributes.introVideo"><button class=" p-button-danger" pButton type="button"
              icon="fas fa-times" (click)="removeContent('introVideo')"></button></span>
          <br />
        </div>
      </div>

      <div class="p-grid">
        <div class="form-group p-col-fixed form-input-label">
          <label for="introvideo">Level 1 video</label>
        </div>
        <div class="p-col form-input-field">
          <ng-container *ngIf="courseModel.attributes.level1Video.data !== null">
            <span> {{ courseModel.attributes.level1Video.data?.attributes.name }} &nbsp;&nbsp;</span>
          </ng-container>
          <span><button pButton type="button" icon="fas fa-photo-video"
              [label]="courseModel.attributes.level1Video.data !== null ? 'Replace video' : 'Select video from library' "
              (click)="showMediaLibDialog('level1Video')"></button></span>&nbsp;
          <span *ngIf="courseModel.attributes.level1Video"><button class=" p-button-danger" pButton type="button"
              icon="fas fa-times" (click)="removeContent('level1Video')"></button></span>
          <br />
        </div>
      </div>

      <div class="p-grid">
        <div class="form-group p-col-fixed form-input-label">
          <label for="introvideo">Level 2 video</label>
        </div>
        <div class="p-col form-input-field">
          <ng-container *ngIf="courseModel.attributes.level2Video.data !== null">
            <span> {{ courseModel.attributes.level2Video.data?.attributes.name }} &nbsp;&nbsp;</span>
          </ng-container>
          <span><button pButton type="button" icon="fas fa-photo-video"
              [label]="courseModel.attributes.level2Video.data !== null ? 'Replace video' : 'Select video from library' "
              (click)="showMediaLibDialog('level2Video')"></button></span>&nbsp;
          <span *ngIf="courseModel.attributes.level2Video"><button class=" p-button-danger" pButton type="button"
              icon="fas fa-times" (click)="removeContent('level2Video')"></button></span>
          <br />
        </div>
      </div>

      <div class="p-grid">
        <div class="form-group p-col-fixed form-input-label">
          <label for="introvideo">Level 3 video</label>
        </div>
        <div class="p-col form-input-field">
          <ng-container *ngIf="courseModel.attributes.level3Video.data !== null">
            <span> {{ courseModel.attributes.level3Video.data?.attributes.name }} &nbsp;&nbsp;</span>
          </ng-container>
          <span><button pButton type="button" icon="fas fa-photo-video"
              [label]="courseModel.attributes.level3Video.data !== null ? 'Replace video' : 'Select video from library' "
              (click)="showMediaLibDialog('level3Video')"></button></span>&nbsp;
          <span *ngIf="courseModel.attributes.level3Video"><button class=" p-button-danger" pButton type="button"
              icon="fas fa-times" (click)="removeContent('level3Video')"></button></span>
          <br />
        </div>
      </div>

      <div *ngIf="cubeLevels>=4" class="p-grid">
        <div class="form-group p-col-fixed form-input-label">
          <label for="introvideo">Level 4 video</label>
        </div>
        <div class="p-col form-input-field">
          <ng-container *ngIf="courseModel.attributes.level4Video.data !== null">
            <span> {{ courseModel.attributes.level4Video.data?.attributes.name }} &nbsp;&nbsp;</span>
          </ng-container>
          <span><button pButton type="button" icon="fas fa-photo-video"
              [label]="courseModel.attributes.level4Video.data !== null ? 'Replace video' : 'Select video from library' "
              (click)="showMediaLibDialog('level4Video')"></button></span>&nbsp;
          <span *ngIf="courseModel.attributes.level1Video"><button class=" p-button-danger" pButton type="button"
              icon="fas fa-times" (click)="removeContent('level4Video')"></button></span>
          <br />
        </div>
      </div>
    </ng-container>

    <hr>
    <br/>

    <div class="p-grid">
      <div class=" p-col-fixed form-input-label">
        <label for="include">Startpage</label>
      </div>
      <div class="p-grid" style="width:250px;margin-bottom:10px">
        <div class="p-col-12">
          <p-checkbox name="include" [(ngModel)]="includeStartpage" [binary]="true" inputId="binary" label="Show startpage before cube"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="startpageMD">Startpage Markdown</label>
      </div>
      <div class="p-col form-input-field">
        <textarea name="startpageMD" id="startpageMD" autoResize="true" pInputTextarea
          [(ngModel)]="courseModel.attributes.startpageMarkdown" style="width: 100%;"></textarea>
      </div>
    </div>

    <div class="p-grid">
      <div class="form-group p-col-fixed form-input-label">
        <label for="startpageMDpreview">Preview</label><br />
        <a style="cursor: pointer;" (click)="showPreview=!showPreview">{{showPreview ? 'Hide' : 'Show'}}</a>
      </div>
      <div class="p-col" >
        <span style="width:fit-content;" *ngIf="showPreview" [innerHTML]="courseModel.attributes.startpageMarkdown | markdown"></span>
      </div>
    </div>

  </form>
</p-card>

<br>


<div class="right grouped-buttons">
  <button pButton type="cancel" label="Cancel" (click)="onCancel()" class="ui-button-secondar ui-button"></button>
  <button pButton type="" label="Save" (click)="onSave()" class="ui-button-secondar ui-button"></button>
  <button pButton type="submit" label="Export" (click)="onSubmit()" [disabled]="!form.form.valid"
    class="ui-button"></button>
</div>
<!-- <p-messages [(value)]="msgs"></p-messages> -->
