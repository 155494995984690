
import {
  Component,
  OnInit,
  AfterViewInit,
} from '@angular/core';
// import {
//   Level, NewApplication,
//   NewLevel, NewNugget, NewExam, NewAnswer, Answer, Nugget, ApplicationLevels, Course,
// } from '../swagger-api';
import { environment } from 'src/environments/environment';
import { Utilities } from '../utilities';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApplicationFormComponent } from '../application-form/application-form.component';
import {
  ConfirmationService, MenuItem, Message, MessageService, SelectItem, SelectItemGroup,
} from 'primeng/api';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { AppComponent } from '../app.component';
import { saveAs } from 'file-saver';
import { NavigationExtras, Router } from '@angular/router';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { BreadcrumbService } from '../breadcrumb.service';
import { ExportLevel, ExportAnswer, ExportApplication, ExportExam, ExportNugget, ExportFeed, ExportFeedInfo, } from '../export';
import { LANGUAGES } from '../utils/languages';
import { timer } from 'rxjs';
import { validate } from 'jsonschema';
import { APPLICATION_TRANSLATION_SCHEMA } from '../application_translation_schema';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  Application, ApplicationService, ApplicationResponseDataObject, CourseListResponse, CourseService, CustomerService,
  ApplicationRequest, CustomerListResponse, ApplicationResponse, ApplicationLevelsData, FeedResponse, FeedListResponse, FeedService, LevelRequest, LevelService,
  NuggetRequest, NuggetService, ExamRequest, ExamService, AnswerRequest, AnswerService, FeedRequest,
} from '../swagger-api-v4';
import { Course } from '../swagger-api-v3/model/course';


export interface NuggetTranslate {
  oldId: string,
  newId: string
}


@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class ApplicationsComponent implements OnInit, AfterViewInit {
  //  displayBasic = true;
  Util = Utilities;

  ref: DynamicDialogRef;
  applications: ApplicationResponseDataObject[] = [];
  selectedApplication: ApplicationResponseDataObject;
  importApp: ApplicationLevelsData;
  displayCopy = false;
  displayModal = false;
  displayMoveModal = false;
  displayUpload = false;
  cloneFinished = false;
  importFinished = false;

  cloneText = '';
  importText = '';
  msgs: Message[] = [];
  msgsImport: Message[] = [];

  locales: SelectItem[] = [];
  LANGUAGES = LANGUAGES;

  cloneTodo = 0;
  progressValue = 0;

  nuggetPairs: NuggetTranslate[] = [];
  resApp: Application;
  clonedFeeds = false;

  cloneAbortError = false;

  groupedCourses: SelectItemGroup[] = [];
  courses: SelectItem[] = [];
  selectedCourse: Course;

  courseId: number = 0;
  courseName: string;

  cols = [
    // { field: 'id', header: 'Id' },
    { field: 'title', header: 'Title', type: 'Text' },
    { field: 'description', header: 'Description', type: 'Text' },
    { field: 'locale', header: 'Language', type: 'Text', width: '4em' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },

  ];

  // { field: 'locale', header: 'Language', type: 'Text', width: '4em' },
  // { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
  // { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },
  constructor(
    public app: AppComponent,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private applicationService: ApplicationService,
    // private courseService: CourseService,
    // private customerService: CustomerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private answerService: AnswerService,
    private examService: ExamService,
    private levelService: LevelService,
    private nuggetService: NuggetService,
    private feedService: FeedService,
    public dialogService: DialogService,
    private http: HttpClient,

  ) {
    this.courseId = Number(localStorage.getItem('courseId'));
    this.courseName = localStorage.getItem('courseName');
  }

  ngOnInit(): void {
    this.refreshData();

    LANGUAGES.forEach((lan) => {
      const item: SelectItem = {
        label: lan.code.toUpperCase() + ' - ' + lan.name,
        value: lan.code.toUpperCase(),
      };
      this.locales.push(item);
    });
  }

  ngAfterViewInit(): void {
    const items: MenuItem[] = [
      { label: localStorage.getItem('customerName'), routerLink: '/customers' },
      { label: localStorage.getItem('courseName'), routerLink: '/courses' },
      { label: 'Application Languages' },
    ];
    this.breadcrumbService.setItems(items);
  }

  //  public applicationsGet(limit?: number, sort?: string, start?: number, op?: string, _in?: Array<string>, nin?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

  refreshData() {
    this.applications = [];
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('cms-server/courses?filters[id]=' + this.courseId + '&populate[applications][sort][0]=title',
      { headers: headers }).toPromise().then((courses: CourseListResponse) => {
        if (courses.data.length > 0) {
          this.applications = courses.data[0].attributes.applications.data.filter(app => app.attributes.isDeleted != true);
          console.log('Applications', this.applications);
        }
      }, (err) => {
        console.log('error', err);
      })
  }


  newApplication(event) {
    const newApplication: Application = {
      title: '',
      description: '',
      locale: 'EN',
      // course: { id: Number(this.uxSelections.courseId) },
    };
    const application: ApplicationResponseDataObject = { id: 0, attributes: newApplication };
    this.selectedApplication = { id: 0, attributes: newApplication };

    this.showEditDialog(application);
  }

  closeCopyApplication(event) {
    this.displayModal = false;
    this.refreshData();
  }

  copyApplication(event) {

    this.confirmationService.confirm({
      message:
        'Are you sure you want to clone "' +
        this.selectedApplication.attributes.title +
        // '" with ' +
        // this.selectedApplication.attributes.levels.data.length +
        // ' levels and language code ' +
        '" with language code ' + this.selectedApplication.attributes.locale +
        '?',
      accept: () => {
        this.msgs = [];
        this.displayModal = true;
        this.cloneFinished = false;
        this.cloneApplication();
      },
    });

    //this.displayCopy = true;
    // const newApplication: Application = {
    //   id: null,
    //   title: '',
    //   description: '',
    //   locale: '',
    // };
    // this.selectedApplication = newApplication;

    // this.showEditDialog(newApplication);
  }

  showEditDialog(application: ApplicationResponseDataObject) {
    this.ref = this.dialogService.open(ApplicationFormComponent, {
      header: application.id > 0 ? 'Edit Application' : 'New Application',
      width: '600px',
      data: {
        application: application,
        courseId: Number(this.courseId)
      },
    });

    this.ref.onClose.subscribe((app) => {
      if (app) {
        this.refreshData();
      }
    });
  }

  showError(msg) {
    this.messageService.add({ key: 'error', severity: 'error', summary: 'Error', detail: msg });
  }

  deleteApplication(application: ApplicationResponseDataObject) {
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, application.attributes.title),
      accept: () => {
        //const updateApplication:ApplicationRequest = this.cloneObjectAll({}, application);
        // updateApplication.data.isDeleted = true;
        let updateApplication: ApplicationRequest = { data: { isDeleted: true } };

        console.log('del', updateApplication, application);
        this.applicationService.putapplicationsid(updateApplication, application.id).toPromise().then(res => {
          console.log('res putapplicationsid', res);
          let index = this.applications.findIndex(
            (app) => app.id === application.id
          );
          if (index != -1) {
            this.applications.splice(index, 1);
          }
        }, (err) => {
          this.showError("Could not delete application!\n" + err.message);
        })

        // this.applicationService
        //   .applicationsIdDelete(application.id)
        //   .toPromise()
        //   .then(
        //     (ret) => {
        //       let index = this.applications.findIndex(
        //         (app) => app.id === application.id
        //       );
        //       if (index != -1) {
        //         this.applications.splice(index, 1);
        //       }
        //     },
        //     (err) => {
        //       console.log('error', err);
        //     }
        //   )
      },
    });
  }

  onRowSelect(event) {
    // console.log('selected ', event.data);
    // console.log('selected levels', event.data.levels);
    // console.dir(event.data.levels);
    // this.selectedApplication = event.data;
    // this.showEditDialog(event.data);
  }

  onRowDblClick(rowData) {
    this.gotoRoute(rowData);
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  // EXPORTING TO JSON FOR TRANSLATION

  exportFeeds(expApp: ExportApplication) {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

    // return this.http.get('/cms-server/feeds?populate[application][fields][0]=title&populate[application][filters][id][$eq]=' + expApp.id + '&sort=sortOrder', { headers: headers })
    return this.http.get('/cms-server/feeds?filters[application][id][$eq=' + expApp.id + '&sort=sortOrder', { headers: headers })
      .toPromise().then((feeds: FeedListResponse) => {
        //    return this.feedService.feedsGet(null, 'sortOrder').toPromise().then(feeds => {
        // let appFeeds = feeds.filter(feed => Number(feed.application.id) === expApp.id); //TODO : should both be number
        console.log('Feeds', feeds);
        // let appFeeds = feeds.data.filter(feed => feed.attributes.application.data !== null);
        // console.log('AppFeeds', appFeeds);

        let expFeeds: ExportFeed[] = [];
        feeds.data.forEach(feed => {
          let aFeedInfos: ExportFeedInfo[] = [];

          if (feed.attributes.examtype === 'question' || feed.attributes.examtype === 'imagemap') {
            feed.attributes.info.forEach(info => {
              let aFeedInfo: ExportFeedInfo = {
                sortOrder: info.sortOrder,
                statement: info.statement,
                response: info.response
              };
              aFeedInfos.push(aFeedInfo);
            });
          } else { // 'order'
            feed.attributes.info.forEach(info => {
              let aFeedInfo: ExportFeedInfo = {
                sortOrder: info.sortOrder,
                statement: info.statement,
              };
              aFeedInfos.push(aFeedInfo);
            })
          }

          let aFeed: ExportFeed = {
            id: feed.id,
            title: feed.attributes.title,
            details: feed.attributes.details,
            info: aFeedInfos
          }
          if (feed.attributes.examtype === 'order') {
            aFeed.correct = feed.attributes.correct === null ? '' : feed.attributes.correct;
            aFeed.incorrect = feed.attributes.incorrect === null ? '' : feed.attributes.incorrect;
          }

          expFeeds.push(aFeed);
        })
        expApp.feeds = expFeeds;
        return expApp;
      })
  }

  saveExport(expApp: ExportApplication) {
    this.exportFeeds(expApp).then(expApp => {

      const text = JSON.stringify(expApp, null, 2);
      const blob = new Blob([text], { type: 'application/json;' });
      console.log('Export\n', JSON.stringify(expApp, null, 20));
      // console.log('Export', JSON.stringify(expApp, null, 1));
      const filename = localStorage.getItem('customerName') + '-' + expApp.title + '-' + expApp.locale + '_' + new Date().toLocaleDateString();
      saveAs(blob, filename);
    });
  }

  cloneObject(fromObj, toObj) {
    Object.keys(toObj).forEach((key) => (toObj[key] = fromObj[key]));
  }

  cloneObjectAll(fromObj, toObj) {
    return Object.assign(toObj, fromObj);
  }


  setProgress(total: number, current: number) {
    this.progressValue = Math.round((current * 100) / total);
    console.log('total: ', total, 'current', current, 'progressValue', this.progressValue);
  }


  async cloneApplication() {
    this.progressValue = 0;
    this.cloneTodo = 0;
    this.clonedFeeds = false;
    this.cloneAbortError = false;
    this.nuggetPairs.length = 0;


    let cloned = 0;
    let total = 1;

    this.cloneText = this.selectedApplication.attributes.title;

    try {

      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

      const application = await this.http.get<ApplicationResponse>('/cms-server/applications/' + this.selectedApplication.id + '?[populate][levels][populate][nuggets][populate][exams][populate]=answers&populate[levels][populate][nuggets][populate]=content&sort[0]=levels.sortOrder&sort][1]=nuggets.sortOrder', { headers: headers }).toPromise(); // .then((application: ApplicationResponse) => {
      console.log('Cloning application', this.selectedApplication.id, this.selectedApplication.attributes.title);
      console.log('application', application);

      const feeds = await this.http.get<FeedListResponse>('/cms-server/feeds?filters[application][id][$eq]=' + this.selectedApplication.id + '&sort=sortOrder&populate=*&pagination[limit]=1000', { headers: headers }).toPromise(); //.then((feeds: FeedListResponse) => {
      console.log('feeds for app', feeds);

      // Calculate how many items to copy for progressbar
      total += feeds.data.length;
      application.data.attributes.levels.data.forEach(level => {
        if (level.attributes.isDeleted != true) {
          total++;
          level.attributes.nuggets.data.forEach(nugget => {
            if (nugget.attributes.isDeleted != true) {
              total++;
              nugget.attributes.exams.data.forEach(exam => {
                total++;
                total += exam.attributes.answers.data.length;
              })
            }
          })
        }
      })
      console.log('total', total);
      this.setProgress(total, cloned);


      let applicationRequest: ApplicationRequest = {
        data: {
          title: application.data.attributes.title + ' *CLONE*',
          description: application.data.attributes.description,
          locale: '--',
          course: this.courseId
        },
      };
      console.log('applicationRequest', applicationRequest);
      const newApplication = await this.applicationService.postapplications(applicationRequest).toPromise();
      console.log('newApplication', newApplication);

      const levelIds = [];
      const levels: ApplicationLevelsData[] = application.data.attributes.levels?.data.filter(level => level.attributes.isDeleted != true);
      for (let level of levels) {
        this.cloneText = "Level: " + level.attributes.title;
        console.log('level ', level);
        const nuggetIds = [];
        const nuggets = level.attributes.nuggets?.data.filter(nugget => nugget.attributes.isDeleted != true);
        for (let nugget of nuggets) {
          this.cloneText = "Level: " + level.attributes.title + ", Nugget: " + nugget.attributes.title;
          console.log('nugget ', nugget, nuggetIds);
          const examsIds = [];
          for (let exam of nugget.attributes.exams?.data) {
            console.log('exam ', exam, examsIds);
            this.cloneText = "Level: " + level.attributes.title + ", Nugget: " + nugget.attributes.title + ", Exam: " + exam.attributes.question;
            const answerIds = [];
            const noOfAnswers = exam.attributes.answers.data.length;
            let idx = 1;
            for (let answer of exam.attributes.answers?.data) {
              this.cloneText = "Level: " + level.attributes.title + ", Nugget: " + nugget.attributes.title + ", Exam: " + exam.attributes.question + " " + (idx++) + "/" + noOfAnswers;
              console.log('answer ', answer, answerIds);
              const answerRequest: AnswerRequest = {
                data: {
                  answer: answer.attributes.answer,
                  correct: answer.attributes.correct,
                  response: answer.attributes.response
                }
              };
              console.log('answerRequest', answerRequest);
              const newAnswer = await this.answerService.postanswers(answerRequest).toPromise();
              console.log('newAnswer', newAnswer);
              answerIds.push(newAnswer.data.id);
              this.setProgress(total, ++cloned);
            }
            const examRequest: ExamRequest = {
              data: {
                question: exam.attributes.question,
                answers: answerIds,
                correctAnswers: exam.attributes.correctAnswers,
                nugget: nugget.id
              }
            };
            console.log('examRequest', examRequest);
            const newExam = await this.examService.postexams(examRequest).toPromise();
            console.log('newExam', newExam);
            examsIds.push(newExam.data.id);
            this.setProgress(total, ++cloned);

          }
          const nuggetRequest: NuggetRequest = {
            data: {
              title: nugget.attributes.title,
              description: nugget.attributes.description,
              learningTime: nugget.attributes.learningTime,
              content: nugget.attributes.content?.data?.map(content => content.id),
              exams: examsIds,
              sortOrder: nugget.attributes.sortOrder,
              iconNr: nugget.attributes.iconNr,
              keywords: nugget.attributes.keywords,
              isDeleted: nugget.attributes.isDeleted,
              feeds: []
            }
          }
          console.log('nuggetRequest', nuggetRequest);
          const newNugget = await this.nuggetService.postnuggets(nuggetRequest).toPromise();
          console.log('newNugget', newNugget);
          nuggetIds.push(newNugget.data.id);
          this.setProgress(total, ++cloned);
        }

        const levelRequest: LevelRequest = {
          data: {
            title: level.attributes.title,
            description: level.attributes.description,
            nuggets: nuggetIds,
            sortOrder: level.attributes.sortOrder,
            isDeleted: level.attributes.isDeleted
          }
        }
        console.log('levelRequest', levelRequest);
        const newLevel = await this.levelService.postlevels(levelRequest).toPromise();
        console.log('newLevel', newLevel);
        levelIds.push(newLevel.data.id);
        this.setProgress(total, ++cloned);
      }
      const feedIds = [];
      for (let feed of feeds.data) {
        console.log('feed', feed);
        const feedRequest: FeedRequest = {
          data: {
            title: feed.attributes.title,
            details: feed.attributes.details,
            correct: feed.attributes.correct,
            incorrect: feed.attributes.incorrect,
            sortOrder: feed.attributes.sortOrder,
            image: feed.attributes.image?.data?.id,
            thumbnail: feed.attributes.thumbnail?.data?.id,
            examtype: feed.attributes.examtype,
            info: feed.attributes.info,
            nuggets: feed.attributes.nuggets?.data.map(nugget => nugget.id),
            application: newApplication.data.id,  // NEW APP ID,
            furtherInfo: feed.attributes.furtherInfo?.data?.id,
            furtherInfoText: feed.attributes.furtherInfoText,
          }
        }
        this.cloneText = applicationRequest.data.title + ", Feed: " + feed.attributes.title;
        console.log('feedRequest', feedRequest);
        const newFeed = await this.feedService.postfeeds(feedRequest).toPromise();
        console.log('newFeed', newFeed);
        feedIds.push(newFeed.data.id)
        this.setProgress(total, ++cloned);

      }

      // WRITE application (update)
      const putApplication: ApplicationRequest = {
        data: {
          levels: levelIds
        }
      }
      console.log('putApplication', putApplication)
      const updatedApplication = await this.applicationService.putapplicationsid(putApplication, newApplication.data.id).toPromise();
      console.log('updatedApplication', updatedApplication);

      this.setProgress(total, ++cloned);

      this.cloneCompleted();
    } catch (err) {
      console.error(err);
    }
  }


  cloneCompleted(error?: HttpErrorResponse) {
    console.log('cloneCompleted');
    if (error) {
      this.cloneAbortError = true;
      console.error('Clone Error:', error);
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Error during cloning : ' + error.message + ' ' + error.status + ':' + error.statusText });
    } else {
      // console.log('1cloneTodo:', this.cloneTodo);
      timer(2000).subscribe((x) => {
        this.cloneFinished = true;
        if (error === undefined) {
          this.msgs = [{ severity: 'success', summary: 'Success', detail: 'Cloning completed' }];
        }
      });
    }
  }

  onOpenFeeds(rowData) {
    console.log('onOpenFeeds', rowData);
    localStorage.setItem('applicationId', rowData.id);
    localStorage.setItem('applicationName', rowData.attributes.title + ' - ' + rowData.attributes.locale,);
    this.router.navigate(['/feeds']);
  }

  onOpenFileUpload(rowData) {
    this.importApp = rowData;
    this.msgsImport = [];
    this.importFinished = true;
    this.displayUpload = true;
  }

  myUploader(event, form) {
    this.msgsImport = [];
    this.importFinished = false;
    console.log('Upload Translation', event);

    let fileReader = new FileReader();
    event.files.forEach((file: File) => {
      file.arrayBuffer().then(data => {
        var enc = new TextDecoder("utf-8");
        const translation = enc.decode(data);
        console.log('First 300 characters of file :\n' + translation.substr(1, 300));
        form.clear();
        let json = null;
        try {
          json = JSON.parse(translation);
        } catch (error) {
          console.log('Translation JSON parse error', error);
          this.addImportErrorMsgs('Parse error - json file is invalid! ' + error.message);
          this.importFinished = true;
          return;
        }
        const validation = validate(json, APPLICATION_TRANSLATION_SCHEMA);
        console.log(validation);
        validation.errors.forEach(err => {
          this.msgsImport.push({ severity: 'error', summary: err.instance, detail: err.stack });
        });
        if (validation.valid) {
          this.importJSON(json);
        } else {
          this.importFinished = true;
        }
      });
    });
  }


  addImportErrorMsgs(errorText: string) {
    console.log('Import Error : ', errorText);
    this.msgsImport.push({ severity: 'error', summary: 'Error', detail: errorText });
  }

  completedImport() {
    this.importFinished = true;
    if (this.msgsImport.length === 0) {
      this.msgsImport.push({ severity: 'success', summary: 'Success', detail: 'Translation imported!' });
    }
  }

  async importJSON(json: ExportApplication) {
    let total = 1;

    console.log('importJSON:', json);
    console.log('this.importApp:', this.importApp);

    this.importText = 'Application Id ' + json.id;

    if (json.id !== this.importApp.id) {
      this.addImportErrorMsgs('Application Id do not match! ' + json.id + " =/= " + this.importApp.id);
      this.importFinished = true;
      return;
    }
    if (json.locale !== this.importApp.attributes.locale) {
      this.addImportErrorMsgs('Application Locale do not match! ' + json.locale + " =/= " + this.importApp.attributes.locale);
      this.importFinished = true;
      return;
    }

    // this.importApp.title = json.title;
    // this.importApp.description = json.description;

    const applicationRequest: ApplicationRequest = {
      data: {
        title: json.title,
        description: json.description,
      }
    }

    const updateApp = this.cloneObjectAll({}, this.importApp);
    console.log('updateApp', updateApp);
    const res = await this.applicationService.putapplicationsid(applicationRequest, this.importApp.id).toPromise();
    // // this.applicationService.applicationsIdPut(updateApp, this.importApp.id).toPromise().then(res => {
    // const res = await this.applicationService.applicationsIdPut(updateApp, this.importApp.id).toPromise();
    total--;
    console.log('Application Id ' + this.importApp.id);
    total += json.levels.length;
    let totLevel = json.levels.length;
    // json.levels.forEach(level => {
    for (const level of json.levels) {
      console.log('level', level);
      // const theApp = await this.applicationService.getapplicationsid(this.importApp.id).toPromise();
      // console.log('theApp', theApp);
      // const foundLevel = theApp.data.attributes.levels.data.find(lev => lev.id === level.id);
      // console.log('foundLevel', foundLevel);
      // if (foundLevel === undefined) {
      //   this.addImportErrorMsgs('Level Id :' + level.id + ' do not exist for this application!');
      // } else {
      const levelRequest: LevelRequest = {
        data: {
          description: level.description,
          title: level.title,
        }
      }
      const res = await this.levelService.putlevelsid(levelRequest, level.id).toPromise();
      //  const res = await this.levelService.levelsIdPut(updateLevel, foundLevel.id).toPromise();

      this.importText = 'Level Id ' + level.id + ' ' + level.title;
      console.log(this.importText);
      //     // Read Level to get Nuggets
      //     // this.levelService.levelsIdGet(foundLevel.id).toPromise().then(appLevel => {
      //     const appLevel = await this.levelService.levelsIdGet(foundLevel.id).toPromise();
      //     total--;
      //     total += level.nuggets.length;
      //     // level.nuggets.forEach(nugget => {
      for (const nugget of level.nuggets) {
        //       const foundNugget = appLevel.nuggets.find(nug => nug.id === nugget.id);
        //       if (foundNugget === undefined) {
        //         this.addImportErrorMsgs('Nugget Id :' + nugget.id + ' do not exist for this application!');
        //       } else {
        //         const updateNugget = this.cloneObjectAll({}, foundNugget);
        //         updateNugget.title = nugget.title;
        //         updateNugget.description = nugget.description;
        //         updateNugget.keywords = nugget.keywords;
        const nuggetRequest: NuggetRequest = {
          data: {
            title: nugget.title,
            description: nugget.description,
            keywords: nugget.keywords,
          }
        }
        //         // this.nuggetService.nuggetsIdPut(updateNugget, foundNugget.id).toPromise().then(res => {
        //         const nugres = await this.nuggetService.nuggetsIdPut(updateNugget, foundNugget.id).toPromise();
        await this.nuggetService.putnuggetsid(nuggetRequest, nugget.id).toPromise();
        this.importText = 'Level Id ' + level.id + ' Nugget Id ' + nugget.id + ' ' + nugget.title;
        console.log(this.importText);


        //         // this.nuggetService.nuggetsIdGet(foundNugget.id).toPromise().then(appNugget => {
        //         const appNugget = await this.nuggetService.nuggetsIdGet(foundNugget.id).toPromise();
        //         total--;
        //         total += nugget.exams.length;
        //         // nugget.exams.forEach(exam => {
        for (const exam of nugget.exams) {
          //           const foundExam = appNugget.exams.find(ex => ex.id === exam.id);
          //           if (foundExam === undefined) {
          //             this.addImportErrorMsgs('Exam Id :' + exam.id + ' do not exist for this application!');
          //           } else {
          //             const updateExam = this.cloneObjectAll({}, foundExam);
          //             updateExam.question = exam.question;
          const examRequest: ExamRequest = {
            data: {
              question: exam.question
            }
          }
          await this.examService.putexamsid(examRequest, exam.id).toPromise();
          //             // this.examService.examsIdPut(updateExam, foundExam.id).toPromise().then(res => {
          //             const resExamUpd = this.examService.examsIdPut(updateExam, foundExam.id).toPromise();
          this.importText = 'Level Id ' + level.id + ' Nugget Id ' + nugget.id + ' Exam Id ' + exam.id + ' ' + exam.question;
          console.log(this.importText);

          //             // this.examService.examsIdGet(foundExam.id).toPromise().then(appExam => {
          //             const appExam = await this.examService.examsIdGet(foundExam.id).toPromise();
          //             total--;
          //             total += exam.answers.length;
          //             let totAnswers = exam.answers.length;
          //             // exam.answers.forEach(answ => {
          for (const answer of exam.answers) {
            const answerRequest: AnswerRequest = {
              data: {
                answer: answer.answer
              }
            }
            await this.answerService.putanswersid(answerRequest, answer.id).toPromise();
            //               const foundAnswer = appExam.answers.find(ans => ans.id === answ.id);
            //               if (foundAnswer === undefined) {
            //                 this.addImportErrorMsgs('Answer Id :' + answ.id + ' do not exist for this application!');
            //                 if (--total === 0) this.completedImport();
            //               } else {
            //                 const updateAnswer = this.cloneObjectAll({}, foundAnswer);
            //                 updateAnswer.answer = answ.answer;
            //                 // this.answerService.answersIdPut(updateAnswer, answ.id).toPromise().then(res => {
            //                 const res = await this.answerService.answersIdPut(updateAnswer, answ.id).toPromise();
            this.importText = 'Level Id ' + level.id + ' Nugget Id ' + nugget.id + ' Exam Id ' + exam.id + ' Answer Id ' + answer.id;
            //                 console.log(this.importText);
            //                 if (--total === 0) this.completedImport();
            //                 // }, (err) => {
            //                 //   this.addImportErrorMsgs('Could not update answer ' + foundAnswer.id);
            //                 //   if (--total === 0) this.completedImport();
            //                 // })
            //               }
            //             } // for each answer
            //             // }, (err) => {
            //             //   this.addImportErrorMsgs('Could not read exam ' + foundExam.id);
            //             // })

          }
          //           // , (err) => {
          //           //   this.addImportErrorMsgs('Could not update exam ' + foundExam.id);
          //           //   if (--total === 0) this.completedImport();
          //           // })
          //           // }
          //         } // for each exam
          //         // }, (err) => {
          //         //   this.addImportErrorMsgs('Could not read nugget ' + foundNugget.id);
          //         // })
          //         // }, (err) => {
          //         //   this.addImportErrorMsgs('Could not update nugget ' + foundNugget.id);
          //         //   if (--total === 0) this.completedImport();
          //         // })
          //         //          } // get nugget
          //       } // else found nugget
          //       // });
          //     } // for each nugget

          //     // }, (err) => {
          //     //   this.addImportErrorMsgs('Could not read level ' + foundLevel.id);
          //     // })
          //     // }, (err) => {
          //     //   this.addImportErrorMsgs('Could not update application! ' + err.message);
          //     //   if (--total === 0) this.completedImport();
          //     // })
          //   } // else found level
          //   //   });
          //   // }, (err) => {
          //   //   this.addImportErrorMsgs('Could not update application! ' + err.message);
          //   //   this.completedImport();
          //   // })
          // } // foreach level


          if (json?.feeds.length > 0) {
            //   // this.feedService.feedsGet(null, 'sortOrder').toPromise().then(feeds => {
            //   const feeds = await this.feedService.feedsGet(null, 'sortOrder').toPromise();
            //   let appFeeds = feeds.filter(feed => feed.application.id === this.importApp.id);
            //   // let impFeeds: ExportFeed[] = [];
            //   total += json.feeds.length;
            //   // json.feeds.forEach(feed => {
            for (const feed of json.feeds) {
              this.importText = 'Feed Id ' + feed.id + ' - ' + feed.title;
              //     console.log(this.importText);
              //     let foundFeed = appFeeds.find(f => f.id === feed.id);
              //     if (foundFeed !== undefined) {
              //       foundFeed.title = feed.title;
              //       foundFeed.details = feed.details;
              //       foundFeed.correct = feed?.correct;
              //       foundFeed.incorrect = feed?.incorrect;
              //       foundFeed.info = feed.info;
              const feedRequest: FeedRequest = {
                data: {
                  title: feed.title,
                  details: feed.details,
                  info: feed.info
                }
              }
              await this.feedService.putfeedsid(feedRequest, feed.id).toPromise();


              //       // this.feedService.feedsIdPut(foundFeed, foundFeed.id).toPromise().then(res => {
              //       const res = await this.feedService.feedsIdPut(foundFeed, foundFeed.id).toPromise();
              //       if (--total === 0) this.completedImport();
              //       // }, (err) => {
              //       //   this.addImportErrorMsgs('Could not update feed ' + feed.id);
              //       //   if (--total === 0) this.completedImport();
              //       // })
              //       // } else {
              //       //   this.addImportErrorMsgs('Feed Id :' + feed.id + ' do not exist for this application!');
              //       //   if (--total === 0) this.completedImport();
              //       // }
              //       // })
              //       // })
            }
            //   } // for each
          } // if
        }
      }
    }
    this.completedImport();
  }


  downloadApplicationTexts(app: ApplicationResponseDataObject) {
    const expApplication: ExportApplication = {
      id: app.id,
      locale: app.attributes.locale,
      language: LANGUAGES.find((lang) => {
        return (
          lang.code.toUpperCase().localeCompare(app.attributes.locale.toUpperCase()) === 0
        );
      })?.name,
      translationInfo:
        "All text strings right of the colon on the rows below this shall be translated to the correct language. For example not the word 'title', but '" +
        app.attributes.title +
        "' shall be translated.",
      title: app.attributes.title,
      description: app.attributes.description,
      levels: [],
    };
    console.log('expApplication', expApplication);
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/applications/' + app.id + '?[populate][levels][populate][nuggets][populate][exams][populate]=answers&sort[0]=levels.sortOrder&sort][1]=nuggets.sortOrder', { headers: headers })
      .toPromise().then((application: ApplicationResponse) => {
        console.log('Export application', application);
        application.data.attributes.levels.data.forEach(level => {
          if (level.attributes.isDeleted != true) {
            console.log('Export level', level.id);
            let exportLevel = this.convertToExportFormat(level);
            console.log('exportLevel', exportLevel);
            expApplication.levels.unshift(exportLevel);
          }
        })
        console.log('DONE expApplication', expApplication);
        this.saveExport(expApplication);
      })

  }

  convertToExportFormat(level: ApplicationLevelsData): ExportLevel {
    let expLevel: ExportLevel;

    expLevel = {
      id: level.id,
      title: level.attributes.title,
      description: level.attributes.description,
      nuggets: [],
    };
    level.attributes.nuggets.data.forEach((nug) => {
      let expNugget: ExportNugget = {
        id: nug.id,
        title: nug.attributes.title,
        description: nug.attributes.description,
        keywords: nug.attributes.keywords === null ? '' : nug.attributes.keywords,
        exams: [],
      };
      expLevel.nuggets.push(expNugget);
      nug.attributes.exams.data.forEach((exam) => {
        let exportExam: ExportExam = {
          id: exam.id,
          question: exam.attributes.question,
          answers: [],
        };
        expNugget.exams.push(exportExam);
        exam.attributes.answers.data.forEach((answ) => {
          let exportAnswer: ExportAnswer = {
            id: answ.id,
            answer: answ.attributes.answer,
          };
          exportExam.answers.push(exportAnswer);
        });
      });
    });

    return expLevel;
  }

  gotoRoute(rowData) {
    localStorage.setItem('applicationId', rowData.id);
    localStorage.setItem('applicationName', rowData.attributes.title + ' - ' + rowData.attributes.locale,);
    // environment.cubeApplicationId = rowData.id;
    this.router.navigate(['/levels']);
    // this.router.navigate(['/levels'], { queryParams: { applicationId: rowData.id } });
  }


  showMoveApplication() {
    this.groupedCourses = [];
    this.displayMoveModal = true;
    this.courses = [];
    // this.customerService.getcustomers('name', false, null, null, null, -1, null, 'courses').toPromise().then((customers: CustomerListResponse) => {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/customers?populate[courses][populate][0]=applications',
      { headers: headers }).toPromise().then((customers: CustomerListResponse) => {
        const cs = customers.data.filter(c => c.attributes.isDeleted !== true);
        cs.forEach(cu => {
          if (cu.attributes.courses.data.length > 0) {
            const itemsCourses = [];
            cu.attributes.courses.data.forEach(co => {
              if (co.attributes.isDeleted !== true && co.id !== this.courseId) {
                itemsCourses.push({ label: co.attributes.name, value: co })
                this.courses.push({ label: cu.attributes.name + ' - ' + co.attributes.name, value: co }); // THIS IS IN USE FOR PRIMENG 9
              }
            })
            this.groupedCourses.push({
              label: cu.attributes.name, value: cu.id,
              items: itemsCourses
            });
          }
        })
        this.courses= [...this.courses];
      })
  }

  onMove() {
    console.log('this.courseId', this.courseId,' move to courseId',this.selectedCourse.id);
    const updApp: ApplicationRequest = { data: { course: this.selectedCourse.id } };
    this.applicationService.putapplicationsid(updApp, this.selectedApplication.id).toPromise().then(app => {
      this.refreshData();
      this.displayMoveModal = false;
    }, (err) => {
      console.log('error when moving', err);
    })
  }

  onMoveCancel() {
    this.displayMoveModal = false;
  }

}
