import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
// import { Level, NewLevel, Application, NewApplication, ApplicationLevels } from '../swagger-api';
import { Utilities } from '../utilities';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { LevelFormComponent } from '../levels-form/level-form.component';
import { MessageService, ConfirmationService, MenuItem } from 'primeng/api';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BreadcrumbService } from '../breadcrumb.service';
import { ApplicationListResponse, ApplicationListResponseDataItem, ApplicationService, Level, LevelRequest, LevelResponseDataObject, LevelService } from '../swagger-api-v4';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss'],
  providers: [DialogService, ConfirmationService]
})

export class LevelsComponent implements OnInit, AfterViewInit {

  Util = Utilities;

  levelModel: LevelResponseDataObject = { id:0, attributes : { title: '', description: '', nuggets: {} }};
  application: ApplicationListResponseDataItem;
  levels: LevelResponseDataObject[] = [];
  selectedLevel: LevelResponseDataObject;
  ref: DynamicDialogRef;

  cols = [
    { field: 'title', header: 'Title' },
    { field: 'description', header: 'Description' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' }
  ];

  applicationId: number = 0;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private confirmationService: ConfirmationService,
    private levelService: LevelService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private http: HttpClient,
  ) {
    this.applicationId = Number(localStorage.getItem('applicationId'));
  }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('cms-server/applications?filters[id]=' + this.applicationId + '&populate=*',
      { headers: headers }).toPromise().then((applications: ApplicationListResponse) => {
        if (applications.data.length > 0) {
          this.application = applications.data[0];
          this.levels = applications.data[0].attributes.levels.data.filter(level => level.attributes.isDeleted !== true);
        }
        this.levels.sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder)
        console.log('Levels', this.levels);
      },
        err => { console.log(err); }
      );
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    const items: MenuItem[] = [
      { label: localStorage.getItem('customerName'), routerLink: '/customers' },
      { label: localStorage.getItem('courseName'), routerLink: '/courses' },
      { label: localStorage.getItem('applicationName'), routerLink: '/applications' },
      { label: 'Levels' },
    ];
    this.breadcrumbService.setItems(items);
  }


  newLevel(event) {
    let sortOrder = 0;
    if (this.levels.length > 0) {
      sortOrder = this.levels?.reduce((prev, current) => {
        return (prev.attributes.sortOrder > current.attributes.sortOrder) ? prev : current
      }).attributes.sortOrder + 1;
    }
    const newLevel: LevelResponseDataObject = {
      id: 0,
      attributes: {
        title: '',
        description: '',
        sortOrder: sortOrder,
        nuggets: { data: [] }
      }
    }

    this.showEditDialog(newLevel);

  }

  formatDate(date) {
    return Utilities.formatDate(date);
  }

  showEditDialog(level: LevelResponseDataObject) {
    this.ref = this.dialogService.open(LevelFormComponent, {
      header: level.id > 0 ? 'Edit Level' : 'New Level',
      width: '600px',
      data: {
        level: level,
        application: this.application
      }
    });


    this.ref.onClose.subscribe((level) => {
      if (level) {
        // this.onRowReorder(null);
        this.refreshData();
      }
    });
  }

  deleteLevel(level: LevelResponseDataObject) {
    console.log('deleteLevel');
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, level.attributes.title),
      accept: () => {
        this.levelService.deletelevelsid(Number(level.id)).toPromise().then(
          ret => {
            let index = this.levels.findIndex(lvl => lvl.id === Number(level.id));
            if (index != -1) {
              this.levels.splice(index, 1);
            }
          },
          err => {
            console.log('error', err);
          }
        )
      }
    });
  }

  onRowReorder(event) {
    // console.log('reorder', event);
    this.levels.forEach((lev, idx) => {
      let levelRequest: LevelRequest = { data: { sortOrder: idx } };
      this.levelService.putlevelsid(levelRequest, lev.id).toPromise().then(
        updatedLevel => {
          // console.log('Updated Level sortorder', updatedLevel);
        },
        err => {
          this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
        }
      )
      console.log(idx, lev.id, lev.attributes.title, lev.attributes.sortOrder);
    });
  }

  gotoRoute(rowData) {
    localStorage.setItem('levelId', rowData.id);
    localStorage.setItem('levelName', rowData.attributes.title);
    this.router.navigate(['/nuggets']);
  }


}
