import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UsersPermissionsUser } from 'resources/swagger-api-v4';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsersPermissionsUsersRolesService } from './swagger-api-v4';
@Injectable({
  providedIn: 'root'
})
export class AuthStrapiGuard {

  constructor(
    // private authStrapiService: AuthStrapiService,
    private usersPermissionsUsersRolesService: UsersPermissionsUsersRolesService,
    private http: HttpClient,
    private route: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    environment.cmsAccessToken = localStorage.getItem('jwt');
    this.usersPermissionsUsersRolesService.configuration.accessToken = environment.cmsAccessToken;
    if (environment.cmsAccessToken === undefined || environment.cmsAccessToken === null) {
      console.error('Not authenticated');
      environment.cmsAccessToken = null;
      environment.user = null;
      this.route.navigate(['/welcome']);
      return false;
    } else {
      // return this.usersPermissionsUsersRolesService.usersMeGet().toPromise().then(user => {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
      return this.http.get('/cms-server/users/me?populate[customers][sort][0]=name&populate[customer][fields]=name&populate=role', { headers: headers }).toPromise().then((user: UsersPermissionsUser) => {
        console.log('auth user', user);
        if (user.blocked || !user.confirmed) {
          console.log('user is blocked or not confirmed');
          environment.cmsAccessToken = null;
          environment.user = null;
          this.route.navigate(['/welcome']);
          return false;
        }
        environment.user = user;
        return true;
      }, (err) => {
        console.error('error when verifying user token', err);
        environment.cmsAccessToken = null;
        environment.user = null;
        this.route.navigate(['/welcome']);
        return false;
      })
    }
    console.log('authenticated', environment.cmsAccessToken);
    return true;
  }

}
