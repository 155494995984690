import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { UsersPermissionsUsersRolesService , UsersPermissionsAuthService} from '../swagger-api-v4';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild("usernameInputBox") usernameInputBox: ElementRef;

  username = ''; // 'testuser';
  password = ''; // 'password';
  error = false;
  errorSuper = false;
  signInDisabled = false;

  items: MenuItem[] = [{ label: 'Welcome' }];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private userService: UsersPermissionsUsersRolesService,
    private userAuth: UsersPermissionsAuthService,
    public route: Router) {
    this.breadcrumbService.setItems(this.items);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.error = false;
    this.errorSuper = false;
    this.signInDisabled = false;
    this.usernameInputBox.nativeElement.focus();
  }

  signIn() {
    this.signInDisabled = true;
    // const body :any = { email: this.username, url:'http://localhost:4200/#/reset-password' };
    const body :any = { identifier: this.username };
    console.log('body', body);
    this.userAuth.authForgotPasswordPost(body).toPromise().then(
      newuser => {
        console.log('user save post', newuser);
        //  this.messageService.add({ severity: 'success', summary: 'Saved', detail: newuser.username });
      }, (err) => {
        console.log('forgot password error', err);
      }
    )

  }
}
