import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DataView } from 'primeng/dataview';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Nugget } from '../swagger-api-v4';
import { Setting } from '../swagger-api-v4/model/setting';
import { FeedInfo } from 'resources/swagger-api-v4';
import { Feed } from '../swagger-api-v4/model/feed';
import { Utilities } from '../utilities';


interface QuizAnswers {
  quizId: string,
  answers: string[],
  correct: boolean
}
interface SettingQuizes {
  quizIds: string[];
  results: QuizAnswers[]
}

interface Answer {
  id: number;
  answer: string;
  correct?: boolean;
}

interface FeedView extends Feed {
  completed?: boolean;
  result?: string[];
}



@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  host: {
    '(window:resize)': 'resizeMaps()'
  }
})
export class NewsFeedComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dv') dv: DataView;
  @ViewChild('clickimage') clickimage: any;
  @ViewChild('clickmap') clickmap: any;
  @Input() previewMode = true;
  @Input() previewFeed: Feed = null;

  sub: PushSubscription;
  subError = '';

  imageUrl: SafeUrl;
  showImage = false;

  videoUrl: SafeUrl;
  videoSubtitles: SafeUrl;
  showVideo = false;

  pdfUrl: SafeUrl;
  showPdf = false;

  educationUrl: SafeUrl;
  showEducation = false;

  show3Dnugget = false;

  isEnabled = false;
  isGranted = false; //Notification.permission === 'granted';

  imagemapCoords = [];
  // news: NewsCard[] = [
  //   {
  responsiveOptions: any[];
  selectedFeed: FeedView;
  multipleCorrects = false;
  selectedAnswers: any;
  showCorrectAnswerText = false;
  showWrongAnswerText = false;
  showingOriginalOrder = true;

  showIntro = true;
  showQuiz = true;

  nuggetToDisplay: Nugget;
  showNugget = false;

  setting: Setting;
  settingSubscription: Subscription;
  configSubscription: Subscription;
  systemSettingStoreSubscription: Subscription;
  feedSubscription: Subscription;

  disableNext = false;

  showQuizLink1 = false;
  showQuizLink2 = false;
  showQuizLink3 = false;
  showQuizLink4 = false;
  showQuizLink5 = false;

  showFurtherInformation = false;

  setShowQuizLink1() {
    // console.log('quizlink1');
    this.showQuizLink1 = true;
  }
  setShowQuizLink2() {
    // console.log('quizlink2');
    this.showQuizLink2 = true;
  }
  setShowQuizLink3() {
    // console.log('quizlink3');
    this.showQuizLink3 = true;
  }
  setShowQuizLink4() {
    // console.log('quizlink4');
    this.showQuizLink4 = true;
  }
  setShowQuizLink5() {
    // console.log('quizlink5');
    this.showQuizLink5 = true;
  }

  allFeeds: FeedView[] = [];
  feeds: FeedView[] = [];
  showCheckmark = [];

  correctOrder = false;

  furtherInfoNugget: Nugget = {};

  applicationId = 0;
  connection = '';

  Util = Utilities;

  constructor(
    // private systemSettingStore: Store<{ systemSettings: Setting[] }>,
  ) {
    console.log('constructor of news-feed ', this.previewMode);

    // if (!this.authStrapiService.loggedInSuper) {
    //   authStrapiService.signInSuperUser().then((data) => {
    //    this.settingStore.dispatch(SettingActions.GetSettings());
    //   });
    // } else {
    //   this.settingStore.dispatch(SettingActions.GetSettings());
    // }

    // this.systemSettingStore.dispatch(SystemSettingActions.GetSystemSettings());
    // this.systemSettingStoreSubscription = this.systemSettingStore.select('systemSettings').subscribe((settings) => {
    //   // console.log('get system settings,appId', settings, environment.cubeApplicationId);
    //   const feedSettings = settings.find(
    //     (setting) => (setting.datatype === 'feeds' && setting.data.notifications.applicationId === environment.cubeApplicationId)
    //   );
    //   // console.log('get system settings feed', feedSettings);
    //   // if (feedSettings !== undefined) {
    //   //   this.systemSettings = feedSettings;
    //   //   // console.log('get system settings  assigned ', this.systemSettings);
    //   //   if (this.allFeeds.length > 0) {
    //   //     this.setVisibleFeeds();
    //   //   }

    //   // }
    // });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];


  }

  setVisibleFeeds() {
    // if (this.systemSettings.data !== undefined) {
    //   const currentFeed = this.systemSettings.data.notifications.currentFeed;
    //   // console.log('starting at feed no', currentFeed);
    //   this.feeds = [...this.allFeeds];
    //   // Remove feeds later than current feed
    //   this.feeds
    //     .sort((a, b) => a.sortOrder - b.sortOrder)
    //     .splice(currentFeed, this.allFeeds.length);
    //   this.feeds.sort((a, b) => b.sortOrder - a.sortOrder); //Reverse order - latest feed first
    //   // Only the six latest feeds needed
    //   if (this.feeds.length > 3) {
    //     this.feeds.splice(3, this.feeds.length);
    //   }
    //   // console.log('feeds spliced all=', this.allFeeds, this.feeds);
    // }
  }

  ngOnDestroy(): void {
    if (this.configSubscription) this.configSubscription.unsubscribe();
    if (this.settingSubscription) this.settingSubscription.unsubscribe();
    if (this.systemSettingStoreSubscription) this.systemSettingStoreSubscription.unsubscribe();
    if (this.feedSubscription) this.feedSubscription.unsubscribe();
  }

  ngOnInit(): void {

    console.log('ngOnInit in newsfeed');
    this.disableNext = false;

    if (this.previewMode) {
      this.allFeeds = [this.previewFeed];
      this.previewFeed['completed'] = false;
      this.selectedFeed = this.previewFeed;
      this.onClickCard(this.previewFeed);
    }

    // } else {
    //   this.feedSubscription = this.feedStore.select('feeds').subscribe((feeds) => {
    //     // console.log('feed subs', feeds.length, feeds);
    //     // this.feeds = Object.assign(this.feeds,feeds);
    //     this.allFeeds = feeds;
    //     // console.log('feeds subscription ', this.feeds, feeds);
    //     if (this.systemSettings.id > '0') {
    //       this.setVisibleFeeds();
    //     }


    // this.settingSubscription = this.settingStore.select('settings').subscribe((settings) => {
    //   // console.log('subscribe to settings in newsfeed', settings);
    //   this.setting = settings.find((set) => set.datatype === 'quizes-completed');
    //   // console.log(' this.setting', this.setting);
    //   if (this.setting !== undefined) {
    //     this.setting.data?.quizIds?.forEach((quizId) => {
    //       // console.log('completed quiz id', quizId);
    //       // this.news.find((news) => news.id === quizId).completed = true;
    //       const foundFeed = this.feeds.find((feed) => feed.id === quizId);
    //       if (foundFeed !== undefined) {
    //         foundFeed.completed = true;
    //       }
    //     })
    //     this.setting.data.results.forEach(feedResult => {
    //       const foundFeed = this.feeds.find((feed) => feed.id === feedResult.quizId);
    //       if (foundFeed !== undefined) {
    //         foundFeed.result = feedResult.answers;
    //       }
    //     });

    //   }
    // });
    // });
    // this.feedStore.dispatch(GetFeeds({ applicationId: environment.cubeApplicationId }));

    // this.configSubscription = this.configStore.select('configs').subscribe(configs => {
    //   const appId = configs.find((conf) => conf.key === 'applicationId');
    //   if (appId) this.applicationId = appId.value;

    //   const connection = configs.find((conf) => conf.key === 'connection');
    //   if (connection) this.connection = connection.value;

    // });
    // }


  }

  ngAfterViewInit(): void {
    this.showIntro = true;

    if (this.previewMode) {
      this.allFeeds = [this.previewFeed];
      this.previewFeed['completed'] = false;
      this.selectedFeed = this.previewFeed;
      this.onClickCard(this.previewFeed);
    }

    // this.dv.filter('no', 'contains');
  }


  hasSingleCorrectAnswer2(feed: Feed) {
    if (feed !== undefined || feed === null || !this.previewMode) {
      let count = 0;
      feed?.info?.forEach((answer) => {
        if (answer.correct) {
          count++;
        }
      });
      if (count === 1) {
        return true;
      }
    }

    return false;
  }


  showCorrectOrder() {
    this.showingOriginalOrder = false;
    this.selectedFeed.info.sort((a, b) => a.sortOrder - b.sortOrder);
    // console.log('showCorrectOrder', this.selectedFeed.info);
  }

  showOriginalOrder() {
    this.showingOriginalOrder = true;
    const orderedFeedInfo: FeedInfo[] = [];
    const indexes: any[] = this.selectedFeed.result;
    indexes.forEach(index => {
      orderedFeedInfo.push(this.selectedFeed.info.find(info => info.sortOrder === index));
    });
    this.selectedFeed.info = orderedFeedInfo;
    // console.log('showOriginalOrder', this.selectedFeed.info);
  }

  correctInput(): boolean {
    this.showWrongAnswerText = false;
    this.showCorrectAnswerText = false;
    let correct = false;

    if (this.selectedFeed.examtype === 'question') {
      const correctIds = this.selectedFeed.info
        .filter((info) => info.correct === true)
        .map((info) => info.sortOrder);

      let myAnswers = [];
      if (this.multipleCorrects) {
        myAnswers = this.selectedAnswers;
      } else {
        myAnswers = [this.selectedAnswers];
      }

      const containsAll =
        correctIds.every((aId) => myAnswers.includes(aId)) &&
        correctIds.length === myAnswers.length;

      correct = containsAll;
    } else if (this.selectedFeed.examtype === 'order') {
      let idx = 1;
      correct =
        this.selectedFeed.info.filter(
          (feed, index) => feed.sortOrder === index + 1
        ).length === this.selectedFeed.info.length;
      if (correct) {
        this.selectedFeed.info = this.selectedFeed.info.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );
      }
    } else if (this.selectedFeed.examtype === 'imagemap') {
      const found = this.selectedFeed.info.filter(
        (info, index) => info.correct === (this.showCheckmark[index] !== 'none')
      );
      correct = found.length === this.selectedFeed.info.length;
      // console.log('found', found);
    }

    // console.log('correct', correct);
    if (correct) {
      this.showCorrectAnswerText = true;
    } else {
      this.showWrongAnswerText = true;
    }
    this.disableNext = true;
    this.selectedFeed.completed = true;

    return correct;
  }

  submitAnswer() {

    const correct = this.correctInput();

    let theAnswers;
    if (this.selectedFeed.examtype === 'order') {
      theAnswers = this.selectedFeed.info.map(info => info.sortOrder);
    } else if (this.selectedFeed.examtype === 'imagemap') {
      console.log('showCheckmark', this.showCheckmark);
      theAnswers = [];
      this.showCheckmark.forEach((item, index) => (item.length === 0) ? theAnswers.push(index + 1) : null);
      this.selectedAnswers = theAnswers;
    } else {
      theAnswers = this.selectedAnswers;
    }
    console.log('theAnswers', theAnswers);

  }
  // const containsAll = (arr1, arr2) =>                 arr2.every(arr2Item => arr1.includes(arr2Item))

  onStartQuiz() {
    // console.log('onStartQuiz');
    this.showIntro = false;
    this.showQuiz = true;
    this.disableNext = false;
    // this.onClickCard(this.news[0]);
  }

  closeNuggetDisplay() {
    this.showNugget = false;
  }

  onSubscribeToNotifications(): void {
    // this.subError = '';
    // // console.log('pushed subscribe to newsfeeds button');
    // this.webNotificationService
    //   .subscribeToNotification()
    //   .then((sub) => {
    //     // console.log('sub', sub);
    //     this.subError = null;
    //     this.sub = sub;
    //     this.webNotificationService.sendToServer(sub, this.applicationId);
    //   })
    //   .catch((err) => {
    //     this.logger.error('Could not subscribe to notifications', err);
    //     this.subError = err;
    //   });
  }

  // onSubscribeToNotifications() {
  //   console.log('onSubscribeToNotifications');
  //   this.swPush.requestSubscription({
  //     serverPublicKey: this.VAPID_PUBLIC_KEY
  //   })
  //     .then(sub => {
  //       console.log('sub', sub);
  //     }) //this.newsletterService.addPushSubscriber(sub).subscribe())
  //     // .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
  //     .catch(err => console.error("Could not subscribe to notifications", err));
  // }

  // onRowReorder() {
  //   let index = 1;
  //   let correct = true;
  //   this.products.forEach((element) => {
  //     if (index++ !== element.correctOrder) {
  //       correct = false;
  //     }
  //   });
  //   this.correctOrder = correct;
  // }

  onClickMap(e, idx) {
    console.log('### onClickMap event', e);
    if (!this.selectedFeed.completed) {
      e.preventDefault();
      this.showCheckmark[idx] = this.showCheckmark[idx] === 'none' ? '' : 'none';
      // alert("circle");
    }
  }

  onClickNugget(nugget: Nugget) {
    this.nuggetToDisplay = nugget;
    this.showNugget = true;
  }

  resizeMaps() {
    // console.log('resizeMaps', this.clickimage);
    if (this.selectedFeed?.examtype === 'imagemap' && this.clickimage != undefined) {
      // console.log('clickimage', this.clickimage);
      const scale = this.clickimage.nativeElement.offsetWidth / this.clickimage.nativeElement.naturalWidth;
      // console.log('clickimage', scale, this.selectedFeed.image.width, this.clickimage.nativeElement.offsetWidth, this.clickimage.nativeElement.clientWidth, this.clickimage.nativeElement.naturalWidth);
      // console.log('imagemapCoords', this.imagemapCoords);
      // for (const map of maps) {
      let map = this.clickmap;

      for (let i = 0; i < this.selectedFeed.info.length; i++) {
        // console.log('info1:', this.selectedFeed.info[i]);
        // console.log('coords:', this.imagemapCoords[i]);
        this.selectedFeed.info[i].statement = this.imagemapCoords[i].map(coord => Math.round(coord * scale)).join(',').toString();
        // console.log('info2:', this.selectedFeed.info[i]);

        const coords = this.selectedFeed.info[i].statement
          .split(',')
          .map((entry) => parseInt(entry));

        const shapeWidth = this.selectedFeed.info[i]['shapeWidth'] / 2;
        const shapeHeight = this.selectedFeed.info[i]['shapeHeight'] / 2;
        if (coords.length === 4) {
          this.selectedFeed.info[i]['x'] = (coords[0] + coords[2]) / 2 - (shapeWidth * scale);
          this.selectedFeed.info[i]['y'] = (coords[1] + coords[3]) / 2 - (shapeHeight * scale);
          this.selectedFeed.info[i]['width'] = this.selectedFeed.info[i]['shapeWidth'] * scale;
          this.selectedFeed.info[i]['height'] = this.selectedFeed.info[i]['shapeHeight'] * scale;
        } else if (coords.length === 3) {
          this.selectedFeed.info[i]['x'] = coords[0] - (shapeWidth * 2 * scale);
          this.selectedFeed.info[i]['y'] = coords[1] - (shapeWidth * 2 * scale);
          this.selectedFeed.info[i]['width'] = this.selectedFeed.info[i]['shapeWidth'] * 2 * scale;
          this.selectedFeed.info[i]['height'] = this.selectedFeed.info[i]['shapeHeight'] * 2 * scale;
        }
        //        this.selectedFeed.info[i]['width'] = 80 * scale;
        // this.selectedFeed.info[i]['width'] = this.selectedFeed.info[i]['shapeWidth'] * scale;
      }
      console.log('COORDS: ', this.selectedFeed.info);
    }
  }



  // ngOnChanges(data) {
  //   if (data.previewMode.currentValue === "true") {
  //     this.onClickCard(data.previewFeed);
  //     setTimeout(() => {
  //       this.resizeMaps();
  //     }, 1000);
  //   }
  // }



  onClickCard(feed: Feed) {
    console.log('onClickCard',feed);
    this.selectedAnswers = [];
    this.showCorrectAnswerText = false;
    this.showWrongAnswerText = false;
    this.correctOrder = false;
    this.disableNext = false;
    this.selectedFeed = feed;
    this.multipleCorrects = !this.hasSingleCorrectAnswer2(this.selectedFeed);
    if (feed.examtype === 'order') {
      feed.info = Utilities.getShuffledArr(feed.info);
    } else if (feed.examtype === 'imagemap') {
      this.showCheckmark = ['none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none']; // new Array(10); //new Array(this.selectedFeed?.result.length);
      this.imagemapCoords = [];
      let idx = 0;
      console.log('info in onclick:::', feed);
      feed.info.forEach((info) => {
        console.log('info in onclick', info);
        if (this.selectedFeed.result) {
          const found = this.selectedFeed.result.find(item => (item === '' + (idx + 1)));
          if (found !== undefined) {
            const indexPos: number = Number(found) - 1;
            this.showCheckmark[indexPos] = 'inherit'
          }
          idx++;
        }
        const coords = info.statement
          .split(',')
          .map((entry) => parseInt(entry));
        // console.log('coords', coords);
        this.imagemapCoords.push(coords);
        if (coords.length === 4) {
          const shapeWidth = Math.abs(coords[0] - coords[2]);
          const shapeHeight = Math.abs(coords[1] - coords[3]);
          info['x'] = (coords[0] + coords[2]) / 2 - (shapeWidth / 2);
          info['y'] = (coords[1] + coords[3]) / 2 - (shapeWidth / 2);
          info['width'] = 0;
          info['shapeWidth'] = shapeWidth;
          info['shapeHeight'] = shapeHeight;
          info['shape'] = 'rect';
        } else if (coords.length === 3) {
          const shapeWidth = coords[2];
          const shapeHeight = coords[2];
          info['x'] = coords[0] - (shapeWidth / 2);
          info['y'] = coords[1] - (shapeWidth / 2);
          info['width'] = 0;
          info['shapeWidth'] = shapeWidth;
          info['shapeHeight'] = shapeHeight;
          info['shape'] = 'circle';
        }
      });
      setTimeout(() => {
        this.resizeMaps();
      }, 500);
    }

    this.showIntro = false;
    this.showQuiz = true;

    //   if (feed.completed && !this.previewMode) {
    //     if (this.selectedFeed.examtype === 'order') {
    //       this.showOriginalOrder();
    //     }
    //     this.correctInput();
    //   }

    //   // if (this.selectedFeed.furtherInfo) {
    //   //   this.furtherInfoNugget.content = [this.selectedFeed.furtherInfo];
    //   // }

    // }



  }
}
