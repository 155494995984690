import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, Message, MessageService, SelectItem, SelectItemGroup, } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FeedsFormComponent } from '../feeds-form/feeds-form.component';
// import { DeleteFeed, GetFeeds, SaveFeed } from '../store/feeds.action';
// import { SettingActions } from '../store/setting.action';
import { Setting } from '../swagger-api-v4/model/setting';
import { Utilities } from '../utilities';
import { MSG_DELETE_CONFIRM } from '../utils/messages';
import * as moment from 'moment';
import { BreadcrumbService } from '../breadcrumb.service';
import { environment } from 'src/environments/environment';
import { Application, CourseService, CustomerService, FeedResponseDataObject, FeedListResponseDataItem, FeedRequest, FeedService, FeedListResponse, CustomerListResponse, CustomerResponse, SettingResponse, SettingRequest, SettingService, SettingListResponse, SettingListResponseDataItem } from '../swagger-api-v4';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface NotificationSettings {
  notifications?: {
    title?: string;
    body?: string;
    currentFeed: number;
    interval: number;
    intervalUnit: string;
    nextNotification: Date;
    latestNotification: Date;
    applicationId?: string;
    locale?: string;
  };
}

@Component({
  selector: 'app-feeds',
  templateUrl: './feeds.component.html',
  styleUrls: ['./feeds.component.scss'],
})

// interface Answer {
//   id: number,
//   answer: string,
//   correct?: boolean
// }
// interface NewsCard {
//   id: string,
//   title: string,
//   details: string,
//   header?: string,
//   question?: string,
//   answers?: Answer[]
//   correct?: string,
//   incorrect?: string,
//   completed?: string,
//   nuggetId?: string
// }

// id: string;
// title?: string;
// details?: string;
// exam?: FeedExam;
// correct?: string;
// incorrect?: string;
// sortOrder?: number;
// nugget?: ExamNugget;
// image?: CustomerLogo;
// locale: string;
// }




export class FeedsComponent implements OnInit, AfterViewInit {
  cols = [
    // { field: 'id', header: 'Id' },
    // { field: 'sortOrder', header: 'SortOrder', type: 'Numeric', width: '4em' },
    { field: 'title', header: 'Title', type: 'Text' },
    { field: 'details', header: 'Question', type: 'Text' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },
  ];

  feeds: FeedListResponseDataItem[] = [];
  selectedFeed: FeedResponseDataObject = null;
  selectedFeeds: FeedResponseDataObject[] = [];

  appsDisabled = false;
  copyFinished = false;
  copyText = "";
  displayModal = false;
  displayCopyModal = false;
  msgs: Message[] = [];
  groupedApplications: SelectItemGroup[] = [];
  applications: SelectItem[] = [];
  selectedApplication = 0;


  notificationSettings: NotificationSettings = {
    notifications: {
      currentFeed: 1,
      interval: 12,
      intervalUnit: 'months',
      nextNotification: new Date('2050-01-01T15:00:00.000Z'),
      latestNotification: new Date('2000-01-01T15:00:00.000Z'),
      applicationId: ''
    }
  };

  systemSettings: SettingListResponseDataItem;

  // notifications?: {
  //   currentFeed: number;
  //   interval: number;
  //   intervalUnit: string;
  //   nextNotification: Date;
  //   latestNotification: Date;
  //   applicationId?: string;
  //   locale?: string;
  // };

  displayScheduling = false;

  // uxSelections: UxSelections;
  ref;

  curFeedNo: number = 6;
  interval: number = 2;
  lastrun: Date = new Date();
  nextrun: Date = new Date();

  intervals: SelectItem[] = [
    // { label: 'Minutes', value: 'minutes' },
    // { label: 'Hours', value: 'hours' },
    { label: 'Days', value: 'days' },
    { label: 'Weeks', value: 'weeks' },
    { label: 'Months', value: 'months' },
  ];
  selectedInterval: SelectItem = this.intervals[3];

  applicationId = 0;
  courseId = 0;
  customerId = 0;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private confirmationService: ConfirmationService,
    // private customerService: CustomerService,
    // private courseService: CourseService,
    public dialogService: DialogService,
    private feedService: FeedService,
    private settingService: SettingService,
    // private systemSettingStore: Store<{ systemSettings: Setting[] }>,
    // private feedStore: Store<{ feeds: FeedListResponseDataItem[] }>,
    private http: HttpClient,
  ) {

    console.log('consructing');
    this.applicationId = Number(localStorage.getItem('applicationId'));
    this.courseId = Number(localStorage.getItem('courseId'));
    this.customerId = Number(localStorage.getItem('customerId'));

    // this.systemSettingStore.dispatch(SystemSettingActions.GetSystemSettings());

    if (this.applicationId) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);

      // this.notificationSettings.notifications.applicationId = this.applicationId.toString();

      // this.sysSub = this.systemSettingStore.select('systemSettings').subscribe((settings) => {

      console.log('systemSettings headers', headers);
      this.http.get<SettingListResponse>('/cms-server/settings?filters[userid][$eq]=@SYSTEM@', { headers: headers, })
        .toPromise().then((settings) => {
          console.log('get system settings', settings);
          const foundSettings = settings.data.filter((setting) => { console.log('compare', setting.attributes, setting.attributes.datatype === 'feeds'); return setting.attributes.datatype === 'feeds'; });
          console.log('get system settings feed', foundSettings, this.applicationId);
          if (foundSettings !== undefined && foundSettings.length > 0) {
            const foundSettingApp = foundSettings.find(setting => {
              console.log('appId', setting.attributes.data?.notifications.applicationId, this.applicationId);
              return setting.attributes.data?.notifications.applicationId === this.applicationId;
            });
            console.log('foundSettingApp', foundSettingApp, this.applicationId);
            if (foundSettingApp === undefined) {
              const newNotification = {
                title: "",
                body: "",
                currentFeed: 1,
                interval: 14,
                intervalUnit: 'days',
                nextNotification: new Date,
                latestNotification: null,
                applicationId: 0 + this.applicationId,
                //      locale: 'XX'
              };
              this.systemSettings = {
                attributes: {
                  userid: '@SYSTEM@',
                  datatype: 'feeds',
                  data: {notifications: newNotification}
                }
              }
              this.notificationSettings = this.systemSettings.attributes.data;
              console.log('new setting', this.systemSettings);
              console.log('notificationSettings ', this.notificationSettings);
            } else {
              this.systemSettings = foundSettingApp;
              // this.notificationSettings = feedSettings.data;
              this.notificationSettings = foundSettingApp.attributes.data;
              this.nextrun = moment(
                this.systemSettings.attributes.data.notifications.nextNotification
             ).toDate();
              // this.nextrun = foundSettingApp.attributes.data.notifications.nextNotification;
              // this.notificationSettings.notifications.applicationId = 0+this.applicationId.toString();
              // this.updateNextNotification();
              // Object.assign(this.notificationSettings, feedSettings.data);
              console.log('get system settings  assigned ', this.notificationSettings);
            }
          }
        });
      // environment.cubeApplicationId = this.applicationId.toString();
      // this.feedSub = feedStore.select('feeds').subscribe((feeds) => {
      //   console.log('sub to feeds', feeds);
      //   if (feeds !== undefined) {
      //     this.feeds = feeds.filter(feed => feed.attributes.application.data.id === this.applicationId);
      //     console.log('sub to feeds 2', this.feeds);
      //     this.feeds = this.feeds.sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder);
      //     console.log('sub to feeds 3', this.feeds);
      //     // console.log('feeds subscription', this.feeds);
      //   }
      // });
      // this.feedStore.dispatch(GetFeeds({ applicationId: this.applicationId.toString() }));

    }


    //this.store.dispatch(GetCMS());
    // this.store.select('cms').subscribe((cms) => {
    //   console.log('SUB CMS', cms);
    //   this.cms = cms;
    // });

    this.refreshData();
  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/feeds?filters[application][id][$eq]=' + this.applicationId + '&sort=sortOrder&populate=*&pagination[limit]=1000', { headers: headers })
      .toPromise().then((feeds: FeedListResponse) => {
        console.log('feeds', feeds);
        this.feeds = feeds.data;

      })
  }

  ngAfterViewInit() {
    const items: MenuItem[] = [
      { label: localStorage.getItem('customerName'), routerLink: '/customers' },
      { label: localStorage.getItem('courseName'), routerLink: '/courses' },
      { label: localStorage.getItem('applicationName'), routerLink: '/applications' },
      { label: 'Feeds' },
    ];
    this.breadcrumbService.setItems(items);
  }

  updateNextNotification() {
    this.nextrun = moment(
      this.systemSettings.attributes.data.notifications.latestNotification
    )
      .add(
        this.systemSettings.attributes.data.notifications.interval,
        this.systemSettings.attributes.data.notifications.intervalUnit
      )
      .toDate();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
  }

  newFeed(event) {

    const newFeed: FeedListResponseDataItem = {
      id: 0,
      attributes: {
        title: 'New Feed',
        details: '',
        examtype: 'question',
        sortOrder: 999,
        //locale: '',
        application: { data: { id: this.applicationId } },
        info: [
          { statement: 'A', response: '', sortOrder: 1, correct: true },
          { statement: 'B', response: '', sortOrder: 2, correct: false },
          { statement: 'C', response: '', sortOrder: 3, correct: false },
        ],
      }
    };
    // const newFeed: Feed = {
    //   id: '',
    //   title: '',
    //   sortOrder: 999,
    //   info: [],
    //   application: { id: this.uxSelections.applicationId }
    // };

    this.showEditDialog(newFeed);
  }

  formatDate(date) {
    return Utilities.formatDate(date);
  }

  showEditDialog(feed: FeedListResponseDataItem) {
    this.ref = this.dialogService.open(FeedsFormComponent, {
      header: feed?.id > 0 ? 'Edit Feed' : 'New Feed',
      width: '100%',
      data: {
        feed: feed,
      },
    });

    this.ref.onClose.subscribe((feed) => {
      if (feed) {
        this.onRowReorder(feed);
        // let idx;
        // if (
        //   (idx = this.feeds.findIndex((alevel) => alevel.id === feed.id)) >= 0
        // ) {
        //   this.feeds[idx] = feed;
        // } else {
        //   this.feeds.push(feed);
        // this.application.levels.push(feed);
        // let newApplication: NewApplication = {};
        // Object.assign(newApplication, this.application);
        // this.applicationService.applicationsIdPut(newApplication, this.application.id).toPromise().then(
        //   app => { },
        //   err => {
        // this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: err });
        // }
        // );
        // }
      }
    });
  }

  deleteFeed(feed: FeedResponseDataObject) {
    console.log('deleteFeed', feed);
    this.confirmationService.confirm({
      message: Utilities.format(MSG_DELETE_CONFIRM, feed.attributes.title),
      accept: () => {
        this.feedService.deletefeedsid(feed.id).toPromise().then((deletedFeed) => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newfeed.title });
          // this.feedStore.dispatch(DeleteFeed(feed));

          let index = this.feeds.findIndex(afeed => afeed.id === feed.id);
          if (index != -1) {
            this.feeds.splice(index, 1);
          }
        },
          err => {
            console.log('error', err);
          }
        )
      }
    })
  }

  onCopyFeeds() {
    console.log('Copy feeds : ' + this.selectedFeeds.length);
    this.confirmationService.confirm({
      message:
        'Are you sure you want to copy ' +
        this.selectedFeeds.length +
        ' feeds?',
      accept: () => {
        this.msgs = [];
        this.displayCopyModal = true;
        this.copyFinished = false;
        //        cloneApplication();
      },
    });

  }


  showCopyFeeds() {
    this.groupedApplications = [];
    this.applications = [];
    this.displayCopyModal = true;

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/customers/' + this.customerId + '?populate[courses][fields][0]=name&populate[courses][fields][1]=isDeleted&[populate][courses][populate][applications][fields][2]=title&[populate][courses][populate][applications][fields][3]=isDeleted', { headers: headers })
      .toPromise().then((customer: CustomerResponse) => {
        const itemsApplications = [];
        console.log('customer', customer);
        customer.data.attributes.courses.data
          .filter(course => course.attributes.isDeleted != true)
          .forEach(course => {
            course.attributes.applications.data.filter(app => app.attributes.isDeleted != true)
              .forEach(app => {
                console.log('app ivar',app);
                if (app.attributes.isDeleted !== true && app.attributes.title.charAt(0) !== "@") {
                  itemsApplications.push({ label: app.attributes.title, value: app.id })
                  this.applications.push({ label: customer.data.attributes.name + ' - ' + course.attributes.name + ' - ' + app.attributes.title, value: app.id }); // THIS IS IN USE FOR PRIMENG 9
                }
              })
          })
        console.log(itemsApplications);
        console.log(this.applications);
        this.applications = [...this.applications];
      })


    //             if (app.attributes.isDeleted !== true && app.attributes.title.charAt(0) !== "@") {
    //               itemsApplications.push({ label: app.attributes.title, value: app })
    //               this.applications.push({ label: cu.Name + ' - ' + co.Name + ' - ' + app.attributes.title, value: app }); // THIS IS IN USE FOR PRIMENG 9
    //             }




    // this.customerService.getcustomers().toPromise().then(customers => {
    //   const cs = customers.data.filter(c => c.isDeleted !== true);
    //   cs.forEach(cu => {
    //     if (cu.courses.length > 0) {
    //       const itemsApplications = [];
    //       cu.courses.forEach(co => {
    //         if (co.isDeleted !== true && Number(co.id) !== this.courseId) {
    //           // itemsCourses.push({ label: co.Name, value: co })
    //           // this.courses.push({ label: cu.Name + ' - ' + co.Name, value: co }); // THIS IS IN USE FOR PRIMENG 9
    //         }
    //         this.courseService.getcoursesid(co.id).toPromise().then(course => {
    //           course.data.attributes.applications.data.forEach(app => {
    //             if (app.attributes.isDeleted !== true && app.attributes.title.charAt(0) !== "@") {
    //               itemsApplications.push({ label: app.attributes.title, value: app })
    //               this.applications.push({ label: cu.Name + ' - ' + co.Name + ' - ' + app.attributes.title, value: app }); // THIS IS IN USE FOR PRIMENG 9
    //             }
    //           })

    //         })
    //       })
    //       this.groupedApplications.push({
    //         label: cu.Name, value: cu.id,
    //         items: itemsApplications
    //       });
    //     }
    //   })
    // })
  }


  onCopyCancel() {
    this.displayCopyModal = false;
  }

  closeCopyApplication(event) {
    this.displayModal = false;
    // this.refreshData();
  }


  onCopy() {
    this.msgs = [];
    this.displayModal = true;
    this.copyFinished = false;

    console.log('onCopy ', this.selectedApplication);
    // console.log('this.selectedApplication.course', this.selectedApplication.course);
    // const updApp: any = this.selectedApplication;

    console.log('selectedFeeds', this.selectedFeeds);
    let i = 0;
    // const copyToApplication: Application = { id: this.selectedApplication.id };
    this.selectedFeeds.forEach(feed => {
      // let newFeed: NewFeed = feed;
      //newFeed.application = copyToApplication;
      let feedRequest: FeedRequest = {
        data: {}
      }
      Object.assign(feedRequest.data, feed.attributes);
      feedRequest.data.application = feed.attributes.application.data.id;
      // feedRequest.data.examtype = this.selectedQuizType?.value;
      feedRequest.data.nuggets = feed.attributes.nuggets.data.map(nug => nug.id);
      feedRequest.data.furtherInfo = feed.attributes.furtherInfo.data?.id;
      // feedRequest.data.application = feed.attributes.application.data?.id;
      feedRequest.data.image = feed.attributes.image.data?.id;
      feedRequest.data.thumbnail = feed.attributes.thumbnail.data?.id;
      feedRequest.data.application = this.selectedApplication;
      console.log('saving feed', feedRequest);
      this.feedService.postfeeds(feedRequest).toPromise().then(feed => {
        this.copyText = feed.data.attributes.title;
        console.log('feed copied', feed);
        i++;
        if (i >= this.selectedFeeds.length) {
          this.copyFinished = true;
        }

      },
        (err) => {
          console.log('error while copying feed', feed.id);
          i++;
          if (i >= this.selectedFeeds.length) {
            this.copyFinished = true;
          }

        });
    });

    //    console.log('Finished copying');
    // updApp.course = this.selectedCourse.id;
    // this.applicationService.applicationsIdPut(updApp, this.selectedApplication.id).toPromise().then(app => {
    //   console.log('updated app after move', app);
    //   this.displayCopyModal = false;
    // }, (err) => {
    //   console.log('error when copying', err);
    // })

  }

  onRowReorder(event) {
    console.log('reorder', event);
    this.feeds.forEach((feed, idx) => {
      // let newFeed: NewFeed = {};
      // Object.assign(newFeed, feed);
      if (feed.attributes.sortOrder != idx + 1) {
        feed.attributes.sortOrder = idx + 1;
        const feedRequest: FeedRequest = {
          data: {
            sortOrder: feed.attributes.sortOrder
          }
        }
        this.feedService.putfeedsid(feedRequest, feed.id)
          // .feedsIdPut(feed, feed.id)
          .toPromise()
          .then(
            (updatedFeed) => {
              // this.feedStore.dispatch(SaveFeed(feed));
            },
            (err) => {
              console.log('error while sorting feed', feed.id);
              // this.messageService.add({
              //   severity: 'error',
              //   summary: 'Error while saving',
              //   detail: err,
              // });
            }
          );
      }
      console.log(idx, feed.id, feed.attributes.title, feed.attributes.sortOrder);
    });
  }

  onSubmitNextExcecution() {
    alert('Todo onSubmitNextExcecution');
  }

  onShowNotifications() {
    this.displayScheduling = true;
    console.log('this.notificationSettings', this.notificationSettings);
    // if (this.notificationSettings) {
    //   const newNotification = {
    //     currentFeed: 1,
    //     interval: 14,
    //     intervalUnit: 'days',
    //     nextNotification: new Date,
    //     latestNotification: null,
    //     applicationId: this.applicationId,
    //     //      locale: 'XX'
    //   };
    // }
  }

  onSubmitSaveNotification() {

    console.log('onSubmitSaveNotification', this.systemSettings);

    // this.systemSettings.attributes.data.notifications.nextNotification = this.nextrun;
    //      this.systemSettings.data.notifications.locale = 'EN';
    // let mySettings = Object.assign({}, this.systemSettings.data.attributes);
    // Check if data for this app already exist..replace or create
    console.log('this.notificationSettings.notifications', this.notificationSettings.notifications);
    // Object.assign(this.systemSettings.attributes.data.notifications, this.notificationSettings.notifications);
    // this.systemSettings.attributes.data.notifications = this.notificationSettings.notifications;
    console.log('SAVING FEED SETTINGS', this.systemSettings);
    //      mySettings.data = this.notificationSettings;

    const setting: SettingRequest = {
      data: {
        userid: this.systemSettings.attributes.userid,
        datatype: this.systemSettings.attributes.datatype,
        data: this.systemSettings.attributes.data
      }
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.systemSettings.attributes.data.notifications.nextNotification = this.nextrun;
    if (this.systemSettings.id > 0) {
      this.settingService.putsettingsid(setting, this.systemSettings.id).toPromise().then(response => {
        console.log('put saved settings', response);
      })
    } else {
      this.settingService.postsettings(setting).toPromise().then(response => {
        console.log('post saved settings', response);
      })
    }

    this.displayScheduling = false;
  }

}


