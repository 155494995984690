<!-- {{ selectedFeed | json }} -->
<br>
<!--
{{ previewFeed | json }}
<br>
previewMode : {{ previewMode }}
showQuiz : {{ showQuiz }}
<br>
furtherInfo : {{ selectedFeed.furtherInfo|json }}
furtherInfoText : {{ selectedFeed.furtherInfoText }}
nuggets : {{ selectedFeed.nuggets | json }} -->

<!-- <ng-container *ngIf="showNugget">
  <app-nugget-display [nugget]="nuggetToDisplay" (close)="closeNuggetDisplay()"></app-nugget-display>
</ng-container> -->

<ng-container *ngIf="!showNugget">
  <a #startquiz id="startquiz"></a>
  <div *ngIf="showIntro && !showQuiz" style="width: 100%">
    <div class="container_row">
      <div class="layer1">
        <div class="p-grid page-grid-top">
          <span class="p-offset-1 p-xl-5 p-lg-5 p-md-11 p-sm-11">
            <span class="main-title" i18n="My feed title@@#MY_FEED_TITLE">My Feed</span>
            <span class="main-subtitle">&nbsp;&mdash;&nbsp;</span>
            <span class="main-subtitle" i18n="My feed subtitle@@#MY_FEED_SUBTITLE">check your knowledge on
              e-mobility with a quiz</span>
          </span>
          <span class="p-xl-7 p-lg-7 p-md-1 p-sm-1"></span>
          <span class="p-offset-1 p-xl-5 p-lg-5 p-md-11 p-sm-11">
            <div class="main-ingress">
              <p i18n="My feed details@@#MY_FEED_DETAILS">Just like cars, you need to do your regular maintenance as
                well. This easy quiz will deepen your knowledge in several interesting areas. Stop by when you have time
                and you will get a new quiz with exciting news and information regarding High-Voltage vehicles. Let´s do
                this superfast check-up!
              </p>
            </div>

            <!-- <p-button *ngIf="!isGranted" label="Subscribe to quiz updates" -->

            <!-- <p-button *ngIf="!isGranted"
              i18n-label="Button for subscribing to quiz notifications@@#MY_FEED_SUBSCRIBE_BUTTON"
              label="Subscribe to quiz updates" (onClick)="onSubscribeToNotifications()" [disabled]="sub"
              class="p-button-warning"></p-button> -->

            <!-- <p *ngIf="subError" class="error">
              <span i18n="Notification subscription error@@#MY_FEED_NOTIFICATION_ERROR">Could not subscribe to
                notifications. Your browers might not support this feature.</span>
            </p>

            <p *ngIf="isGranted" class="success" i18n="Notifications active@@#MY_FEED_NOTIFICATION_ACTIVE">
              Notification of new quizes is activated.
            </p> -->
            <!-- <p *ngIf="!isEnabled" class="error">
              Notifications are not available in the browser or enabled in the
              application.
            </p> -->

            <!-- <div>
                        <p><b>{{ selectedNewsCard?.title }}</b></p>
                        <p>{{ selectedNewsCard?.details }}</p>
                    </div>
                    <p-button label="Open Quiz" (click)="onStartQuiz()" class="p-button-warning"></p-button> -->
          </span>
          <!-- <span class="p-col-6"></span> -->
        </div>
      </div>
      <!-- <div class="layer2 background-image2" style="
          background-image: url('assets/backgrounds/My_feed_1920x710px.jpg');
        "></div> -->

      <br /><br />

      <!-- <div class="layer2" style="z-index: -20;">
            <img src="assets/backgrounds/My_feed_1920x710px.jpg" alt="" class="background-image">
        </div> -->
    </div>
  </div>
  <!-- <pre>selectedFeed : {{selectedFeed|json}}</pre> -->
  <div *ngIf="showQuiz" style="width: 100%;">
    <div class="container_row">
      <div class="layer1">
        <div>
          <!-- <a id="startquiz"></a> -->

          <div *ngIf="showQuiz" class="p-grid" style="width: 100%; background-color: #E5EEF5;">
            <!-- <span class="p-sm-1 p-xl-1 p-lg-1 p-md-1"></span> -->
            <span class="p-offset-1 p-col-10 p-xl-5 p-lg-5 p-md-10 p-sm-10 p-offset-1">
              <!-- </span>p-sm-11 p-xl-5 p-lg-5 p-md-5"> -->
              <br />
              <br />
              <br />

              <!-- <h1>{{ selectedNewsCard?.header }}</h1> -->
              <!-- <p><b>{{ selectedNewsCard?.question }}</b></p> -->
              <div class="quiz-no">
                <!-- <span i18n="Header for quiz@@MY_FEED_QUIZ_HEADER">QUIZ</span> {{ selectedFeed?.title }} -->
                <!-- <span i18n="Header for quiz@@MY_FEED_QUIZ_HEADER">QUIZ</span>  -->
                {{ selectedFeed?.title }}
              </div>
              <div class="main-question">{{ selectedFeed?.details }}</div>
              <!-- <br />-->
              <br />
              <p *ngIf="selectedFeed?.furtherInfoText !== null">
                <span i18n="My feed further information@@#MY_FEED_FURTHER_INFORMATION" class="green-label">
                  Further information :</span>
                <span class="green-link">
                  {{ selectedFeed.furtherInfoText }}</span>
                  <!-- <span class="green-link" (click)="onClickNugget(furtherInfoNugget)">
                    {{ selectedFeed.furtherInfoText }}</span> -->
                </p>

              <ng-container *ngIf="selectedFeed?.examtype === 'question'">
                <ng-container *ngIf="!multipleCorrects">
                  <div *ngFor="let info of selectedFeed?.info">
                    <div
                      [ngClass]="selectedFeed.completed && info.correct ? 'p-field-radiobutton correct-choice' :
                              selectedFeed.completed && selectedAnswers === info.sortOrder ? 'p-field-radiobutton wrong-choice' : 'p-field-radiobutton'">
                      <p-radioButton name="radioanswers" [value]="info.sortOrder" [(ngModel)]="selectedAnswers"
                        [inputId]="info.sortOrder" [disabled]="disableNext || selectedFeed.completed">
                      </p-radioButton>
                      <label for="radioanswers" [ngStyle]="!selectedFeed.completed ? 'cursor: pointer;' : ''"
                        (click)="!selectedFeed.completed ? (selectedAnswers = info.sortOrder) : null"> {{
                        info.statement }}</label>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="multipleCorrects">
                  <div *ngFor="let info of selectedFeed?.info">
                    <div
                      [ngClass]="selectedFeed.completed && !info.correct && selectedAnswers?.includes(info.sortOrder) ? 'p-field-radiobutton wrong-choice' :
                               selectedFeed.completed && info.correct ? 'p-field-radiobutton correct-choice' : 'p-field-radiobutton'">
                      <p-checkbox name="checkanswers" [value]="info.sortOrder" [(ngModel)]="selectedAnswers"
                        [inputId]="info.sortOrder" [disabled]="disableNext || selectedFeed.completed">
                      </p-checkbox>
                      <label for="checkanswers"
                        [ngClass]="!selectedFeed?.completed ? 'show-hand' : ''">{{info.statement}}</label>
                      <!-- [ngStyle]="!selectedFeed?.completed ? 'background-color: #ff00ff;' : ''">{{info.statement}}</label> -->
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="selectedFeed?.examtype === 'order'">
                <div *ngIf="!selectedFeed.completed">
                  <p-orderList [value]="selectedFeed.info" dragdrop="true" controlsPosition="left">
                    <ng-template let-info pTemplate="item" let-index="index">
                      <div>{{ info.statement }}</div>
                    </ng-template>
                  </p-orderList>
                </div>

                <div *ngIf="selectedFeed.completed">
                  <div *ngFor="let info of selectedFeed?.info; let i = index;"
                    [ngClass]="i+1 === info.sortOrder ? 'sortorder-view correct-choice' : 'sortorder-view wrong-choice'">
                    {{ info.statement }}
                  </div>
                  <div *ngIf="selectedFeed.completed && showWrongAnswerText" style="text-align:center">
                    <p-button *ngIf="showingOriginalOrder && (disableNext || selectedFeed.completed)"
                      i18n-label="Show correct order@@#MY_FEED_SHOW_CORRECT_ORDER" label="Show correct order"
                      (onClick)="showCorrectOrder()" angulartics2On="click" angularticsAction="QuizShowCorrectOrder"
                      angularticsCategory="newsfeed">
                    </p-button> &nbsp;&nbsp;
                    <p-button *ngIf="!showingOriginalOrder && (disableNext || selectedFeed.completed)"
                      i18n-label="Show your order@@#MY_FEED_SHOW_YOUR_ORDER" label="Show your order"
                      (onClick)="showOriginalOrder()" angulartics2On="click" angularticsAction="QuizShowCorrectOrder"
                      angularticsCategory="newsfeed">
                    </p-button>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="selectedFeed?.examtype === 'imagemap'">
                <div style="overflow:hidden;">
                  <div style="position: relative; overflow:hidden;">
                    <img #clickimage style="z-index: 1; max-width: 100%; max-height: 100%;"
                      [src]="Util.getUrl(selectedFeed?.image?.data.attributes.url)" usemap="#clickmap" alt="imagemap" />

                    <map #clickmap name="clickmap">
                      <ng-container *ngFor="let info of selectedFeed?.info; index as i" style="cursor: pointer;">
                        <area alt="O" title="Select" [coords]="info.statement" [shape]="info['shape']"
                          (click)="onClickMap($event, i)" />
                      </ng-container>
                    </map>
                    <!-- showCorrectAnswerText {{ showCorrectAnswerText }}
                  showWrongAnswerText {{ showWrongAnswerText }}
                  selectedFeed?.completed {{ selectedFeed?.completed }} -->

                    <!-- {{ selectedFeed.result | json }} -->
                    <ng-container *ngFor="let info of selectedFeed?.info; index as i">
                      <img *ngIf="info.shape==='circle'"
                        style="position: absolute; z-index: 2; cursor: pointer; overflow:hidden;"
                        (click)="onClickMap($event, i)" alt="selection" [ngStyle]="{
                          display: showCheckmark[i],
                          'top.px': info['y'],
                          'left.px': info['x'],
                          'width.px': info['width'],
                          'height.px': info['height']
                        }" [src]="(showCorrectAnswerText || showWrongAnswerText || selectedFeed?.completed) ?
                            (info.correct  ? 'assets/cube/hotspot_g.png' : 'assets/cube/hotspot_r.png') : 'assets/cube/hotspot_y.png'
                        " />
                      <img *ngIf="info.shape==='rect'"
                        style="position: absolute; z-index: 2; cursor: pointer; overflow:hidden;"
                        (click)="onClickMap($event, i)" alt="selection" [ngStyle]="{
                            display: showCheckmark[i],
                            'top.px': info['y'],
                            'left.px': info['x'],
                            'width.px': info['width'],
                            'height.px': info['height']
                          }" [src]="(showCorrectAnswerText || showWrongAnswerText || selectedFeed?.completed) ?
                              (info.correct  ? 'assets/cube/hotspot_sq_g.png' : 'assets/cube/hotspot_sq_r.png') : 'assets/cube/hotspot_sq_y.png'
                          " />

                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <div *ngIf="!selectedFeed.completed" style="text-align:center">
                <p-button *ngIf="!disableNext" i18n-label="Submit answer button@@#MY_FEED_SUBMIT_ANSWER_BUTTON"
                  label="Submit" [disabled]="
                  (selectedFeed.examtype === 'question' &&
                    selectedAnswers?.length === 0) ||
                  disableNext
                " (onClick)="submitAnswer()" routerLink="." fragment="result" angulartics2On="click"
                  angularticsAction="QuizSubmit" angularticsCategory="newsfeed">
                </p-button>
              </div>
              <!-- </ng-container> -->
            </span>

            <!-- <span #result class="p-offset-1 p-xl-5 p-lg-5 p-md-11 p-sm-11" style="background-color: rebeccapurple;"> -->
            <span #result
              class="p-xl-offset-0 p-lg-offset-0 p-offset-1  p-col-10 p-xl-5 p-lg-5 p-md-10 p-sm-10 p-offset-1">
              <a id="corrected" name="corrected"></a>
              <div *ngIf="showCorrectAnswerText || (selectedFeed?.completed && showCorrectAnswerText)">
                <br />
                <br />
                <br />
                <div class="quiz-correct" i18n="Quiz answered correct@@#MY_FEED_ANSWER_CORRECT">Correct!
                  <img class="quiz-hivo-img" src="assets/img/Feed_Question_Feedback_HiVo_correct_v2_120x120.png"
                    alt="HiVo Correct">
                </div>

                <p *ngIf="selectedFeed.examtype === 'question'"> {{ selectedFeed?.info[selectedAnswers-1]?.response }}</p>
                <p *ngIf="selectedFeed.examtype === 'imagemap'"> {{ selectedFeed?.info[selectedAnswers[0]-1]?.response }}</p>
                <p *ngIf="selectedFeed.examtype === 'order'"> {{ selectedFeed?.correct }}</p>
                <p *ngIf="selectedFeed?.nuggets?.data?.length > 0" class="green-label"
                  i18n="Quiz more information header@@#MY_FEED_QUIZ_MORE_INFORMATION">
                  For more information look here:
                </p>
              </div>
              <div *ngIf="showWrongAnswerText || (selectedFeed?.completed && showWrongAnswerText)">
                <br />
                <br />
                <br />

                <div class="quiz-wrong" i18n="Quiz answered wrong@@#MY_FEED_ANSWER_WRONG">Wrong!
                  <img class="quiz-hivo-img" src="assets/img/Feed_Question_Feedback_HiVo_rethink_v2_120x120.png"
                    alt="HiVo Wrong">
                </div>
                <p *ngIf="selectedFeed.examtype === 'question'"> {{ selectedFeed?.info[selectedAnswers-1]?.response }}
                  <p *ngIf="selectedFeed.examtype == 'imagemap'"> {{ selectedFeed?.info[selectedAnswers[0]-1]?.response }}
                  </p>
                <p *ngIf="selectedFeed.examtype === 'order'"> {{ selectedFeed.incorrect }}</p>
                <!-- IVAR {{ selectedFeed | json }} XXX {{ selectedFeed?.info[selectedAnswers-1]?.response }} HAGEN {{ selectedAnswers }} SLUT -->
                <!-- selectedAnswers : {{ selectedAnswers  |json }} END -->
                <p *ngIf="selectedFeed?.nuggets?.data?.length > 0" class="green-label"
                  i18n="Quiz more information header@@#MY_FEED_QUIZ_LEARN_MORE">
                  Learn more :
                </p>
              </div>

              <!-- <p-button label="Go There" (click)="submitAnswer()" routerLink="/knowledge-store"></p-button> -->
              <!-- selectedFeed : {{ selectedFeed | json }} -->
              <ng-container *ngIf="
                showCorrectAnswerText ||
                showWrongAnswerText ||
                selectedFeed?.completed
              ">
                <div *ngFor="let nugget of selectedFeed?.nuggets?.data">
                  <span class="green-link">{{ nugget.attributes.title }}</span>
                  <br /><br />
                </div>

                <!-- <div *ngIf="selectedNewsCard?.no === '1'">
              <span
                style="cursor: pointer; font-weight: bold"
                (click)="setShowQuizLink1()"
                >Rescue Card</span
              >
              <br /><br />
            </div>
            <div *ngIf="selectedNewsCard?.no === '1'">
              <span
                style="cursor: pointer; font-weight: bold"
                (click)="setShowQuizLink2()"
                >Breakdown Recovery using a Winch</span
              >
              <br /><br />
            </div>
            <div *ngIf="selectedNewsCard?.no === '1'">
              <span
                style="cursor: pointer; font-weight: bold"
                (click)="setShowQuizLink3()"
                >I know how to tow</span
              >
              <br /><br />
            </div>

            <div *ngIf="selectedNewsCard?.no === '2'">
              <span
                style="cursor: pointer; font-weight: bold"
                (click)="setShowQuizLink4()"
                >External Start via 12 Volt Battery</span
              >
              <br /><br />
            </div>

            <div *ngIf="selectedNewsCard?.no === '3'">
              <span
                style="cursor: pointer; font-weight: bold"
                (click)="setShowQuizLink5()"
                >Dangerous work in repair work</span
              >
              <br /><br />
            </div>-->
              </ng-container>

              <a id="result"></a>

              <!-- <div class="background-image" *ngIf="!showCorrectAnswerText && !showWrongAnswerText"> -->
              <div style="   text-align: center; padding: 2px; height:100%; max-width:100%;"
                *ngIf="!showCorrectAnswerText && !showWrongAnswerText">

                <span
                  style=" display: inline-block; vertical-align: middle; margin-top: 50%; transform: translate(0%, -25%);">
                  <img *ngIf="selectedFeed?.image" class="background-image"
                    [src]="Util.getUrl(selectedFeed?.image?.data.attributes.url)"
                    style="  margin: 0;
                    top: 50%; left: 50%; display: block; margin-left: auto;  margin-right: auto;  width: 40%; max-width: 100%" alt="Feed Image" />
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br />

