import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent implements AfterViewInit {
  @ViewChild('supportEmail') emailLink: ElementRef;
  @ViewChild('supportPhone') phoneLink: ElementRef;

  ngAfterViewInit(): void {
    this.phoneLink.nativeElement.href = 'tel:' + this.phoneLink.nativeElement.innerText;
    const email = this.emailLink.nativeElement.innerText;
    if (email.indexOf('http') >= 0) {
      this.emailLink.nativeElement.href = this.emailLink.nativeElement.innerText;
    } else {
      this.emailLink.nativeElement.href = 'mailto:' + this.emailLink.nativeElement.innerText;
    }
  }
}
