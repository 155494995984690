import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { windowWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContentLibraryComponent } from '../content-library/content-library.component';
import { UploadFile, UploadFileService } from '../swagger-api-v4';
import { Utilities } from '../utilities';
@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss']
})
export class ContentFormComponent implements OnInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  contentModel: UploadFile;
  filteredTags: string[] = [];
  selectedNames = [];
  replaceFile: any = null;
  saveEnabled = true;

  allContents: UploadFile[] = [];

  customers: SelectItem[];
  selectedCustomers;
  msgs: Message[] = [];

  Util = Utilities;

  constructor(
    private http: HttpClient,
    private uploadFileService: UploadFileService,
    public ref: DynamicDialogRef,
    public router: Router,
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    // console.log('oninit 1', this.config.data.content);
    // console.log('oninit 2', Object.create({}, this.config.data.content));
    // this.contentModel = Object.create({}, this.config.data.content);
    // console.log('content', this.config.data.content);
    // this.contentModel = this.config.data.content;
    console.log('input content', this.config.data.content);
    this.contentModel = Object.assign({}, this.config.data.content);
    console.log('this content', this.contentModel);
    // this.filterTags(null);
    // this.contentModel['tags']= this.contentModel.caption;
    this.allContents = this.config.data.allContents;


    this.customers = environment.user.customers.map(customer => {
      return { value: customer['id'], label: customer.name };
    });
    console.log('check the data', this.config.data);
    if (this.config.data.content['customers']['data']) {
      console.log("this.config.data.content['customers']['data']", this.config.data.content['customers']['data']);
      this.selectedCustomers = this.config.data.content['customers']['data'].map(customer => customer.id);
    } else {
      this.selectedCustomers = [];
    }

    window.setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 100);
    // }
  }

  filterTags(event) {
    // console.log(event);
    let filtered: string[] = event.query.length > 0 ? [event.query] : [];
    let query = event.query;
    const uniqueTagSet = new Set<string>().add('Articulate').add('Image').add('PDF').add('Video').add('3D Nugget');
    this.allContents.forEach(content => {
      if (content.caption) {
        content?.caption?.split(',').forEach(tag => {
          if (tag.length > 0) uniqueTagSet.add(tag.trim())
        })
      }
    });
    this.contentModel['tags'].forEach(tag => uniqueTagSet.delete(tag));
    const uniqueTags = Array.from(uniqueTagSet);
    for (let i = 0; i < uniqueTags.length; i++) {
      let tag = uniqueTags[i];
      if (tag.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tag);
      }
    }
    this.filteredTags = filtered.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
  }

  onSubmit() {
    this.saveEnabled = false;
    console.log('ONSUBMIT', this.contentModel);

    let info = {
      alternativeText: this.contentModel['tags'].toString(),
      caption: this.contentModel['tags'].toString(),
      name: this.contentModel.name
    };

    let formData: FormData = new FormData();
    if (this.replaceFile?.name) {
      formData.append('files', this.replaceFile, this.replaceFile.name);
    }
    formData.append('fileInfo', JSON.stringify(info));
    console.log('formData');
    formData.forEach(fd => console.log(fd.toString()));

    if (this.contentModel?.id) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
      this.http.post('/cms-server/upload?id=' + this.contentModel.id, formData, { headers: headers }).toPromise().then(
        // this.uploadFileService.uploadPost(formData, this.contentModel.id).toPromise().then(
        // this.uploadFileService.uploadididPostForm(info, null, ''+this.contentModel.id).toPromise().then(
        content => {
          this.updateResource(content)
          // console.log('saved ', content);
          // this.ref.close(content);
        },
        error => {
          console.log('error', error);
          this.saveEnabled = true;
        }
      )
    } else {
      this.updateResource(this.contentModel);
    }

  }

  updateResource(file: UploadFile) {
    const resourceRequest = { data: { customers: this.selectedCustomers, media: file.id } };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    if (this.selectedCustomers.length > 0) {

      if (this.contentModel['resourceId'] == 0) {
        // create new resource
        console.log('post', resourceRequest);
        this.http.post('cms-server/resources', resourceRequest, { headers: headers }).toPromise().then(resource => {
          console.log('resource created', resource);
          this.ref.close(file);
        },
          (err) => {
            console.log('error', err);
          })
      } else {
        // update resource in case updated customers
        console.log('put', this.contentModel['resourceId'], resourceRequest);
        this.http.put('cms-server/resources/' + this.contentModel['resourceId'], resourceRequest, { headers: headers }).toPromise().then(resource => {
          console.log('resource updated', resource);
          this.ref.close(file);
        },
          (err) => {
            console.log('error', err);
          })
      }
    } else {
      if (this.contentModel['resourceId'] > 0) {
        this.http.delete('cms-server/resources/' + this.contentModel['resourceId'], { headers: headers }).toPromise().then(resource => {
          console.log('resource deleted', resource);
          this.ref.close(file);
        })
      }
    }
  }

  onCancel() {
    this.ref.close();
  }

  onBasicUploadAuto(event) {
    for (let file of event.files) {
      console.log('upload', file);
      // this.uploadedFiles.push(file);
    }
  }

  myUploader(event) {
    // console.log('uploader', event);
    this.replaceFile = event.files[0];

    this.selectedNames = [];
  }

  isPushable() {
    let isOk = false;
    this.contentModel['tags'].forEach(tag => {
      let tagStr: string = tag;
      if (tagStr.toLowerCase().localeCompare('articulate') === 0) { isOk = true; }
      if (tagStr.toLowerCase().localeCompare('html') === 0) { isOk = true; }
    });
    return isOk;
  }

  onPush() {
    // Send contentUrl to node server
    // console.log('onPush', this.contentModel);
    console.log('folderName', this.contentModel.name.replace('.zip', ''));
    let body = { contentURL: this.Util.getUrl(this.contentModel.url), folderName: this.contentModel.name.replace('.zip', '') };
    console.log('body', body);
    let url = '/appextension/html-upload/content';
    // console.log('origin', window.location.origin);
    // const origin = window.location.origin;
    // if (origin.indexOf('hivocms') >= 0) {
    //   url = origin.replace('hivocms', 'hivoappextension') + '/html-upload/content';
    // }
    console.log('url', url);
    this.http.post(url, body).toPromise().then(
      res => {
        console.log(res);
        this.msgs = [];
        this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Contents uploaded and unzipped.' });
      },
      err => {
        console.log('err', err);
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err.message });
      }
    )
  }
}
