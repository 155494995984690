import * as moment from 'moment';
import { UploadFile } from './swagger-api-v4';


interface Tag {
  key: string,
  values: string[]
}


export class Utilities {

  constructor() { }

  static formatDate(date) {
    const userLang = navigator.language;
    return moment(date).locale(userLang).format('L LT');
  }

  static getUrl(path: string): string {
    if (path === null) return null;

    let url = '';
    //https://dekraopenstorage.blob.core.windows.net/dekraopencontainer/assets/template_fc39a40232.zip


    if (path.substr(0, 8) === 'https://' || path.substr(0, 7) === 'http://') {
      // Use content proxy instead
      url = '/content/' + path.substr(path.indexOf('blob.core.windows.net') + 22);
      // console.log('URL', path.indexOf('blob.core.windows.net'));
      // console.log('stripped', '/content/' + path.substr(path.indexOf('blob.core.windows.net') + 22));
      //url = path;
    } else {
      url = '/cms-server' + path;
    }
    return url;
  }

  static getCourseUrl(path: string): string {
    const url = '/assets/tests/' + path + '/index.html';
    // const url = '/webart-content/webart/assets/' + path + '/index.html';
    return url;
  }

  // static getCourseUrl(path: string): string {
  //   const url = '/assets/tests/' + path + '/index.html';
  //   //const url = '/course/' + path + '/index.html';
  //   return url;
  // }

  static isImage(mime: string) {
    return (mime?.search('image') >= 0);
  }

  static format(text: string, ...args: string[]): string {
    return text.replace(/{(\d+)}/g, (match, num) => {
      return typeof args[num] !== 'undefined' ? args[num] : match;
    });
  }

  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getShuffledArr = arr => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
  };


  static tagExists(tags: Tag[], key: string, value?: string) {
    if (value) {
      return (tags.find(tag => (tag.key.toLowerCase() === key.toLowerCase() && tag.values.includes(value))) !== undefined);
    }
    return (tags.find(tag => (tag.key.toLowerCase() === key.toLowerCase()))) !== undefined;
  }

  static getTagValuesOfKey(tags: Tag[], key: string): string[] {
    const foundTag = tags.find(tag => tag.key.toLowerCase() === key.toLowerCase());
    if (foundTag !== undefined) {
      return foundTag.values;
    } else {
      return [];
    }
  }

  static getAllTags(contents: UploadFile[]): Tag[] {
    let allTags: Tag[] = [];
    contents.forEach(content => {
      allTags = allTags.concat(this.getTags(content));
    })
    return allTags;
  }

  static getTags(content: UploadFile): Tag[] {
    const tags: Tag[] = [];
    if (content) {
      const tagNoKey: Tag = { key: '', values: [] };
      const split = content.caption ? content.caption.split(',') : [];
      // console.log('captionsplit', split);
      split.forEach(str => {
        const idx = str.indexOf(':');
        if (idx >= 0) {
          const keyTrimmed = str.substring(0, idx).trim();
          const endpos = str.indexOf(',', idx + 1) >= 0 ? str.indexOf(',', idx + 1) : str.length;
          const values = str.substr(idx + 1, endpos - idx + 1).trim();
          const valsTrimmed = values.split(';').map(s => s.trim());
          // console.log('mapped', values.split(';').map(s => s.trim()));
          const foundTag = tags.find(tag => tag.key === keyTrimmed)
          // console.log('keyTrimmed', keyTrimmed, 'valsTrimmed', valsTrimmed, foundTag, tags);
          if (foundTag) {
            foundTag.values = foundTag.values.concat(valsTrimmed);
          } else {
            tags.push({ key: keyTrimmed, values: valsTrimmed });
          }
        } else {
          tags.push({ key: str, values: [] });
          // tagNoKey.values.push(str);
        }
      })
      // if (tagNoKey.values.length > 0) {
      // tags.push(tagNoKey);
      // }
      // console.log('TAGS', content.id, tags);
    }
    return tags;
  }





}
