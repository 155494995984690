<app-breadcrumbs></app-breadcrumbs>
<hr />
<p-confirmDialog header="Confirmation"></p-confirmDialog>

<p-table [columns]="cols" [value]="users" selectionMode="single" [(selection)]="selectedUser" dataKey="id"
  [resizableColumns]="true" [reorderableColumns]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 34px">#</th>
      <th *ngFor="let col of columns" pResizableColumn pReorderableColumn
        [style]="col.width ? ' width:' + col.width : ''">
        {{ col.header }}
      </th>
      <th style="width: 118px">Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
    <tr [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData" (dblclick)="showEditDialog(rowData)">
      <td>{{ index + 1 }}</td>
      <td *ngFor="let col of columns" style="white-space: normal">
        <ng-container *ngIf="col.type === 'Date'">
          {{ formatDate(rowData[col.field]) }}
        </ng-container>
        <ng-container *ngIf="col.type === 'Info'">
          {{ rowData.info[col.field] }}
        </ng-container>
        <!-- <ng-container *ngIf="col.type === 'URL'">
          /{{ uxSelections.customerName.toLowerCase() }}/{{ rowData.Name.toLowerCase() }}
        </ng-container> -->
        <ng-container *ngIf="col.type !== 'Date' && col.type !== 'URL' && col.type !== 'Info'">
          {{ rowData[col.field] }}
        </ng-container>
      </td>
      <td>
        <span pTooltip="Edit" tooltipPosition="top" style="display: inline-block">
          <button pButton type="button" icon="fas fa-edit" (click)="showEditDialog(rowData)"></button> </span>&nbsp;
        <span *ngIf="rowData.role.type!='cms_admin'" pTooltip="Delete" tooltipPosition="top" style="display: inline-block">
          <button pButton type="button" icon="fas fa-times" class="p-button-danger"
            (click)="deleteUser(rowData)"></button>
        </span>
      </td>
    </tr>
  </ng-template>
  <!-- <ng-template pTemplate="rowexpansion" let-rowData>
        <td colspan="6">
            <app-levels [app]="rowData" [appLevels]="rowData.levels"></app-levels>
        </td>
    </ng-template> -->
  <ng-template pTemplate="summary">
    <div class="right">
      <span><button pButton type="button" label="New User" icon="fas fa-plus" (click)="newUser($event)"></button></span>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div style="text-align: center">Loading users</div>
      </td>
    </tr>
  </ng-template>
</p-table>

<br />
