export const APPLICATION_TRANSLATION_SCHEMA =
{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "http://dekra.com/application.schema.json",
  "title": "Application",
  "description": "A translation file for dekra edudcations",
  "type": "object",
  "properties": {
    "id": {
      "description": "Unique Application id",
      "type": "integer"
    },
    "locale": {
      "description": "The locale code that this file should have translated texts for",
      "type": "string"
    },
    "language": {
      "description": "The language name that this file should have translated texts for",
      "type": "string"
    },
    "title": {
      "description": "Title of the application",
      "type": "string"
    },
    "description": {
      "description": "Description of the application",
      "type": "string"
    },
    "levels": {
      "type": "array",
      "items": {
        properties: {
          "id": {
            "description": "Unique Level id",
            "type": "integer"
          },
          "title": {
            "description": "Title of the level",
            "type": "string"
          },
          "description": {
            "description": "Description of the level",
            "type": "string"
          },
          "nuggets": {
            "type": "array",
            "items": {
              properties: {
                "id": {
                  "description": "Unique Nugget id",
                  "type": "integer"
                },
                "title": {
                  "description": "Title of the nugget",
                  "type": "string"
                },
                "description": {
                  "description": "Description of the nugget",
                  "type": "string"
                },
                "keywords": {
                  "description": "Keywords for the nugget",
                  "type": "string"
                },
                "exams": {
                  "type": "array",
                  "items": {
                    properties: {
                      "id": {
                        "description": "Unique Exam id",
                        "type": "integer"
                      },
                      "question": {
                        "description": "Question for the exam",
                        "type": "string"
                      },
                      "answers": {
                        "type": "array",
                        "items": {
                          properties: {
                            "id": {
                              "description": "Unique Answer id",
                              "type": "integer"
                            },
                            "answer": {
                              "description": "An answer for the exam",
                              "type": "string"
                            },
                            "required": ["id", "answer"]
                          }
                        }
                      },
                      "required": ["id", "question"]
                    }
                  }
                },


                "required": ["id", "title", "description"]
              }
            }
          },
          "required": ["id", "title", "description"]
        }
      }
    },
    "feeds": {
      "type": "array",
      "items": {
        properties: {
          "id": {
            "description": "Unique Feed id",
            "type": "integer"
          },
          "title": {
            "description": "Title of the feed",
            "type": "string"
          },
          "details": {
            "description": "Details/question for the feed quiz",
            "type": "string"
          },
          "correct": {
            "description": "Response for correct answer",
            "type": "string"
          },
          "incorrect": {
            "description": "Response for wrong answer",
            "type": "string"
          },
          "info": {
            "type": "array",
            "items": {
              properties: {
                "sortorder": {
                  "description": "Unique sort order for the statement",
                  "type": "integer"
                },
                "statement": {
                  "description": "Statement/question for the quiz",
                  "type": "string"
                },
                "response": {
                  "description": "Response to show if this statement was selected as an answer",
                  "type": "string"
                }

              },
            },
            "required": ["sortorder", "statement",]
          },
        }
      },
      "required": ["id", "title", "details"]
    }
  },
  "required": ["id", "locale", "language", "title", "description"]
}

