import { BrowserModule } from '@angular/platform-browser';
import {  CUSTOM_ELEMENTS_SCHEMA,  NgModule,  ɵɵsetNgModuleScope} from '@angular/core';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { AppComponent } from './app.component';
import { ApplicationsComponent } from './applications/applications.component';
import { BreadcrumbService } from './breadcrumb.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import { ApiModule } from './swagger-api-v4';
import { AnswerService, Configuration, CustomerService, ExamService, FeedService, LevelService, NuggetService, SettingService, UsersPermissionsAuthService, UsersPermissionsUsersRolesService } from './swagger-api-v4';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, MessageService } from 'primeng/api';


import { LevelsComponent } from './levels/levels.component';
import { NuggetsComponent } from './nuggets/nuggets.component';
import { LevelFormComponent } from './levels-form/level-form.component';
import { NuggetFormComponent } from './nugget-form/nugget-form.component';
import { ContentLibraryComponent } from './content-library/content-library.component';
import { ExamsComponent } from './exams/exams.component';
import { ExamFormComponent } from './exam-form/exam-form.component';
import { ExportImportComponent } from './export-import/export-import.component';
import { StartComponent } from './start/start.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { environment } from 'src/environments/environment';
import { WelcomeCmsComponent } from './welcome-cms/welcome-cms.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
// import { BreadcrumbModule } from 'primeng/breadcrumb';
// import { ButtonModule } from 'primeng/button';
// import { CalendarModule } from 'primeng/calendar';
// import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
// import { CheckboxModule } from 'primeng/checkbox';
// import { ChipsModule } from 'primeng/chips';
// import { CodeHighlighterModule } from 'primeng/codehighlighter';
// import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
// import { DialogModule } from 'primeng/dialog';
// import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
// import { FileUploadModule } from 'primeng/fileupload';
// import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
// import { InputSwitchModule } from 'primeng/inputswitch';
// import { InputTextModule } from 'primeng/inputtext';
// import { InputTextareaModule } from 'primeng/inputtextarea';
// import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
// import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
// import { MessagesModule } from 'primeng/messages';
// import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
// import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
// import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
// import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
// import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
// import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
// import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

// import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
// import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppRightMenuComponent } from './app.right-menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { MenuService } from './app.menu.service';
import { ContentFormComponent } from './content-form/content-form.component';
import { CmsComponent } from './cms/cms.component';
import { FeedsComponent } from './feeds/feeds.component';
import { FeedsFormComponent } from './feeds-form/feeds-form.component';

import { CustomersComponent } from './customers/customers.component';
import { CoursesComponent } from './courses/courses.component';
import { CourseFormComponent } from './course-form/course-form.component';
import { LoaderScreenComponent } from './loader-screen/loader-screen.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CourseExportFormComponent } from './course-export-form/course-export-form.component';
import { UsersComponent } from './users/users.component';
import { UserFormComponent } from './user-form/user-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UploadFileService } from './swagger-api-v4';
import { CourseService } from './swagger-api-v4';
import { ApplicationService } from './swagger-api-v4';
import { NewsFeedComponent } from './news-feed/news-feed.component';


@NgModule({
  declarations: [
    AppComponent,

    ApplicationsComponent,
    ApplicationFormComponent,
    LevelsComponent,
    NuggetsComponent,
    LevelFormComponent,
    NuggetFormComponent,
    ContentLibraryComponent,
    ExamsComponent,
    ExamFormComponent,
    // DemoComponent,
    ExportImportComponent,
    StartComponent,
    BreadcrumbsComponent,
    WelcomeCmsComponent,
    ContentFormComponent,
    FeedsComponent,
    FeedsFormComponent,

    // AppComponent,
    AppMainComponent,
    // AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppRightMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,

    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    AppLoginComponent,
    CmsComponent,
    CustomersComponent,
    CoursesComponent,
    CourseFormComponent,
    LoaderScreenComponent,
    CustomerFormComponent,
    CourseExportFormComponent,
    UsersComponent,
    UserFormComponent,
    ForgotPasswordComponent,
    NewsFeedComponent,

  ],
  imports: [
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),

    ApiModule.forRoot(() =>
      new Configuration({
        accessToken: function () { return environment.cmsAccessToken; }
      })
    ),

    // PdfViewerModule,

    BrowserModule,
    BrowserAnimationsModule,
    //   AppRoutingModule,
    FormsModule,
    HttpClientModule,

    // PrimeNG
    // BreadcrumbModule,
    // ButtonModule,
    // CalendarModule,
    // CardModule,
    // CheckboxModule,
    // ChipsModule,
    // ConfirmDialogModule,
    // DialogModule,
    // DropdownModule,
     DynamicDialogModule,
    // FileUploadModule,
    InputNumberModule,
    // InputSwitchModule,
    // InputTextareaModule,
    // InputTextModule,
    // MenuModule,
    // MessageModule,
    // MessagesModule,
    // OverlayPanelModule,
    ProgressSpinnerModule,
    // RadioButtonModule,
    // SelectButtonModule,
    // TableModule,
    // ToastModule,
    // TooltipModule,

    AppRoutes,
    // HttpClientModule,
    // BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    // CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    // EditorModule,
    // FieldsetModule,
    FileUploadModule,
    // FullCalendarModule,
    // GalleriaModule,
    // InplaceModule,
    // InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    // LightboxModule,
    ListboxModule,
    // MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    // OrganizationChartModule,
    OverlayPanelModule,
    // PaginatorModule,
    // PanelModule,
    // PanelMenuModule,
    // PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    // RatingModule,
    // ScrollPanelModule,
    SelectButtonModule,
    SidebarModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    // SplitButtonModule,
    // StepsModule,
    TableModule,
    // TabMenuModule,
    // TabViewModule,
    // TerminalModule,
    // TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    // ToolbarModule,
    TooltipModule,
    // TreeModule,
    // TreeTableModule,
    VirtualScrollerModule,

  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    BreadcrumbService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    //{ provide: LocationStrategy, useClass: PathLocationStrategy },

    // CarService, CountryService, EventService, NodeService,
    MenuService,
    ConfirmationService,
    MessageService,
    DynamicDialogConfig,
    DynamicDialogRef,

    UploadFileService,
    FeedService,
    CustomerService,
    CourseService,
    ApplicationService,
    LevelService,
    NuggetService,
    ExamService,
    AnswerService,
    SettingService,
    UsersPermissionsAuthService,
    UsersPermissionsUsersRolesService,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
