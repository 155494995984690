<!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->
<!-- ITEMS:{{ items?.length }} - {{ items  | json }} END {{ home | json}} -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<div class="layout-breadcrumb">
  <ul>
    <li>
      <a routerLink="/">
        <i class="fas fa-home"></i>
      </a>
    </li>
    <li>/</li>
    <ng-template ngFor let-item let-last="last" [ngForOf]="items">
      <li>
        <a [routerLink]="item.routerLink" [queryParams]="item?.queryParams" *ngIf="item.routerLink">{{ item.label }}</a>
        <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
      </li>
      <li *ngIf="!last">/</li>
    </ng-template>
  </ul>
  <div class="layout-breadcrumb-options">
    <!-- <a routerLink="/" title="Backup">
      <i class="fa fa-cloud-upload"></i>
    </a>
    <a routerLink="/" title="Bookmark">
      <i class="fa fa-bookmark"></i>
    </a> -->
    <!-- <a routerLink="/feeds" title="Feeds">
      <i class="fas fa-rss"></i>
    </a> -->

<!-- {{user.role.type}} -->
    <ng-container *ngIf="user.role.type ==='cms_admin'">
      <a routerLink="/users" title="Users">
        <i class="fas fa-users"></i>
      </a>
      </ng-container>

      <a routerLink="/content-library" title="Content Library">
        <i class="fas fa-photo-video"></i>
      </a>

      <span>
        <a href="javascript:void(0);" (click)="onLogout()" [title]="'Logout '+(user ? user?.email: '')">
          <i class=" fas fa-sign-out-alt"></i>
        </a>
      </span>
  </div>
</div>
