import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LANGUAGES } from '../utils/languages';
import { ApplicationRequest, ApplicationResponseDataObject, ApplicationService } from '../swagger-api-v4';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})

export class ApplicationFormComponent implements OnInit {
  @ViewChild('titleInput') titleInput: ElementRef;

  @Input() set application(app: ApplicationResponseDataObject) {
    this.originalApplication = app;
    this.appModel.id = app.id;
    Object.assign(this.appModel.attributes, app.attributes);
  };

  appModel: ApplicationResponseDataObject =  { id: 0, attributes: {} };
  originalApplication: ApplicationResponseDataObject = null;

  locales: SelectItem[] = [];
  LANGUAGES = LANGUAGES;

  constructor(
    private applicationService: ApplicationService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    LANGUAGES.forEach(lan => {
      const item: SelectItem = {
        label: lan.code.toUpperCase() + ' - ' + lan.name,
        value: lan.code.toUpperCase()
      }
      this.locales.push(item);
    });
    this.application = this.config.data.application;
    // if (this.titleInput) {
    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();
    }, 100);
    // }
  }

  onSubmit() {
    let applicationRequest: ApplicationRequest = { data: {} };
    Object.assign(applicationRequest.data, this.appModel.attributes);
    applicationRequest.data.course = this.config.data.courseId;

    if (this.originalApplication?.id) {
      this.applicationService.putapplicationsid(applicationRequest, this.originalApplication.id).toPromise().then(
        app => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: app.title });
          this.ref.close(app);
        }
      )
    } else {
      this.applicationService.postapplications(applicationRequest).toPromise().then(
        newapp => {
          // this.messageService.add({ severity: 'success', summary: 'Saved', detail: newapp.title });
          this.ref.close(newapp);
        }
      )
    }
  }

  onCancel() {
    this.ref.close();
  }

}
