<div class="layout-wrapper" [ngClass]="{'layout-horizontal': menuMode === 'horizontal',
    'layout-overlay': menuMode === 'overlay',
    'layout-static': menuMode === 'static',
    'layout-slim': menuMode === 'slim',
    'layout-menu-light': lightMenu === true,
    'layout-menu-dark': lightMenu === false,
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-rtl': isRTL}" [class]="topbarColor" (click)="onLayoutClick()">
  <app-topbar></app-topbar>

  <app-menu></app-menu>
  <app-right-menu></app-right-menu>

  <div class="layout-main">
    <div class="layout-content">

      <router-outlet></router-outlet>
    </div>
    <!-- </div>
    <!-- <app-config></app-config> -->
    <!-- <div class="layout-footer">        <app-footer></app-footer>
    </div> -->
    <!-- <div class="layout-content-mask"></div> -->
  </div>
