import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from '../breadcrumb.service';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { Customer, CustomerListResponse, CustomerRequest, CustomerRequestData, CustomerResponseDataObject, CustomerService } from '../swagger-api-v4';
import { Utilities } from '../utilities';
import { MSG_DELETE_CONFIRM } from '../utils/messages';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class CustomersComponent implements OnInit {

  Util = Utilities;

  cols = [
    // { field: 'id', header: 'Id' },
    // { field: 'sortOrder', header: 'SortOrder', type: 'Numeric', width: '4em' },
    { field: 'name', header: 'Name', type: 'Text' },
    // { field: 'details', header: 'Details', type: 'Text' },
    { field: 'createdAt', header: 'Created', type: 'Date', width: '9em' },
    { field: 'updatedAt', header: 'Updated', type: 'Date', width: '9em' },
  ];

  customers: CustomerResponseDataObject[] = [];
    sub: Subscription;
  ref: DynamicDialogRef;

  selectedCustomer: CustomerResponseDataObject;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    public dialogService: DialogService,
    private customerService: CustomerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private http: HttpClient
  ) {

    // this.cmsStore.select('cms').subscribe((cms) => {
    //   this.cms = cms;
    //   if (cms.customers) {
    //     this.customers = this.cms.customers;
    //     console.log('**** Customers', this.cms.customers);
    //   } else {
    //     this.cmsStore.dispatch(GetCMS());
    //   }
    // });
  }

  refreshData() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.cmsAccessToken);
    this.http.get('/cms-server/customers?sort=name&populate=logo', { headers: headers }).toPromise().then((customers: CustomerListResponse) => {
      const customerIds = environment.user.customers.map(customer => customer.id);
      this.customers = customers.data.filter(c => c.attributes.isDeleted != true)
      .filter(customer => customerIds.includes(customer.id))
      console.log('Customers', this.customers);
    }, (err => {
      this.showError('Error while getting customers.\n' + err.message);
    }))
  }


  ngOnInit(): void {
    const items: MenuItem[] = [{ label: 'Customers' }];
    this.breadcrumbService.setItems(items);
    this.refreshData();
  }

  ngOnDestroy(): void {
    // this.sub.unsubscribe();
  }

  newCustomer(event) {
    const newCustomer: Customer = {
      name: '',
      courses: { data: [] },
      isDeleted: false,
      logo: { data: null },
      topbarColor: '#007d40',
      buttonColor: '#007d40'
    }
    const newCustomerData: CustomerResponseDataObject = { id: 0, attributes: newCustomer };
    this.showEditDialog(newCustomerData);
  }

  formatDate(date) {
    return Utilities.formatDate(date);
  }

  gotoRoute(rowData) {
    localStorage.setItem('customerId', rowData.id);
    localStorage.setItem('customerName', rowData.attributes.name);
    this.router.navigate(['/courses']);
  }

  showEditDialog(customer: CustomerResponseDataObject) {
    this.ref = this.dialogService.open(CustomerFormComponent, {
      header: customer.id > 0 ? 'Edit Customer' : 'New Customer',
      width: '90%',
      data: {
        customer: customer
      },
    });

    this.ref.onClose.subscribe((customer) => {
      if (customer) {
        this.refreshData();
      }
    });
  }


  deleteCustomer(customer: CustomerResponseDataObject) {
    this.confirmationService.confirm({
      message: this.Util.format(MSG_DELETE_CONFIRM, customer.attributes.name),
      accept: () => {
        customer.attributes.isDeleted = true;
        const updateCustomer: CustomerRequest = { data: { isDeleted: true } };
        this.customerService.putcustomersid(updateCustomer, customer.id).toPromise().then(res => {
          let index = this.customers.findIndex(
            (c) => c.id === customer.id
          );
          if (index != -1) {
            this.customers.splice(index, 1);
          }
        }, (err) => {
          this.showError("Could not delete course!\n" + err.message);
        })
      },
    });
  }

  showError(msg) {
    this.messageService.add({ key: 'error', severity: 'error', summary: 'Error', detail: msg });
  }

}
