<div class="layout-topbar">

  <!-- <a class="layout-right-panel-button layout-topbar-icon" (click)="app.onRightMenuClick($event)" href="#">
        <i class="pi pi-ellipsis-v"></i>
    </a> -->
<!--
  <a *ngIf="showBarMenu" href="#" class="layout-menu-button layout-topbar-icon "
    (click)="app.onMenuButtonClick($event)">
    <i class="pi pi-bars"></i>
  </a> -->

      <!-- <span *ngIf="showMenu" class="top-menu">
        <ul id="menu">
          <li *ngIf="appExt.includes('feed')"><a [ngStyle]="isSelected(['/newsfeed'])" routerLink="/newsfeed"
              i18n="Top menu choice my feed@@#TOP_MENU_MY_FEED">My Feed</a></li>
          <li *ngIf="appExt.includes('ks')"><a [ngStyle]="isSelected(['/knowledge-store'])"
              routerLink="/knowledge-store" i18n="Top menu choice knowledge store@@#TOP_MENU_KNOWLEDGE_STORE">Knowledge
              Store</a></li>
          <li *ngIf="appExt.includes('rc')"><a [ngStyle]="isSelected(['/rescue-cards'])" routerLink="/rescue-cards"
              i18n="Top menu choice rescue cards@@#TOP_MENU_RESCUE_CARDS">Rescue Cards</a></li>
          <li><a [ngStyle]="isSelected(['/cube-training','/hivo'])" routerLink="/cube-training"
              i18n="Top menu choice cube training@@#TOP_MENU_CUBE_TRAINING">Cube Training</a></li>
          <li *ngIf="appExt.includes('ks')"><a [ngStyle]="isSelected(['/favourites'])" routerLink="/favourites"
              i18n="Top menu choice favourites@@#TOP_MENU_FAVOURITES">Favorites</a></li>
          <li *ngIf="appExt.includes('faq')"><a [ngStyle]="isSelected(['/faq'])" routerLink="/faq"
              i18n="Top menu choice FAQ@@#TOP_MENU_FAQ">FAQ</a>
          </li>
        </ul>
      </span> -->
<!--
      <ul class="fadeInDown">
        <li>
          <a href="#">
            <i class="pi pi-fw pi-user"></i>
            <span>Profile</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="pi pi-fw pi-cog"></i>
            <span>Settings</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="pi pi-fw pi-envelope"></i>
            <span>Messages</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="pi pi-fw pi-bell"></i>
            <span>Notifications</span>
          </a>
        </li>
      </ul>
    <!-- </li> -->
    <li [ngClass]="{'active-topmenuitem fadeInDown': app.topbarNotificationMenuActive}"> -->
      <!-- <a href="#" class="layout-topbar-icon" (click)="app.onTopbarNotificationMenuButtonClick($event)">
                <i class="topbar-icon pi pi-fw pi-bell"></i>
            </a>
            <ul class="fadeInDown">
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-1.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Bithika Abhedananda</span>
                            <span class="topbar-menu-role">User interface review is done.</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-2.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Dai Jiang</span>
                            <span class="topbar-menu-role">Uh, we have sort of a problem here.</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-3.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Karlien Nijhuis</span>
                            <span class="topbar-menu-role">You apparently didn’t put the thing</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-4.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Tom Chun</span>
                            <span class="topbar-menu-role">Please check the files</span>
                        </div>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#"><img src="assets/layout/images/avatar-5.png" alt="">
                        <div class="topbar-menu-info">
                            <span class="topbar-menu-name">Maria Trofimova</span>
                            <span class="topbar-menu-role">Meeting reports attached.</span>
                        </div>
                    </a>
                </li>
            </ul> -->
    </li>
  <!-- </ul> -->
</div>
