import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';


@Injectable()
export class BreadcrumbService {



  private itemsSource = new Subject<MenuItem[]>();

  public itemsHandler = this.itemsSource.asObservable();

//  private currentItems: MenuItem[] = []; //[{ label: 'Allianz' }, { label: 'HiVo' }];

  setItems(items: MenuItem[]) {
//    console.log('setting breadcrumbs', items);
  //  this.currentItems = items;
    this.itemsSource.next([...items]);
  }

  // getItems(): MenuItem[] {
  //   return this.currentItems;
  // }

}
