<app-breadcrumbs></app-breadcrumbs>
<hr />

<!-- <p-toast></p-toast> -->

<p-confirmDialog header="Confirmation"></p-confirmDialog>

<!-- <div class="header">Levels</div> -->

<div style="max-height: calc(100vh - 120px); overflow-y: scroll;">
  <p-table [columns]="cols" [value]="levels" selectionMode="single" [(selection)]="selectedLevel" dataKey="id"
    [resizableColumns]="true" [reorderableColumns]="false" (onRowReorder)="onRowReorder($event)">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 10px"></th>
        <th style="width: 34px">#</th>
        <th *ngFor="let col of columns" pResizableColumn pReorderableColumn
          [style]="col.width ? ' width:'+col.width : ''">
          {{col.header}}
        </th>
        <th style="width: 150px">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex" let-expanded="expanded">
      <tr [pReorderableRow]="index" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData"
        (dblclick)="gotoRoute(rowData)">
        <td> <span class="fas fa-grip-vertical" pReorderableRowHandle></span> </td>
        <td>{{index+1}}</td>
        <td *ngFor="let col of columns" style="white-space: normal;">
          <!-- <ng-container *ngIf="col.field === 'title'">
                    <i [pRowToggler]="rowData"
                        [class]="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>&nbsp;
                </ng-container> -->

          <ng-container *ngIf="col.type === 'Date'">
            {{ formatDate(rowData.attributes[col.field]) }}
          </ng-container>
          <ng-container *ngIf="col.type !== 'Date'">
            {{ rowData.attributes[col.field] }}
          </ng-container>
        </td>
        <td>
          <span pTooltip="Nuggets" tooltipPosition="top" style="display:inline-block;">
            <button pButton type="button" icon="fas fa-forward" (click)="gotoRoute(rowData)"></button>
          </span>&nbsp;
          <span pTooltip="Edit" tooltipPosition="top" style="display:inline-block;">
            <button pButton type="button" icon="fas fa-edit" (click)="showEditDialog(rowData)"></button>
          </span>&nbsp;
          <span [pTooltip]="(rowData?.nuggets?.length > 0) ? 'Cannot delete with nuggets present' :  'Delete'"
            tooltipPosition="top" style="display:inline-block;">
            <button pButton type="button" icon="fas fa-times" class="p-button-danger" (click)="deleteLevel(rowData)"
              [disabled]="(rowData?.nuggets?.length > 0)"></button>
          </span>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="rowexpansion" let-rowData>
        <td colspan="6">
            <app-levels [app]="rowData" [appLevels]="rowData.levels"></app-levels>
        </td>
    </ng-template> -->
    <!-- <ng-template pTemplate="summary">
      <div class="right">
        <span><button pButton type="button" label="New Level" icon="fas fa-plus"
            (click)="newLevel($event)"></button></span>
      </div>
    </ng-template> -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length+1">
          <div style="text-align: center;">No levels found</div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<br>
<div class="right">
  <span><button pButton type="button" label="New Level" icon="fas fa-plus" (click)="newLevel($event)"></button></span>
</div>

<!-- <div *ngIf="selectedLevel">
    <app-nuggets [level]="selectedLevel" [levelNuggets]="selectedLevel?.nuggets"></app-nuggets>
</div> -->

<!-- {{ selectedLevel | json }} -->
